import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		aspectRatio: 1,
		flex: 1 / 2,
	},
	selected: {
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
	button: {},
	logos: {
		position: "relative",
		marginBottom: 16,
		alignSelf: "center",
	},
	onlyCurrencyLogo: {
		width: 96,
		height: 96,
	},
	currencyLogo: {
		width: 84,
		height: 84,
	},
	networkLogo: {
		width: 36,
		height: 36,
		borderWidth: 1,
		borderRadius: 36,
		borderColor: "#FFF",
		position: "absolute",
		right: -12,
		bottom: -12,
	},
	icon: {
	},
	title: {
		fontFamily: "Inter_400Regular",
		fontSize: 18,
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "600",
	},
	subtitle: {
		color: "#79747E",
		fontFamily: "Inter_400Regular",
		fontSize: 12,
	},
	networkText: {
		color: "#1C1B1F",
		fontFamily: "Inter_400Regular",
		fontSize: 12,
	},
});

export default styles;
