import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { CustomButton } from "../../../../src/components/CustomButton";
import { TCurrencySymbol } from "../../../../src/utils/currency";
import { useCurrency } from "../../../../src/hooks/useCurrency";
import { usePos } from "../../../../src/providers/pos.provider";

import Header from "../../../../src/components/Header";
import { PosTitle } from "../../components/Title";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import Crypto from "../../components/Crypto";
import styles from "./styles";
import {
	CurrencyData,
	CurrencyTypeEnum,
	INetwork,
} from "../../../../src/services/CurrencyService";

import {
	useIsFetching,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { ActivityIndicator, Text } from "react-native-paper";
import moment from "moment-timezone";
import { useIsFocused } from "@react-navigation/native";
import InvoiceService from "../../../../src/services/InvoiceService";

export interface PosPaymentMethod {
	id: string;
	name: string;
	currencySymbol: TCurrencySymbol;
	network: INetwork;
	currency: CurrencyData;
}

const parsePaymentMethodId = (
	currencySymbol: TCurrencySymbol,
	networkCurrencySymbol: TCurrencySymbol
) => {
	return currencySymbol + "/" + networkCurrencySymbol;
};

const quoteTimeLimit = 60; //seconds

export const QuoteTimer = ({ onTimeOut }: { onTimeOut: Function }) => {
	const { t } = useTranslation();
	const { paymentState, setPaymentState } = usePos();

	const isFocused = useIsFocused();

	const timerInterval = useRef<NodeJS.Timer>(null);

	const [timeToNewQuote, setTimeToNewQuote] = useState(quoteTimeLimit);

	const startPaymentLimitTimer = (_time?: number) => {
		if (!!timerInterval.current) {
			clearInterval(timerInterval.current);
		}

		timerInterval.current = setInterval(() => {
			setTimeToNewQuote((prevTime) => {
				const time = _time !== undefined ? _time : prevTime;

				if (time <= 1) {
					onTimeOut();

					clearInterval(timerInterval.current);
					return 0;
				}

				if (!isFocused) {
					clearInterval(timerInterval.current);
					return 0;
				}

				return time - 1;
			});
		}, 1000);
	};

	const isQuotingInvoice =
		useIsFetching({
			queryKey: ["paymentMethod/Quote"],
		}) > 0;

	useEffect(() => {
		if (!isQuotingInvoice && isFocused) {
			const now = new Date();

			const diff = moment(now).diff(paymentState.lastQuoteDate, "second");

			if (!paymentState.lastQuoteDate || diff >= quoteTimeLimit) {
				setPaymentState((prev) => ({
					...prev,
					lastQuoteDate: now,
				}));
				setTimeToNewQuote(quoteTimeLimit);
				startPaymentLimitTimer();
			} else {
				setTimeToNewQuote(quoteTimeLimit - diff);
				startPaymentLimitTimer();
			}
		}

		if (!isFocused) {
			clearInterval(timerInterval.current);
		}
	}, [isQuotingInvoice, isFocused]);

	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				gap: 8,
				marginBottom: 16,
			}}
		>
			{!isQuotingInvoice && <ActivityIndicator size={16} />}
			<Text>
				{isQuotingInvoice
					? t(`pos.steps.common.quote.quoting`)
					: t(
							`pos.steps.common.quote.new-quote-timer-${
								timeToNewQuote !== 1 ? "plural" : "singular"
							}`,
							{ replace: { time: timeToNewQuote.toString().padStart(2, "0") } }
					  )}
			</Text>
		</View>
	);
};

export default function CryptoSelect() {
	const navigation = useCustomNavigation();
	const { t } = useTranslation();

	const { paymentState, setPaymentState } = usePos();
	const { currencies, isFetchingCurrencies } = useCurrency();

	const queryClient = useQueryClient();

	const { mutateAsync: quoteInvoice, isLoading: isQuotingInvoice } =
		useMutation({
			mutationFn: InvoiceService.quoteInvoice,
		});

	const { paymentMethods } = useMemo(() => {
		const paymentMethods: PosPaymentMethod[] = [];

		if (!currencies || !currencies.length || !Array.isArray(currencies)) {
			return {
				paymentMethods,
			};
		}

		currencies
			?.filter((currency) => currency?.type === CurrencyTypeEnum.CRYPTO)
			.forEach((currency) => {
				currency.deposit.networks.forEach((network) => {
					paymentMethods.push({
						id: parsePaymentMethodId(currency.currency, network.currencySymbol),
						name: currency.name,
						currencySymbol: currency.currency,
						currency: currency,
						network: network,
					});
				});
			});

		return {
			paymentMethods,
		};
	}, [currencies]);

	const [selectedMethod, setSelectedMethod] = useState<PosPaymentMethod | null>(
		paymentState?.paymentMethod
			? paymentMethods.find(
					(method) => method.id === paymentState.paymentMethod.id
			  )
			: null
	);

	const handleSubmit = async () => {
		const quote = await quoteInvoice({
			netAmountOut: paymentState.netAmountOut,
			currencyIn: selectedMethod.currency.currency,
			networkIn: selectedMethod.network.nameId,
		});

		setPaymentState((prev) => ({
			...prev,
			amountIn: quote.amountIn,
			lastQuoteDate: new Date(),
			paymentMethod: selectedMethod,
		}));

		navigation.customNavigate("send-payment");
	};

	if (!paymentState) {
		return null;
	}

	return (
		<View style={[styles.container, {}]}>
			<Header onBackClick={() => navigation.goBack()} />

			<View style={styles.content}>

				<PosTitle title={t("pos.steps.crypto-select.title")} />

				<QuoteTimer
					onTimeOut={() => {
						queryClient.refetchQueries({
							queryKey: ["paymentMethod/Quote"],
							fetchStatus: "idle",
						});
					}}
				/>

				<FlatList
					data={paymentMethods}
					renderItem={({ item }) => (
						<Crypto
							paymentMethod={item}
							netAmountOut={paymentState?.netAmountOut!}
							onSelect={(quote) => {
								if (isQuotingInvoice) return;
								setSelectedMethod(item);
							}}
							isSelected={selectedMethod?.id === item.id}
						/>
					)}
					keyExtractor={(item) => item.name}
					numColumns={2}
					style={styles.cryptoList}
				/>

				<CustomButton
					label={isQuotingInvoice ? "" : t("pos.continue")}
					rightAddon={
						isQuotingInvoice ? (
							<ActivityIndicator size={16} color={"#FFF"} />
						) : (
							<MaterialCommunityIcons
								name="arrow-right"
								size={24}
								color={"#fff"}
							/>
						)
					}
					onPress={handleSubmit}
					disabled={!selectedMethod || isQuotingInvoice}
				/>
			</View>
		</View>
	);
}
