import {StyleSheet, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";

export default function LoadingView() {
    return (
        <View
            style={[StyleSheet.absoluteFill, styles.container]}
        >
            <ActivityIndicator size={"large"} color={"#FFF"}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "rgba(0,0,0,0.6)",
        justifyContent: "center",
        alignItems: "center"
    }
})
