import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";
import {useCustomTheme} from "../Theme";
import {useTranslation} from "react-i18next";
import commonStyles from "../styles/commonStyles";
import {useMultiTenant} from "../providers/multi-tenant.provider";
import LanguageView from "./LanguageView";

interface FooterProps {
    isFlex?: boolean
}

export default function Footer({ isFlex = true }: FooterProps) {
    const theme = useCustomTheme();
    const {t} = useTranslation();
    const {tenant} = useMultiTenant();

    return (
        <View style={[commonStyles.footerContainer, isFlex ? { flex: 1 } : {}]}>
            <LanguageView fontSize={12}/>

            <Text style={styles.helpText}>
                { t(`receipt.help`) }
                <Text style={{ color: theme.colors.primary, textAlign: "center" }}>{ " " + tenant.email }</Text>
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    helpText: {
      display: "flex",
      flexDirection: "column",
        textAlign: "center",
        fontSize: 12
    }
});
