import {useNavigation} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {NativeParamList, StackParamList} from "../Navigation/Stack/props";
import {Platform} from "react-native";

export default function useCustomNavigation() {
  const { ...rest } = useNavigation<StackNavigationProp<StackParamList & NativeParamList>>();

  const customNavigate = (route: keyof NativeParamList) => {
    if(Platform.OS === "web") {
      rest.navigate("pos", { screen: route })
    } else {
      rest.navigate(route)
    }
  }

  const customReplace = (route: keyof NativeParamList) => {
    if(Platform.OS === "web") {
      rest.replace("pos", { screen: route })
    } else {
      rest.replace(route)
    }
  }

  return { ...rest, customNavigate, customReplace };
}
