import { View } from "react-native";
import { Text } from "react-native-paper";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";
import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../src/Theme";

import styles from "../styles";

import CopyToClipboardIconButton from "../../../src/components/CopyToClipboardIconButton";
import { useResponsive } from "../../../src/hooks/useResponsive";
import QrCodeView from "./components/QrCodeView";
import CurrencyLogo from "../../../src/components/CurrencyLogo";
import { InvoiceContext } from "../context";

const InputWithCopyButton = ({ formattedValue, value, fontSize }: { formattedValue: string, value: string | number, fontSize?: number }) => {
  const theme = useCustomTheme();
  const { responsiveMode } = useResponsive();

  const isMobile = responsiveMode === "mobile";

  return (
    <View style={[
      styles.inputView,
      { borderColor: theme.colors.primaryHover },
      isMobile ? {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderRadius: 0,
        height: 40,
        paddingBottom: 8
      } : {}
    ]}>
      <Text
        style={[styles.amountValue, { fontSize: fontSize || 14 }]}
        numberOfLines={1}
        ellipsizeMode={"middle"}
      >
        {formattedValue}
      </Text>

      <View style={styles.inputRightView}>
        <CopyToClipboardIconButton value={value.toString()} />
      </View>
    </View>
  );
}

export default function AwaitingPaymentStep({ timer }: { timer: ReactNode }) {
  const theme = useCustomTheme();
  const { t } = useTranslation();
  const { invoice, selectedCurrency } = useContext(InvoiceContext);
  const { responsiveMode } = useResponsive();

  const isMobile = responsiveMode === "mobile";

  const network = selectedCurrency.network;
  const address = network.address;
  const amountOut = network.amount;
  const amountIn = CurrencyUtils.getNumberByValue(invoice.amount);


  const AddressView = () => {
    return (
      <View style={[
        styles.addressView,
        isMobile ? { gap: 0 } : {}
      ]}>
        <QrCodeView />

        <Text style={[
          { fontWeight: "bold", color: theme.colors.primary },
          isMobile ? { paddingLeft: 16 } : {}
        ]}>
          {t(`invoice.awaiting-payment.address-label`, { currency: selectedCurrency.name })}
        </Text>

        <InputWithCopyButton
          formattedValue={address}
          value={address}
          fontSize={16}
        />
      </View>
    );
  }

  const AmountView = () => {
    return (
      <View style={[
        styles.amountView,
        isMobile ? { gap: 0 } : {}
      ]}>
        <Text style={[
          { fontWeight: "bold", color: theme.colors.primary },
          isMobile ? { paddingLeft: 16 } : {}
        ]}>
          {t(`invoice.awaiting-payment.send-value-label`, { currency: selectedCurrency.name })}
        </Text>

        <InputWithCopyButton
          value={amountOut}
          formattedValue={CurrencyUtils.formatCurrency(CurrencyUtils.getNumberByValue(amountOut), selectedCurrency.currency, true, selectedCurrency.network.decimals)}
          fontSize={20}
        />
      </View>
    );
  }

  return (
    <>
      <View style={[
        {
          display: "flex",
          flexDirection: "column",
          gap: isMobile ? 8 : 4,
        },
        isMobile ? {
          paddingBottom: 8,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.primaryHover
        } : {}
      ]}>
        <Text style={[
          {
            fontWeight: "bold",
            color: theme.colors.primary,
            textAlign: "center",
            fontSize: 16,
            paddingVertical: 8,
          },
          isMobile ? {
            paddingTop: 0,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.primaryHover
          } : {}
        ]}>
          {t("invoice.awaiting-payment.title")}
        </Text>

        {timer}
      </View>

      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>{t(`invoice.awaiting-payment.subtitle.send`) + " "}</Text>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
          <CurrencyLogo
            currency={selectedCurrency.currency}
            style={{ width: 20, height: 20, marginTop: -2 }}
          />
          <Text style={{ fontWeight: "bold", fontSize: 16 }}>{selectedCurrency.currency}</Text>
        </View>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>{" " + t(`invoice.awaiting-payment.subtitle.network`) + " "}</Text>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
          <CurrencyLogo
            currency={network.network.currencySymbol}
            style={{ width: 20, height: 20, marginTop: -2 }}
          />
          <Text style={{ fontWeight: "bold", fontSize: 16 }}>{network.network.name}</Text>
        </View>
      </View>

      <Text style={[isMobile ? { textAlign: "center", paddingHorizontal: 16 } : {}, { marginBottom: 8 }]}>
        {t(`invoice.awaiting-payment.info`, { currency: selectedCurrency.name })}
      </Text>

      <AddressView />

      <AmountView />
    </>
  );
}
