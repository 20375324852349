import BottomSheet, {
	BottomSheetBackdrop,
	BottomSheetBackdropProps,
	BottomSheetModal,
} from "@gorhom/bottom-sheet";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";
import React, { useCallback, useMemo, useRef } from "react";
import AuthService from "../../services/AuthService";
// import { Navigator } from "./BottomSheetNavigator";
import { useAuth } from "../../hooks/useAuth";

import {
	StackNavigationOptions,
	createStackNavigator,
	TransitionPresets,
} from "@react-navigation/stack";

import Animated, {
	Extrapolate,
	interpolate,
	useAnimatedStyle,
} from "react-native-reanimated";
import { NavigationContainer } from "@react-navigation/native";
import UserAddressRegister from "../../components/AuthBottomSheet/UserAddressRegister";
import LoginBottomSheetData from "../../components/AuthBottomSheet/LoginBottomSheetData";
import LoginBottomSheetCode from "../../components/AuthBottomSheet/LoginBottomSheetCode";
import UserContactRegisterBottomSheet from "../../components/AuthBottomSheet/UserContactRegister";
import UserInfoRegisterBottomSheet from "../../components/AuthBottomSheet/UserInfoRegister";
import RegistrationCompleted from "../../components/AuthBottomSheet/RegistrationCompleted";

const authService = new AuthService();

const Stack = createStackNavigator();

export default function CustomAuthBottomSheet() {
	const { handleSheetChanges, bottomSheetModalRef, snapPoints } = useAuth();

	const screenOptions = useMemo<StackNavigationOptions>(
		() => ({
			...TransitionPresets.SlideFromRightIOS,

			headerShown: false,
			safeAreaInsets: { top: 0 },
			cardStyle: {
				backgroundColor: "white",
				overflow: "visible",
			},
		}),
		[]
	);

	// renders
	const renderBackdrop = useCallback(
		(props: any) => <BottomSheetBackdrop {...props} />,
		[]
	);

	return (
		<BottomSheetModal
			onChange={handleSheetChanges}
			ref={bottomSheetModalRef}
			backdropComponent={renderBackdrop}
			snapPoints={snapPoints}
			index={1}
		>
			<NavigationContainer independent={true}>
				<Stack.Navigator screenOptions={screenOptions}>
					<Stack.Screen name="dataScreen" component={LoginBottomSheetData} />
					<Stack.Screen name="codeScreen" component={LoginBottomSheetCode} />
					<Stack.Screen
						name="userAddressRegisterScreen"
						component={UserAddressRegister}
					/>
					<Stack.Screen
						name="userContactRegisterScreen"
						component={UserContactRegisterBottomSheet}
					/>
					<Stack.Screen
						name="userInfoRegisterScreen"
						component={UserInfoRegisterBottomSheet}
					/>
					<Stack.Screen
						name="registrationCompletedScreen"
						component={RegistrationCompleted}
					/>
				</Stack.Navigator>
			</NavigationContainer>
		</BottomSheetModal>
	);
}

const styles = StyleSheet.create({});
