import Home from "../../../pages/Home";
import Invoice from "../../../pages/Invoice";
import Receipt from "../../../pages/Receipt";
import {
	ImageBackground,
	Platform,
	StyleProp,
	View,
	ViewStyle,
} from "react-native";
import React, { ReactNode } from "react";
import AmountChoice from "../../../pages/Payment/steps/AmountChoice";
import CryptoSelect from "../../../pages/Payment/steps/CryptoSelect";
import History from "../../../pages/Payment/steps/History";
import PosSendPayment from "../../../pages/Payment/steps/SendPayment";
import Main from "../../../pages/Payment/steps/Main";
import { useResponsive } from "../../hooks/useResponsive";
import { useMultiTenant } from "../../providers/multi-tenant.provider";
import {
	createStackNavigator,
	TransitionPresets,
	TransitionSpecs,
} from "@react-navigation/stack";
import commonStyles from "../../styles/commonStyles";
import PosPaymentDetected from "../../../pages/Payment/steps/PaymentDetected";
import Profile from "../../../pages/Payment/steps/Profile";
import PosPaymentPaid from "../../../pages/Payment/steps/PaymentPaid";
import PosProvider from "../../providers/pos.provider";
import { CurrencyProvider } from "../../hooks/useCurrency";
import TestFormBottomSheet from "../../components/TestFormBottomSheet";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";

const Stack = createStackNavigator();

const Layout = ({ children }: { children: ReactNode }) => {
	const { responsiveMode } = useResponsive();
	const isMobile = responsiveMode === "mobile";

	const { tenant } = useMultiTenant();

	return (
		<>
			<ImageBackground
				source={isMobile ? undefined : tenant.background.image}
				resizeMode={"cover"}
				style={{
					flex: 1,
				}}
				imageStyle={{
					resizeMode: "cover",
				}}
			>
				<View style={isMobile ? [{ flex: 1 }] : [commonStyles.stackContainer]}>
					<View
						style={
							isMobile
								? [{ flex: 1 }]
								: [commonStyles.stackCard, commonStyles.cardShadow]
						}
					>
						{children}
					</View>
				</View>
			</ImageBackground>
		</>
	);
};

const PoSScreens = () => {
	const { responsiveMode } = useResponsive();
	const isMobile = responsiveMode === "mobile";

	return (
		<>
			<Stack.Navigator
				initialRouteName={"main"}
				screenOptions={{
					headerShown: false,
					detachPreviousScreen: false,
					cardStyle: isMobile
						? ({
								maxHeight: "-webkit-fill-available",
						  } as unknown as StyleProp<ViewStyle>)
						: {},
				}}
			>
				<Stack.Screen name={"amount-choice"} component={AmountChoice} />
				<Stack.Screen name={"crypto-select"} component={CryptoSelect} />
				<Stack.Screen name={"send-payment"} component={PosSendPayment} />
				<Stack.Screen
					name={"payment-detected"}
					component={PosPaymentDetected}
				/>
				<Stack.Screen name={"payment-paid"} component={PosPaymentPaid} />
				<Stack.Screen
					name={"profile"}
					component={Profile}
					options={{
						...TransitionPresets.ModalSlideFromBottomIOS,
					}}
				/>
				<Stack.Screen name={"history"} component={History} />
				<Stack.Screen name={"main"} component={Main} />
			</Stack.Navigator>
		</>
	);
};

export default function () {
	return (
		<Layout>
				<CurrencyProvider>
					<PosProvider>
						<Stack.Navigator
							initialRouteName={"Home"}
							screenOptions={{
								title: "...",
								headerShown: false,
								cardStyle: {
									alignItems: "center",
									overflow: Platform.OS === "web" ? ("auto" as any) : undefined,
									paddingBottom: 0,
									backgroundColor: "#FFF",
								},
							}}
						>
							<Stack.Screen name={"Home"} component={Home} />
							<Stack.Screen
								name={"Invoice"}
								component={Invoice}
								options={{
									cardStyle: {
										overflow:
											Platform.OS === "web" ? ("auto" as any) : undefined,
									},
								}}
							/>
							<Stack.Screen name={"Receipt"} component={Receipt} />

							<Stack.Screen
								name={"pos"}
								component={PoSScreens}
								options={{
									cardStyle: {
										width: "100%",
									},
								}}
							/>
						</Stack.Navigator>
					</PosProvider>
				</CurrencyProvider>
		</Layout>
	);
}
