import {StyleSheet, Text, View} from "react-native";
import React from "react";

interface Props {
  title: string
}
export function PosTitle({ title }: Props) {
  return (
    <View>
      <Text style={styles.title}>{ title }</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontFamily: "Inter_500Medium",
    textAlign: "center",
    fontSize: 24,
    padding: 12,
  }
})
