import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {useCustomTheme} from "../Theme";
import styled from "styled-components/native";
import {useState} from "react";
import React from "react";
import {Platform} from "react-native";
import {css} from "styled-components";

interface BackButtonProps {
  onClick: () => void
}

const isWeb = Platform.OS === "web";

export default function BackButton({ onClick }: BackButtonProps) {
  const theme = useCustomTheme();

  const [onPressEffect, setOnPressEffect] = useState(false);

  const handleOnClick = () => {
    if(!onPressEffect) {
      setOnPressEffect(true);
      setTimeout(() => {
        setOnPressEffect(false);
      }, 100)
    }

    onClick();
  }

  return (
    <CustomPressable
      onPress={handleOnClick}
    >
      {
        ({ pressed, hovered }: any) => (
          <>
            <CustomBackRipple style={hovered ? { width: 40, height: 40 } : {}}/>

            <CustomBackIcon
              name={"arrow-left"}
              color={pressed || onPressEffect ? theme.colors.primary : "#FFF"}
              size={32}
            />
          </>
        )
      }

    </CustomPressable>
  )
}

const CustomBackRipple = styled.View`
  position: absolute;
  ${
    isWeb ? css`
    transition-duration: 0.4s;
    ` : css``
  } 
  height: 0;
  width: 0;
  background-color: white;
  opacity: 0.3;
  border-radius: 32px;
`

const CustomBackIcon = styled(MaterialCommunityIcons)`
  ${
    isWeb ? css`
    transition-duration: 0.2s;
    ` : css``
  }
`

const CustomPressable = styled.Pressable`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
  cursor: pointer;
  color: #FFF;
  ${
    isWeb ? css`
    transition-duration: 0.2s;
    ` : css``
  }
`
