import React, {
	createContext,
	ReactNode,
	Ref,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import BottomSheet, { BottomSheetModal } from "@gorhom/bottom-sheet";
import API from "../services/api";
import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import AuthService from "../services/AuthService";

interface UserModel {
	name: string;
	email: string;
}

interface LoginModel {
	email: string;
	token?: string;
}

interface AuthContextData {
	user: UserModel | null;
	isLogged: boolean;
	isLoading: boolean;
	login(email: string, token?: string): Promise<void>;
	logout(): void;

	handleCloseBottomSheet: () => void;
	handleOpenBottomSheet: () => void;
	loginBottomSheetStatus: boolean;

	validToken: boolean | undefined;

	//novo

	// setEmail: React.Dispatch<React.SetStateAction<string>>;
	handleEmail: (email: string) => void;
	handleLogin: (email: string, token?: string) => Promise<void>;
	users: LoginModel[];
	// email: string;
	userData: UserDataProps;

	handleUserInfoSubmit: ({
		personLegalName,
		personId,
		birthdate,
		companyLegalName,
		companyId,
	}: UserInfoProps) => void;

	handleUserContactSubmit: ({
		phoneCountryCode,
		phone,
	}: UserContactProps) => void;

	handleUserAddressSubmit: ({
		postalCode,
		addressName,
		addressNumber,
		addressSupplement,
		addressNeighborhood,
		addressCity,
		addressState,
		addressCountry,
	}: UserAddressProps) => void;

	//bottom sheet
	handlePresentModalPress: () => void;
	handleSheetChanges: (index: number) => void;
	bottomSheetModalRef: Ref<BottomSheetModalMethods> | undefined;
	snapPoints: string[];
}

// Novo

type UserType = "person" | "company" | undefined;

export interface UserInfoProps {
	personLegalName?: string;
	personId?: string;
	birthdate?: any;
	companyLegalName?: string;
	companyId?: string;
}

export interface UserContactProps {
	phoneCountryCode: string;
	phone: string;
}

export interface UserAddressProps {
	postalCode: string;
	addressName: string;
	addressNumber: string;
	addressSupplement?: string;
	addressNeighborhood: string;
	addressCity: string;
	addressState: string;
	addressCountry: string;
}

export interface UserDataProps {
	userType: UserType;
	userInfo: UserInfoProps;
	userContact: UserContactProps;
	userAddress: UserAddressProps;
}

//

const authService = new AuthService();

const userItem = "POS_USER";
const tokenItem = "POS_TOKEN_KEY";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<UserModel | null>(null);

	const [isLoading, setIsLoading] = useState(true);

	const [loginBottomSheetStatus, setLoginBottomSheetStatus] = useState(false);

	const bottomSheetRef = useRef<BottomSheet>(null);

	const handleCloseBottomSheet = () => {
		bottomSheetRef.current?.close();
		setLoginBottomSheetStatus(false);
	};

	const handleOpenBottomSheet = () => {
		setLoginBottomSheetStatus(true);
	};

	const isLogged = !!user;

	// Bottom Sheet

	const bottomSheetModalRef = useRef<BottomSheetModal>(null);

	const snapPoints = useMemo(() => ["25%", "75%"], []);

	const handlePresentModalPress = useCallback(() => {
		bottomSheetModalRef.current?.present();
	}, []);

	const handleSheetChanges = useCallback((index: number) => {
		console.log("handleSheetChanges", index);
	}, []);

	useEffect(() => {
		async function loadStorageData() {
			const storedUser = await AsyncStorage.getItem(userItem);
			const storedToken = await AsyncStorage.getItem(tokenItem);

			if (storedUser) {
				API.defaults.headers["x-jwt-token"] = `${storedToken}`;

				const user = JSON.parse(storedUser) as UserModel;
				if (!!user?.email) {
					setUser(user);
				}
			}
		}

		loadStorageData().then(() => {
			setIsLoading(false);
		});
	}, []);

	const login = async (email: string, token?: string) => {
		//TODO: login call
		setUser({
			name: email,
			email: email,
		});

		await AsyncStorage.setItem(userItem, JSON.stringify({ email }));
	};

	const logout = async () => {
		setUser(null);
		await AsyncStorage.removeItem(userItem);
		await AsyncStorage.removeItem(tokenItem);
	};

	//Novo

	const [userData, setUserData] = useState<UserDataProps>({
		userType: undefined,
		userInfo: {
			personLegalName: "",
			personId: "",
			birthdate: "",
			companyLegalName: "",
			companyId: "",
		},
		userContact: {
			phoneCountryCode: "",
			phone: "",
		},
		userAddress: {
			postalCode: "",
			addressName: "",
			addressNumber: "",
			addressSupplement: "",
			addressNeighborhood: "",
			addressCity: "",
			addressState: "",
			addressCountry: "",
		},
	});

	const [userEmail, setUserEmail] = useState("financeiro@hubchain.com");
	// const [userEmail, setUserEmail] = useState("");

	function handleEmail(email: string) {
		setUserEmail(email);
	}

	const [users, setUsers] = useState([
		{
			email: "henri.lourenco@outlook.com",
		},
		{
			email: "eduardo.lourenco@outlook.com",
		},
	]);

	const code = "888888";

	const [validToken, setValidToken] = useState<boolean | undefined>(undefined);

	const handleLogin = async (token?: string) => {
		if (code === token) {
			login(userEmail);
			handleCloseBottomSheet();
			setValidToken(true);
		} else {
			setValidToken(false);
		}

		//await new Promise((r) => setTimeout(r, 2000)); fake loading
		//TODO: API CALL
		// await authService.login(email, token); preview auth api call
		// await authService.login(userEmail, token);
	};

	const handleUserInfoSubmit = ({
		personLegalName,
		personId,
		birthdate,
		companyLegalName,
		companyId,
	}: UserInfoProps) => {
		setUserData((state) => ({
			userType: undefined,
			userInfo: {
				personLegalName: personLegalName,
				personId: personId,
				birthdate: birthdate,
				companyLegalName: companyLegalName,
				companyId: companyId,
			},
			userContact: state.userContact,
			userAddress: state.userAddress,
		}));
	};

	const handleUserContactSubmit = ({
		phoneCountryCode,
		phone,
	}: UserContactProps) => {
		setUserData((state) => ({
			userType: undefined,
			userInfo: state.userInfo,
			userContact: {
				phoneCountryCode: phoneCountryCode,
				phone: phone,
			},
			userAddress: state.userAddress,
		}));
	};

	const handleUserAddressSubmit = ({
		postalCode,
		addressName,
		addressNumber,
		addressSupplement,
		addressNeighborhood,
		addressCity,
		addressState,
		addressCountry,
	}: UserAddressProps) => {
		setUserData((state) => ({
			userType: undefined,
			userInfo: state.userInfo,
			userContact: state.userContact,
			userAddress: {
				postalCode: postalCode,
				addressName: addressName,
				addressNumber: addressNumber,
				addressSupplement: addressSupplement,
				addressNeighborhood: addressNeighborhood,
				addressCity: addressCity,
				addressState: addressState,
				addressCountry: addressCountry,
			},
		}));
	};

	useEffect(() => {
		console.log({ userData });
	}, [userData]);

	useEffect(() => {
		handleLogin("888888");
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				isLogged,
				isLoading,
				login,
				logout,
				userData,
				handleEmail,
				handleLogin,
				users,
				validToken,
				handleUserInfoSubmit,
				handleUserContactSubmit,
				handleUserAddressSubmit,

				handleOpenBottomSheet,
				handleCloseBottomSheet,
				loginBottomSheetStatus,

				//bottom sheet
				handlePresentModalPress,
				handleSheetChanges,
				bottomSheetModalRef,
				snapPoints,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider.");
	}

	return context;
};

export { AuthProvider, useAuth };
