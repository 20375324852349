import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { CustomButton } from "../../../../src/components/CustomButton";
import Header from "../../../../src/components/Header";
import HistoryCard from "../../components/HistoryCard";
import { FlatList, Text, View } from "react-native";
import { PosTitle } from "../../components/Title";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { styles } from "./styles";
import LanguageView from "../../../../src/components/LanguageView";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useAuth } from "../../../../src/hooks/useAuth";
import ConfirmModal from "../../../../src/components/ConfirmModal";

export default function History() {
	const navigation = useCustomNavigation();
	const { t } = useTranslation();
	const { user, logout } = useAuth();

	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const currencies = [
		{
			name: "wallet",
			icon: "wallet-outline",
		},
		{
			name: "history",
			icon: "history",
		},
		{
			name: "adjust",
			icon: "cog-outline",
		},
		{
			name: "support",
			icon: "phone",
		},
	];

	return (
		<>
			<View style={[styles.container]}>
				<Header onBackClick={() => navigation.goBack()} isOnProfile={true} />
				<PosTitle title="History" />

				<View>
					<FlatList
						data={currencies}
						renderItem={({ item }) => (
							<HistoryCard
								name={item.name}
								icon={item.icon}
								isSelected={false}
								onSelect={() => {}}
							/>
						)}
						keyExtractor={(item) => item.name}
					/>
				</View>

				<View style={[styles.footer]}>
					<View style={styles.languageView}>
						<LanguageView />
					</View>
					<CustomButton
						label={t("pos.leave")}
						onPress={() => setShowLogoutModal(true)}
					/>
				</View>
			</View>
		</>
	);
}
