import { Text } from "react-native-paper";
import {Image, ImageSourcePropType, View} from "react-native";
import styles from "../../styles";
import QRCode from "react-native-qrcode-svg";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../../src/Theme";
import { useMultiTenant } from "../../../../src/providers/multi-tenant.provider";
import ScreenUtils from "../../../../src/utils/ScreenUtils";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";
import { useContext } from "react";
// @ts-ignore
import iconInfo from "../../../../assets/icon/icon-info.png";
import React from "react";
import { useResponsive } from "../../../../src/hooks/useResponsive";
import {InvoiceContext} from "../../context";

const isSmallScreen = ScreenUtils.isSmallScreen();

export default function QrCodeView() {
	const { t } = useTranslation();
	const theme = useCustomTheme();
	const { tenant } = useMultiTenant();

	const { invoice, selectedCurrency } = useContext(InvoiceContext);

	const network = selectedCurrency.network;
	const address = network.address;
	const amountOut = network.amount;
	const amountIn = invoice ? CurrencyUtils.getNumberByValue(invoice.amount) : 0;
	const code = invoice?.code;

	const { responsiveMode } = useResponsive();

	const isMobile = responsiveMode === "mobile";

	return (
		<>
			<View style={[styles.qrCodeImageView, isMobile && { height: 164 }]}>
				<View style={[styles.qrCodeImageCard]}>
					<QRCode
						value={address}
						size={160}
						quietZone={8}
						color="black"
						logo={tenant.favicon as ImageSourcePropType}
						logoBackgroundColor={"#FFFFFF"}
						logoSize={40}
						logoBorderRadius={48}
						logoMargin={2}
						ecl={"H"}
					/>
				</View>
			</View>

			<View
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					alignItems: "center",
					marginTop: 4
				}}
			>
				<Text style={{ fontSize: 12 }}>{code}</Text>
				<Text style={{ fontSize: 16 }}>
					{CurrencyUtils.formatCurrency(amountIn, invoice.currency)}
				</Text>
			</View>

			<View
				style={{
					backgroundColor: "#f5f8ff",
					borderRadius: 4,
					paddingHorizontal: 16,
					paddingVertical: 8,
					marginVertical: isMobile ? 8 : 0,
					marginHorizontal: isMobile ? 16 : 0,
					display: "flex",
					flexDirection: "row",
					gap: 8,
				}}
			>
				<Image source={iconInfo} style={{ width: 16, height: 16 }} />
				<Text style={{ color: theme.colors.outline }}>
					{t(`invoice.awaiting-payment.disclaimer`, {
						currency: selectedCurrency.name,
						network: network.network.name,
					})}
				</Text>
			</View>
		</>
	);
}
