import { View, Text, TouchableOpacity, Image } from "react-native";

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../../src/Theme";

import styles from "./styles";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { usePayment } from "../../../../src/hooks/usePayment";

type KeyboardProps = {
	onKeyPress: (amount: string) => void;
	onDeletePress: () => void;
};

type KeyProps = {
	keyLabel: string | ReactNode;
	handlePress: () => void;
};

interface PaymentMethodsProps {
	handlePress: () => void;
	// name: string;
	// icon: string;
	// description: string;
}

export default function PaymentMethods({ handlePress }: PaymentMethodsProps) {
	const theme = useCustomTheme();
	const navigation = useCustomNavigation();
	const { t } = useTranslation();
	const { paymentMethod } = usePayment();

	const Icon = paymentMethod.icon;

	return (
		<View style={styles.changeMethodContainer}>
			<View style={styles.changeMethodContent}>
				<View style={styles.changeMethodIcon}>
					<Icon
						style={{
							width: 40,
							height: 40,
						}}
					/>
				</View>
				<View style={styles.changeMethodContentText}>
					<Text selectable={false} style={styles.changeMethodTitle}>
						{paymentMethod.title}
					</Text>
					<Text
						selectable={false}
						style={[styles.changeMethodSubtitle, styles.subTitle]}
					>
						{paymentMethod.description}
					</Text>
				</View>
			</View>
			<View>
				<TouchableOpacity onPress={handlePress}>
					<Text
						style={[
							styles.changeMethodButton,
							{ color: theme.colors.secondary },
						]}
					>
						{t("pos.steps.amount-choice.payment-methods.change")}
					</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
}
