import { MD3LightTheme } from "react-native-paper";
import { ThemeProp } from "react-native-paper/lib/typescript/src/types";
import favicon from "../../../assets/favicon/bankei.ico";
import { TenantAppDomainEnum, TenantConfig } from "./types";
import { customTheme } from "../../Theme";

import background from "../../../assets/backgrounds/bankei.png";
import headerLogo from "../../../assets/header-icons/bankei.png";
import splashLogo from "../../../assets/splash/bankei.png";

const _themeColors = {
  ...MD3LightTheme.colors,
  ...customTheme.colors,
  primary: "#0879B3",
  primaryHover: "rgb(8 121 179, 0.29)",
  secondary: "#0879B3",
  secondaryHover: "rgb(8 121 179, 0.29)",
  secondaryLight: "rgba(246,16,103,0.29)",
  header: "#0879B3"
}

const _theme: ThemeProp = {
  ...MD3LightTheme,
  custom: "property",
  colors: _themeColors,
} as ThemeProp;

export const bankeiTenant: TenantConfig = {
  name: TenantAppDomainEnum.BANKEI,
  appTitle: "Bankei PAY",
  documentTitle: "..:: Bankei - Payment",
  theme: _theme,
  favicon: favicon,
  email: "suporte@bankei.com.br",
  website: "https://bankei.com.br/",
  headerLogo: headerLogo,
  splashLogo: splashLogo,
  background: {
    color: "#FFF",
    image: background
  }
}
