import { useContext } from "react";
import { Linking, Pressable, View } from "react-native";
import * as Progress from "react-native-progress";
import AntDesign from "@expo/vector-icons/AntDesign";
import { Text } from "react-native-paper";

import styles from "../styles";
import { useCustomTheme } from "../../../src/Theme";
import { useTranslation } from "react-i18next";
import React from "react";
import {InvoiceContext} from "../context";

export default function AwaitingDepositStep() {
	const theme = useCustomTheme();
	const { t } = useTranslation();
	const { selectedCurrency, invoice } = useContext(InvoiceContext);

	if (!invoice?.payments[0]?.transactions[0]) {
		return null;
	}

	const { block, transactionIdExplorerUrl } =
		invoice.payments[0].transactions[0];
	const { confirmations, minConfirmations } = block;

	const ConfirmationProgressBar = () => {
		const progress = confirmations / minConfirmations;

		return (
			<View style={styles.footerView}>
				<View style={styles.progressBarView}>
					<Text
						style={[styles.progressTextView, { color: theme.colors.outline }]}
					>
						{t(`invoice.payment-made.confirmations`)}{" "}
						{`${
							confirmations > minConfirmations
								? minConfirmations
								: confirmations
						}/${minConfirmations}`}
					</Text>
					<Progress.Bar
						progress={progress}
						height={6}
						width={256}
						borderWidth={0}
						color={theme.colors.primary}
						unfilledColor={theme.colors.outlineVariant}
					/>
				</View>
			</View>
		);
	};

	return (
		<>
			<View style={{ justifyContent: "center", alignItems: "center" }}>
				<View style={styles.statusIconView}>
					<AntDesign
						name={"clockcircleo"}
						size={112}
						color={theme.colors.success}
					/>
				</View>
			</View>

			<Text style={[styles.statusTitleText, { color: theme.colors.success }]}>
				{t(`invoice.payment-made.title`)}
			</Text>

			<View style={styles.statusTextView}>
				<Text style={[styles.statusText, { color: theme.colors.outline }]}>
					{t(`invoice.payment-made.info-1`, {
						currency: selectedCurrency.name,
					})}
				</Text>
				<Text style={[styles.statusText, { color: theme.colors.outline }]}>
					{t(
						`invoice.payment-made.info-2${
							minConfirmations === 1 ? "-singular" : ""
						}`,
						{ replace: { minConfirmations: minConfirmations } }
					)}
				</Text>
			</View>

			<Pressable onPress={() => Linking.openURL(transactionIdExplorerUrl)}>
				{({ hovered }: any) => (
					<Text
						style={[
							styles.statusText,
							{
								color: hovered ? theme.colors.secondary : theme.colors.primary,
							},
						]}
					>
						{t(`invoice.payment-made.blockchain-view`)}
					</Text>
				)}
			</Pressable>

			<ConfirmationProgressBar />
		</>
	);
}
