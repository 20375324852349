import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Dimensions} from "react-native";

const windowDimensions = Dimensions.get("window");

type responsiveModeType = "desktop" | "tablet" | "mobile";

interface ResponsiveContextData {
  responsiveMode: responsiveModeType
}

const ResponsiveContext = createContext<ResponsiveContextData>({} as ResponsiveContextData);

const getResponsiveModeByWidth = (width: number) => {
  let mode: responsiveModeType = "desktop";

  if(width < 768) {
    mode = "tablet";
  }

  if(width < 480) {
    mode = "mobile";
  }

  return mode;
}

const ResponsiveProvider = ({ children }: { children: ReactNode }) => {
  const [responsiveMode, setResponsiveMode] = useState<responsiveModeType>(getResponsiveModeByWidth(windowDimensions.width))

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({window}) => {
        setResponsiveMode(getResponsiveModeByWidth(window.width));
      },
    );
    return () => subscription?.remove();
  });

  return (
    <ResponsiveContext.Provider value={{ responsiveMode }}>
      { children }
    </ResponsiveContext.Provider>
  )
}

const useResponsive = () => {
  const context = useContext(ResponsiveContext);
  if (!context) {
    throw new Error("useResponsive must be used within an ResponsiveProvider.");
  }
  return context;
}


export { ResponsiveProvider, useResponsive };
