import { StyleSheet } from "react-native";
import { commonFonts } from "../../../../src/styles/commonStyles";

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	content: {
		paddingHorizontal: 16,
	},
	timerText: {
		fontFamily: commonFonts.regular,
		fontSize: 14,
	},
	amountText: {
		fontFamily: commonFonts.medium,
		fontSize: 24,
	},
	networkText: {
		fontFamily: commonFonts.medium,
		fontSize: 14,
	},
	walletText: {
		fontFamily: commonFonts.medium,
		fontSize: 16,
		textAlign: "center",
	},
	shareButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: 56,
	},
	shareButtonText: {
		fontFamily: commonFonts.medium,
		fontSize: 16,
	},
	footer: {
		flex: 1,
		justifyContent: "flex-end",
	},
	list: {
		marginHorizontal: 24,
	},
	button: {
		backgroundColor: "#F8F8F8",
		borderColor: "transparent",
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		borderRadius: 8,
		borderWidth: 1,
		width: "100%",
		flex: 1,
	},
	selected: {
		borderColor: "#F61067",
		shadowColor: "#F61067",
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
	languageView: {
		alignItems: "center",
		justifyContent: "center",
		padding: 4
	},
	logos: {
		position: "relative",
		marginBottom: 16,
		alignSelf: "center",
	},
	title: {
		color: "#1C1B1F",
		fontFamily: "Inter_400Regular",
		fontSize: 14,
	},
	cardContainer: {
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		aspectRatio: 1,
		width: "50%",
		padding: 8,
	},
	email: {
		flexDirection: "row",
		alignItems: "center",
		borderWidth: 1,
		borderRadius: 16,
		borderColor: "rgba(0, 0, 0, 0.2)",
		paddingVertical: 4,
		paddingHorizontal: 8,
		marginBottom: 8,
	},
	emailText: {
		fontFamily: "Inter_500Medium",
		fontSize: 16,
		paddingLeft: 8
	},
});
