import React, {createContext, ReactNode, useContext} from "react";
import CurrencyService, {CurrencyData, MarketData} from "../services/CurrencyService";
import {useQuery} from "@tanstack/react-query";

interface CurrencyContextContextData {
    currencies: CurrencyData[],
    markets: MarketData[],
    isFetching: boolean,
    isFetchingCurrencies: boolean,
    isFetchingMarkets: boolean
}

const CurrencyContext = createContext<CurrencyContextContextData>({} as CurrencyContextContextData);

const currencyService = new CurrencyService();

const CurrencyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { data: _CurrenciesDataResponse, isFetching: isFetchingCurrencies } = useQuery(["currencies"], {
        queryFn: currencyService.getCurrencies,
        retry: false,
        refetchOnWindowFocus: true,
        staleTime: 60 * 60 * 1000// 1 hour
    });

    const { data: _MarketsDataResponse, isFetching: isFetchingMarkets } = useQuery(["markets"], {
        queryFn: currencyService.getMarkets,
        retry: false,
        refetchOnWindowFocus: true,
        staleTime: 60* 60 * 1000// 1 hour
    });

    const isFetching = isFetchingCurrencies || isFetchingMarkets;

    const currencies = _CurrenciesDataResponse?.data?.data || [];
    const markets = _MarketsDataResponse?.data?.data || [];

    return (
        <CurrencyContext.Provider value={{ currencies: currencies || [], markets, isFetching, isFetchingCurrencies, isFetchingMarkets }}>
            { children }
        </CurrencyContext.Provider>
    );
}

const useCurrency = () => {
    const context = useContext(CurrencyContext);
    if (!context) {
        throw new Error("useCurrency must be used within an CurrencyProvider.");
    }
    return context;
}


export { CurrencyProvider, useCurrency };
