import { Text, View } from "react-native";
import Header from "../../../../src/components/Header";
import React, { useEffect, useRef, useState } from "react";
import { styles } from "./styles";
import { PosTitle } from "../../components/Title";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../../src/Theme";
import { CustomButton } from "../../../../src/components/CustomButton";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import Utils from "../../../../src/utils/Utils";
import { ActivityIndicator, ProgressBar } from "react-native-paper";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import CurrencyLogo from "../../../../src/components/CurrencyLogo";
import { usePos } from "../../../../src/providers/pos.provider";
import { TCurrencySymbol } from "../../../../src/utils/currency";

const ViewInBlockchainButton = ({ link }: { link: string }) => {
	const theme = useCustomTheme();
	const { t } = useTranslation();

	return (
		<CustomButton
			onPress={() => {}}
			label={t(`pos.steps.detected-payment.view-transaction`)}
			type={"ghost"}
			style={{
				backgroundColor: "transparent",
			}}
			labelStyle={{
				color: theme.colors.secondary,
			}}
			rightAddon={
				<MaterialCommunityIcons
					name={"open-in-new"}
					size={16}
					color={theme.colors.secondary}
				/>
			}
		/>
	);
};

const ConfirmationsProgress = () => {
	const { t } = useTranslation();
	const theme = useCustomTheme();
	const navigation = useCustomNavigation();
	const { paymentState } = usePos();

	const minConfirmations = Number(
		paymentState?.paymentMethod?.network?.minConfirmations || 1
	);

	const mockConfirmationInterval = useRef<NodeJS.Timer>(null);

	const [confirmations, setConfirmations] = useState(0);

	const startPaymentLimitTimer = (_time?: number) => {
		if (!!mockConfirmationInterval.current) {
			clearInterval(mockConfirmationInterval.current);
		}

		mockConfirmationInterval.current = setInterval(() => {
			setConfirmations((prevConfirmations) => {
				if (prevConfirmations >= minConfirmations) {
					clearInterval(mockConfirmationInterval?.current!);
					return minConfirmations;
				}

				const mockNextConfirmations = Utils.getRandomInt(3);

				let sumConfirmations = prevConfirmations + mockNextConfirmations;

				if (sumConfirmations >= minConfirmations) {
					sumConfirmations = minConfirmations;
				}

				return sumConfirmations;
			});
		}, 1000);
	};

	useEffect(() => {
		if (!!paymentState) {
			startPaymentLimitTimer();
		}
	}, [paymentState]);

	useEffect(() => {
		if (confirmations >= minConfirmations) {
			setTimeout(() => {
				navigation.customReplace("payment-paid");
			}, 300);
		}
	}, [confirmations]);

	if (!paymentState) {
		return null;
	}

	return (
		<View style={{ marginBottom: 16 }}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: 4,
				}}
			>
				<Text style={styles.confirmationsViewText}>
					{t(`pos.steps.detected-payment.confirmations.label`)}
				</Text>
				<Text
					style={styles.confirmationsViewText}
				>{`${confirmations}/${minConfirmations}`}</Text>
			</View>

			<ProgressBar
				animatedValue={confirmations / minConfirmations}
				color={theme.colors.secondary}
				style={{ borderRadius: 8, height: 8, backgroundColor: "#D9D9D9" }}
			/>

			<View
				style={{
					flexDirection: "row",
					padding: 8,
					paddingRight: 8,
					backgroundColor: "#D9D9D9",
					borderRadius: 8,
					marginTop: 8,
					gap: 8,
					width: "100%",
				}}
			>
				<MaterialCommunityIcons
					name="information-outline"
					size={24}
					color={"#79747E"}
					style={{}}
				/>
				<View style={{ flex: 1 }}>
					<Text style={[styles.confirmationsViewText]}>
						{t(`pos.steps.detected-payment.confirmations.info`, {
							replace: { minConfirmations: minConfirmations },
						})}
					</Text>
				</View>
			</View>
		</View>
	);
};
export default function PosPaymentDetected() {
	const navigation = useCustomNavigation();
	const { t } = useTranslation();
	const theme = useCustomTheme();
	const { paymentState } = usePos();

	if (!paymentState || !paymentState?.paymentMethod) {
		return null;
	}

	const amount = paymentState?.amountIn;
	const { currency, network } = paymentState?.paymentMethod;
	const transactionId = "0x04c88e6a...0aa703f3";
	const wallet: string =
		network.currencySymbol === TCurrencySymbol.BTC
			? "3BioTxVSYTLAvvhGsV4vZPU7de2xcKwrr5"
			: network.currencySymbol === TCurrencySymbol.TRX
			? "TNyoSVavZe9hFGWE7RfLfFv6NBbuWdKFsB"
			: "0x9e78355Ff071b82910dB4add9D4e115aE0FBb1ce";

	return (
		<View style={[styles.container]}>
			<Header />
			<PosTitle title={t(`pos.steps.detected-payment.title`)} />

			<View style={[styles.content]}>
				<ConfirmationsProgress />

				<View style={{ alignItems: "center" }}>
					<Text style={[styles.amountText]}>
						{amount
							? CurrencyUtils.formatCurrency(
									amount,
									currency.currency,
									true,
									currency.decimals
							  )
							: null}
					</Text>
					<View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
						<CurrencyLogo
							currency={network.currencySymbol}
							style={{ width: 20, height: 20 }}
						/>
						<Text style={[styles.networkText]}>
							{network.description +
								(network.contractType ? `-${network.contractType}` : ``)}
						</Text>
					</View>
				</View>

				<View style={{ marginVertical: 32 }}>
					<ActivityIndicator size={98} color={theme.colors.secondary} />
				</View>

				<View style={{ alignItems: "center", marginBottom: 16 }}>
					<Text style={[styles.walletText]} numberOfLines={1}>
						{wallet}
					</Text>
				</View>

				<View style={{ alignItems: "center", marginBottom: 16 }}>
					<Text style={[styles.walletText]} numberOfLines={1}>
						{transactionId}
					</Text>
				</View>
			</View>

			<ViewInBlockchainButton link={transactionId} />

			<View style={[styles.footer]}>
				<CustomButton
					label={t(`general.close`)}
					onPress={() => {
						navigation.customReplace("main");
					}}
				/>
			</View>
		</View>
	);
}
