import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		padding: 8,
	},
	selected: {
		borderColor: "#F61067",
		shadowColor: "#F61067",
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
	button: {
		backgroundColor: "#F8F8F8",
		borderColor: "transparent",
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		borderRadius: 8,
		borderWidth: 1,
		width: "100%",
		flex: 1,
	},
	icon: {
		position: "relative",
		marginBottom: 8,
		alignSelf: "center",
	},
	onlyCurrencyLogo: {
		width: 96,
		height: 96,
	},
	currencyLogo: {
		width: 56,
		height: 56,
	},
	networkLogo: {
		width: 36,
		height: 36,
		borderWidth: 1,
		borderRadius: 36,
		borderColor: "#FFF",
		position: "absolute",
		right: -12,
		bottom: -12,
	},
	title: {
		color: "#1C1B1F",
		fontFamily: "Inter_400Regular",
		fontSize: 14,
	},
	subtitle: {
		color: "#79747E",
		fontFamily: "Inter_400Regular",
		fontSize: 12,
	},
	networkText: {
		color: "#1C1B1F",
		fontFamily: "Inter_400Regular",
		fontSize: 12,
	},
});

export default styles;
function calc(arg0: number): any | import("react-native").DimensionValue {
	throw new Error("Function not implemented.");
}
