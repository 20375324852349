import { useMultiTenant } from "../providers/multi-tenant.provider";
import { StyleSheet, View, Image, StatusBar, Pressable } from "react-native";
import useCustomNavigation from "../hooks/useCustomNavigation";
import { HeaderProfileButton } from "./HeaderProfileButton";
import AuthBottomSheet from "./AuthBottomSheet/AuthBottomSheet";
import { useTranslation } from "react-i18next";
import { CustomButton } from "./CustomButton";
import { useCustomTheme } from "../Theme";
import React, { useState } from "react";
import Constants from "expo-constants";
import BackButton from "./BackButton";
import { useAuth } from "../hooks/useAuth";
import CustomAuthBottomSheet from "../Navigation/AuthNavigation";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";

const statusBarHeight = Constants.statusBarHeight;

interface HeaderProps {
	onBackClick?: (() => void) | "back";
	isOnProfile?: boolean;
	showProfileButton?: boolean;
	variant?: "clean" | "solid";
	returnToMain?: boolean;
}

const Header = ({
	onBackClick,
	showProfileButton,
	variant = "solid",
	isOnProfile,
	returnToMain = true,
}: HeaderProps) => {
	const theme = useCustomTheme();
	const { tenant } = useMultiTenant();
	const { t } = useTranslation();

	const handleBackClick = () => {
		if (!onBackClick) {
			return;
		}

		if (onBackClick === "back") {
			return;
		}

		onBackClick();
	};

	const navigation = useCustomNavigation();

	const handlePressLogo = () => {
		navigation.customNavigate("main");
	};

	const {
		handleOpenBottomSheet,
		handleCloseBottomSheet,
		loginBottomSheetStatus,
		handlePresentModalPress,
	} = useAuth();

	return (
		<BottomSheetModalProvider>
			<View
				style={[
					styles.container,
					{
						backgroundColor:
							variant === "clean" ? "transparent" : theme.colors.header,
						paddingTop: statusBarHeight || 0,
					},
				]}
			>
				<StatusBar
					barStyle={variant === "clean" ? "dark-content" : "light-content"}
				/>

				<View style={styles.content}>
					{variant === "solid" && (
						<View style={styles.logoView}>
							<Pressable
								style={{ height: "100%", width: "100%" }}
								onPress={handlePressLogo}
								disabled={!returnToMain}
							>
								<Image style={styles.logoImage} source={tenant.headerLogo} />
							</Pressable>
						</View>
					)}

					{onBackClick && (
						<View style={[styles.backButtonView]}>
							<BackButton onClick={handleBackClick} />
						</View>
					)}

					{showProfileButton && (
						<View style={[styles.rightButton]}>
							<HeaderProfileButton
								variant={variant === "clean" ? "header" : "header-contrast"}
								onPressLogin={() => handleOpenBottomSheet()}
							/>
							{/* <HeaderProfileButton
								variant={variant === "clean" ? "header" : "header-contrast"}
								onPressLogin={handlePresentModalPress}
							/> */}
						</View>
					)}

					{/* {isOnProfile && (
						<View style={[styles.rightButton]}>
							<CustomButton
								label={t(`general.back`)}
								type={"outlined"}
								isInverted={true}
								style={{
									height: 32,
								}}
								labelStyle={{
									fontSize: 14,
								}}
								onPress={handleBackClick}
							/>
						</View>
					)} */}
				</View>
			</View>

			<CustomAuthBottomSheet />

			{loginBottomSheetStatus && (
				<View
					style={{
						position: "absolute",
						flex: 1,
						width: "100%",
						height: "100%",
						zIndex: 10,
					}}
				>
					<AuthBottomSheet />
					{/* <LoginBottomSheet onClose={() => setLoginBottomSheetOpen(false)} /> */}
				</View>
			)}
		</BottomSheetModalProvider>
	);
};

export default Header;

const styles = StyleSheet.create({
	container: {
		flexDirection: "column",
		width: "100%",
		height: statusBarHeight + 72,
		alignItems: "center",
		justifyContent: "flex-end",
	},
	content: {
		flexDirection: "row",
		width: "100%",
		height: 72,
		alignItems: "center",
		justifyContent: "center",
	},
	logoView: {
		height: 72,
		width: 160,
	},
	logoImage: {
		height: "100%",
		width: "100%",
		resizeMode: "contain",
	},
	backButtonView: {
		position: "absolute",
		left: 12,
	},
	rightButton: {
		position: "absolute",
		right: 12,
	},
});
