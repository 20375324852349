import {MD3LightTheme} from "react-native-paper";
import {ThemeProp} from "react-native-paper/lib/typescript/src/types";
import favicon from "../../../assets/favicon/hubchain.png";
import {TenantAppDomainEnum, TenantConfig} from "./types";
import {customTheme} from "../../Theme";

import headerLogo from "../../../assets/header-icons/hubchain.png";
import splashLogo from "../../../assets/splash/hubchain.png";

const _themeColors = {
  ...MD3LightTheme.colors,
  ...customTheme.colors,
  primary: "#204B94",
  primaryHover: "rgba(32,75,148,0.3)",
  secondary: "#204B94",
  header: "#204B94"
}

const _theme: ThemeProp = {
  ...MD3LightTheme,
  custom: "property",
  colors: _themeColors,
} as ThemeProp;

export const hubchainTenant: TenantConfig = {
  name: TenantAppDomainEnum.HUBCHAINBANK,
  appTitle: "Hubchain PAY",
  documentTitle: "Hubchain PAY - Payment",
  theme: _theme,
  favicon: favicon,
  email: "suporte@hubchainbank.com",
  website: "https://hubchain.com/",
  headerLogo: headerLogo,
  splashLogo: splashLogo,
  background: {
    color: "#F5F5F5"
  }
}
