import { MD3LightTheme } from "react-native-paper";
import { ThemeProp } from "react-native-paper/lib/typescript/src/types";
import favicon from "../../../assets/favicon/ezzepay.png";
import { TenantAppDomainEnum, TenantConfig } from "./types";
import { customTheme } from "../../Theme";

import background from "../../../assets/backgrounds/ezzepay.jpg";
import headerLogo from "../../../assets/header-icons/ezzepay.png";
import splashLogo from "../../../assets/splash/ezzepay.png";

const _themeColors = {
  ...MD3LightTheme.colors,
  ...customTheme.colors,
  primary: "#7b1fa2",
  primaryHover: "rgba(246,16,103,0.29)",
  secondary: "#F61067",
  secondaryHover: "#bb1046",
  secondaryLight: "rgba(246,16,103,0.29)",
  header: "#F61067"
}

const _theme: ThemeProp = {
  ...MD3LightTheme,
  custom: "property",
  colors: _themeColors,
} as ThemeProp;

export const ezzepayTenant: TenantConfig = {
  name: TenantAppDomainEnum.EZZEPAY,
  appTitle: "EzzePay",
  documentTitle: "..:: EzzePay - Payment",
  theme: _theme,
  favicon: favicon,
  email: "suporte@ezzepay.com.br",
  website: "https://ezzepay.com.br/",
  headerLogo: headerLogo,
  splashLogo: splashLogo,
  background: {
    color: "#FFF",
    image: background
  }
}
