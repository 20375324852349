import { TenantAppDomainEnum, TenantConfig } from "./types";

import { hubchainTenant } from "./hubchain";
import { ezzepayTenant } from "./ezzepay";
import { bankeiTenant } from "./bankei";
import { pinbankTenant } from "./pinbank";

export const TenantAliasConfig = {
  "ezzepay.io": [TenantAppDomainEnum.EZZEPAY],
  "bankei.io": [TenantAppDomainEnum.BANKEI],
  "cinkinvest.com": [TenantAppDomainEnum.CINKINVEST],
  "alpinec.com": [TenantAppDomainEnum.ALPINEC],
  "hubchainbank.com": [TenantAppDomainEnum.HUBCHAINBANK],
  "avilabank.com": [TenantAppDomainEnum.AVILABANK],
  "betraderclub.com": [TenantAppDomainEnum.BETRADERCLUB],
  "alexiapay.com": [TenantAppDomainEnum.ALEXIAPAY],
  "pinbank.com.br": [TenantAppDomainEnum.PINBANK],
}

export const tenants: { [key: string]: TenantConfig } = {
  [TenantAppDomainEnum.HUBCHAINBANK]: hubchainTenant,
  [TenantAppDomainEnum.EZZEPAY]: ezzepayTenant,
  [TenantAppDomainEnum.BANKEI]: bankeiTenant,
  [TenantAppDomainEnum.PINBANK]: pinbankTenant,
}
