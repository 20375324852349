import { TouchableRipple, TouchableRippleProps } from "react-native-paper";
import {
	GestureResponderEvent,
	Platform,
	StyleProp,
	StyleSheet,
	TextStyle,
	ViewStyle,
} from "react-native";
import { useCustomTheme } from "../Theme";
import { commonFonts } from "../styles/commonStyles";
import { ReactNode, useState } from "react";
import React from "react";

type CustomButtonTypes = "contained" | "outlined" | "ghost";

interface CustomButtonTypeStyle {
	labelStyle?: StyleProp<TextStyle>;
	labelStyleHover?: StyleProp<TextStyle>;
	buttonStyle?: StyleProp<ViewStyle>;
	buttonStyleHover?: StyleProp<ViewStyle>;
}

interface CustomButtonPros extends Omit<TouchableRippleProps, "children"> {
	content: ReactNode;
	isSelect?: boolean;
}

const isWeb = Platform.OS === "web";

export function CardButton({
	content,
	isSelect,
	style,
	...props
}: CustomButtonPros) {
	const theme = useCustomTheme();

	const [isHover, setIsHover] = useState(false);

	const [onPressEffect, setOnPressEffect] = useState(false);

	const handleOnPressIn = (event: GestureResponderEvent) => {
		setOnPressEffect(true);
	};

	const handleOnPressOut = (event: GestureResponderEvent) => {
		if (onPressEffect) {
			setTimeout(() => {
				setOnPressEffect(false);
			}, 50);
		}
	};

	const stylesByType: Record<CustomButtonTypes, CustomButtonTypeStyle> = {
		contained: {
			buttonStyle: {
				backgroundColor: theme.colors.secondary,
			},
			buttonStyleHover: {
				...(isWeb ? ({ filter: "brightness(90%)" } as ViewStyle) : {}),
			},
			labelStyle: {
				color: "#FFF",
			},
			labelStyleHover: {},
		},
		ghost: {
			buttonStyle: {
				backgroundColor: "#F8F8F8",
			},
			buttonStyleHover: {},
			labelStyle: {
				color: "#1C1B1F",
			},
			labelStyleHover: {
				color: theme.colors.secondary,
			},
		},
		outlined: {
			buttonStyle: {},
			buttonStyleHover: {},
			labelStyle: {},
			labelStyleHover: {},
		},
	};

	return (
		<TouchableRipple
			children={content}
			onHoverIn={() => setIsHover(true)}
			onHoverOut={() => setIsHover(false)}
			onPressIn={handleOnPressIn}
			onPressOut={handleOnPressOut}
			{...props}
			style={[
				styles.button,
				isHover
					? [
							styles.buttonStyleHover,
							{
								borderColor: theme.colors.secondary,
								shadowColor: theme.colors.secondary,
							},
					  ]
					: {},
				isSelect
					? [
							styles.selected,
							{
								borderColor: theme.colors.secondary,
								shadowColor: theme.colors.secondary,
							},
					  ]
					: {},
				{
					...(isWeb ? { userSelect: "none" } : {}),
				} as ViewStyle,
				Platform.OS === "ios" && onPressEffect
					? {
							opacity: 0.5,
					  }
					: {},
				style,
			]}
		/>
	);
}

const styles = StyleSheet.create({
	button: {
		justifyContent: "center",
		alignItems: "center",
		...(isWeb ? { transitionDuration: "0.3s" } : {}),
		backgroundColor: "#F8F8F8",
		borderColor: "transparent",
		transition: "300ms",
		borderRadius: 8,
		borderWidth: 1,
		width: "100%",
		flex: 1,
	},
	selected: {
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		// elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
	label: {
		fontFamily: commonFonts.medium,
		fontSize: 16,
		...(isWeb ? { transitionDuration: "0.3s" } : {}),
	},
	buttonStyleHover: {
		...(isWeb ? ({ filter: "brightness(90%)" } as ViewStyle) : {}),
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		// elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
});
