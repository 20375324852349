import {Modal, ModalProps} from "react-native-paper";
import {Platform, StyleSheet, Text, TextStyle, View} from "react-native";
import {commonFonts} from "../../styles/commonStyles";
import {CustomButton} from "../CustomButton";
import {useCustomTheme} from "../../Theme";
import React from "react";

interface ConfirmModalProps extends Omit<ModalProps, "children"> {
  message: string,
  subMessage?: string,
  cancelButton?: {
    label: string,
    onPress: () => void
  },
  confirmButton?: {
    label: string,
    onPress: () => void
  }
}

export default function ConfirmModal({ ...rest }: ConfirmModalProps) {
  const { message, subMessage, confirmButton, cancelButton } = rest;

  const theme = useCustomTheme();

  return (
    <Modal contentContainerStyle={styles.container} {...rest}>
      <View style={[styles.content]}>
        <Text style={{ fontFamily: commonFonts.medium, fontSize: 20 }}>
          { message }
        </Text>

        {
          subMessage && (
            <Text style={{ fontFamily: commonFonts.regular, color: theme.colors.gray, fontSize: 16 }}>
              { subMessage }
            </Text>
          )
        }
      </View>

      <View style={[styles.footer]}>
        {
          cancelButton && (
            <CustomButton
              style={[
                styles.button,
                { backgroundColor: "transparent" }
              ]}
              label={cancelButton.label}
              labelStyle={[
                {
                  textDecorationLine: "underline",
                },
                Platform.OS === "web" ? {
                  textUnderlineOffset: 2
                } as TextStyle : {}
              ]}
              onPress={cancelButton.onPress}
              type={"ghost"}
            />
          )
        }

        {
          confirmButton && (
            <CustomButton
              style={[
                styles.button
              ]}
              label={confirmButton.label}
              onPress={confirmButton.onPress}
            />
          )
        }
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    padding: 16,
    margin: 16,
    borderRadius: 8,
    gap: 32
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 8
  },
  button: {
    paddingHorizontal: 16,
    borderRadius: 8,
    minWidth: 96
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8
  },
})
