import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
	container: {
		fontFamily: "Inter_400Regular",
		gap: 8,
		padding: 16,
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		flexDirection: "row",
		gap: 8,
	},
	icon: {
		justifyContent: "center",
		alignContent: "center",
	},
	row: {
		flexDirection: "row",
		gap: 8,
	},
	key: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		fontSize: 28,
		paddingTop: 14,
		paddingBottom: 14,
		backgroundColor: "#F8F8F8",
		color: "#1C1B1F",
	},
	noKey: {
		flex: 1,
		paddingTop: 10,
		paddingBottom: 10,
	},
	title: {
		fontFamily: "Inter_500Medium",
		fontSize: 16,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		fontSize: 14,
	},
	button: {
		fontFamily: "Inter_400Regular",
		fontSize: 14,
	},

	changeMethodContainer: {
		fontWeight: "400",
		gap: 8,
		padding: 16,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	changeMethodContent: {
		flexDirection: "row",
		gap: 8,
		alignItems: "center",
		width: "100%",
		flex: 1,
		justifyContent: "center",
	},
	changeMethodContentText: {
		width: "auto",
		flex: 1,
		height: "100%",
	},
	changeMethodIcon: {
		height: 48,
		width: 48,
		justifyContent: "center",
		alignItems: "center",
	},
	changeMethodTitle: {
		fontWeight: "500",
		fontSize: 16,
	},
	changeMethodSubtitle: {
		fontWeight: "400",
		lineHeight: 18,
		fontSize: 14,
	},
	changeMethodButton: {
		fontWeight: "500",
		fontSize: 14,
	},
});

export default styles;
