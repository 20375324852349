import { Image, ImageProps, ImageStyle, StyleProp, View } from "react-native";
import { TCurrencySymbol } from "../../utils/currency";
import React, { useState } from "react";
import { INetwork } from "../../services/CurrencyService";

interface CurrencyLogoProps extends Partial<ImageProps> {
	currency: TCurrencySymbol;
	network?: INetwork;
	contractType?: string,
	networkStyle?: StyleProp<ImageStyle>,
}

export default function Index({ network, contractType, currency, ...props }: CurrencyLogoProps) {

	const [imageCurrencySrc, setImageCurrencySrc] = useState<string>(`https://backoffice.ezzepay.io/static/images/currencies/${currency}.svg`);
	const [imageNetworkCurrencySymbolSrc, setImageNetworkCurrencySymbolSrc] = useState<string>(contractType ? `https://backoffice.ezzepay.io/static/images/currencies/${network?.currencySymbol}.svg` : '');

	return (
		
		<View style={{ position: "relative" }}>

			<Image {...props} source={{ uri: imageCurrencySrc }} onError={() => setImageCurrencySrc(`https://backoffice.ezzepay.io/static/images/currencies/UNKNOWN.svg`)} ></Image>

			{
				(contractType && network) && (
					<Image
						style={[props.networkStyle || {
							position: "absolute",
							minHeight: 16,
							width: 16,
							height: 16,
							left: 18,
							top: 18,
						}]}
						source={{ uri: imageNetworkCurrencySymbolSrc }}
						onError={() => setImageNetworkCurrencySymbolSrc(`https://backoffice.ezzepay.io/static/images/currencies/UNKNOWN.svg`)}
					/>
				)
			}
			
		</View>
	);
}
