import { Text, View, StyleSheet, TextInput as TextInputRN } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { useNavigation } from "@react-navigation/native";
import { validateEmail } from "validations-br";
import { CustomButton } from "../CustomButton";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import * as Yup from "yup";

import {
	IconButton,
	TextInput as TextInputPaper,
	TextInputProps,
} from "react-native-paper";

import React, { useCallback, useEffect, forwardRef, memo } from "react";
import { Formik } from "formik";
import AuthService from "../../services/AuthService";

const BottomSheetTextInputComponent = forwardRef<TextInputRN, TextInputProps>(
	({ onFocus, onBlur, ...rest }, ref) => {
		const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

		useEffect(() => {
			return () => {
				shouldHandleKeyboardEvents.value = false;
			};
		}, [shouldHandleKeyboardEvents]);

		const handleOnFocus = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = true;
				if (onFocus) {
					onFocus(args);
				}
			},
			[onFocus, shouldHandleKeyboardEvents]
		);
		const handleOnBlur = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = false;
				if (onBlur) {
					onBlur(args);
				}
			},
			[onBlur, shouldHandleKeyboardEvents]
		);

		return (
			<TextInputPaper
				ref={ref}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				{...rest}
			/>
		);
	}
);

const BottomSheetTextInput = memo(BottomSheetTextInputComponent);
BottomSheetTextInput.displayName = "BottomSheetTextInput";

const authService = new AuthService();

export default function LoginBottomSheetData() {
	const { t } = useTranslation();

	const navigation = useNavigation();

	const {
		handleCloseBottomSheet,
		users,
		handleEmail,
		handlePresentModalPress,
	} = useAuth();

	const emailValidationSchema = Yup.object().shape({
		email: Yup.string()
			.required()
			.test("is-valid", "invalid", (email) => {
				return validateEmail(email);
			}),
	});

	function handleEmailSubmit(email: string) {
		// authService.login(email, "email")

		// const user = users.find((user) => user.email === email);

		handleEmail(email.toLowerCase());

		// navigation.navigate("codeScreen" as never);
		navigation.navigate("userInfoRegisterScreen" as never);

		// if (user) {
		// 	navigation.navigate("codeScreen" as never);
		// } else {
		// 	navigation.navigate("userInfoRegisterScreen" as never);
		// }
	}

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={{ paddingHorizontal: 16 }}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 12,
							alignItems: "center",
						}}
					>
						<Text style={styles.title}>{t("pos.steps.login.title")}</Text>
						<IconButton
							icon="close-circle"
							iconColor={"#000"}
							size={22}
							onPress={handleCloseBottomSheet}
							style={{ margin: 0 }}
						/>
					</View>
					<Text style={styles.subTitle}>{t("pos.steps.login.content")}</Text>
				</View>

				<View
					style={{
						justifyContent: "space-between",
						flexDirection: "column",
						flex: 1,
					}}
				>
					<Formik
						initialValues={{
							email: "",
						}}
						validateOnMount={true}
						validationSchema={emailValidationSchema}
						onSubmit={(values: { email: string }) =>
							handleEmailSubmit(values.email)
						}
					>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							values,
							errors,
							touched,
							resetForm,
						}) => (
							<View
								style={{
									justifyContent: "space-between",
									flexDirection: "column",
									flex: 1,
									overflow: "hidden",
								}}
							>
								<BottomSheetTextInput
									style={styles.input}
									mode="outlined"
									value={values.email}
									label={
										<Text
											style={{
												backgroundColor: "#FFF",
												paddingHorizontal: 4,
											}}
										>{`E-mail`}</Text>
									}
									textContentType={"emailAddress"}
									onChangeText={handleChange("email")}
									onSubmitEditing={() => handleSubmit()}
								/>
								<CustomButton
									label={t("pos.continue")}
									style={{ marginTop: 16 }}
									disabled={errors.email ? true : false}
									rightAddon={
										<MaterialCommunityIcons
											name="arrow-right"
											size={24}
											color={"#fff"}
										/>
									}
									onPress={() => handleSubmit()}
								/>
							</View>
						)}
					</Formik>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	input: {
		fontFamily: "Inter_400Regular",
		marginHorizontal: 16,
		fontSize: 16,
		marginTop: 32,
		color: "#79747E",
	},
});
