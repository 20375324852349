import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
	container: {
		fontFamily: "Inter_400Regular",
		gap: 8,
		paddingLeft: 8,
		paddingRight: 8,
	},
	row: {
		flexDirection: "row",
		gap: 8,
	},
	key: {
		flex: 1,
		height: 60
	},
	keyContent: {
		fontSize: 24,
	},
	noKey: {
		flex: 1,
	},
});

export default styles;
