import { View } from "react-native";

import React, { ReactNode } from "react";
import { useCustomTheme } from "../../../../src/Theme";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import styles from "./styles";
import {CustomButton} from "../../../../src/components/CustomButton";

type KeyboardProps = {
	onKeyPress: (amount: string) => void;
	onDeletePress: () => void;
};

type KeyProps = {
	label?: string;
	icon?: (isHover: boolean) => ReactNode;
	handlePress: () => void;
};

function Key({ label, icon, handlePress }: KeyProps) {
	return (
		<CustomButton
			type={"ghost"}
			label={label}
			rightAddon={icon}
			onPress={handlePress}
			style={styles.key}
			labelStyle={styles.keyContent}
		/>
	);
}

export default function Keyboard({ onKeyPress, onDeletePress }: KeyboardProps) {

	const theme = useCustomTheme();

	const handleKeyPress = (value: string) => {
		onKeyPress(value);
	};

	return (


		<View style={styles.container}>
			<View style={styles.row}>
				<Key label={"1"} handlePress={() => handleKeyPress("1")} />
				<Key label={"2"} handlePress={() => handleKeyPress("2")} />
				<Key label={"3"} handlePress={() => handleKeyPress("3")} />
			</View>
			<View style={styles.row}>
				<Key label={"4"} handlePress={() => handleKeyPress("4")} />
				<Key label={"5"} handlePress={() => handleKeyPress("5")} />
				<Key label={"6"} handlePress={() => handleKeyPress("6")} />
			</View>
			<View style={styles.row}>
				<Key label={"7"} handlePress={() => handleKeyPress("7")} />
				<Key label={"8"} handlePress={() => handleKeyPress("8")} />
				<Key label={"9"} handlePress={() => handleKeyPress("9")} />
			</View>
			<View style={styles.row}>
				<View style={styles.noKey} />
				<Key label={"0"} handlePress={() => handleKeyPress("0")} />
				<Key
					icon={(isHover) => <MaterialCommunityIcons
						selectable={false}
						name="backspace-outline"
						color={isHover ? theme.colors.secondary : "#79747E"}
						size={24}
					/>}
					handlePress={() => onDeletePress()}
				/>
			</View>
		</View>
	);
}
