import { Text, View, StyleSheet, TextInput as TextInputRN } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { IconButton, TextInputProps } from "react-native-paper";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";
import { useAuth } from "../../hooks/useAuth";
import { useCustomTheme } from "../../Theme";

import {
	CodeField,
	Cursor,
	useBlurOnFulfill,
	useClearByFocusCell,
} from "react-native-confirmation-code-field";

import React, {
	createContext,
	useCallback,
	forwardRef,
	useContext,
	useEffect,
	useState,
	useRef,
	memo,
} from "react";

const CELL_COUNT = 6;

const BottomSheetTextInputComponent = forwardRef<TextInputRN, TextInputProps>(
	({ onFocus, onBlur, ...rest }, ref) => {
		const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

		const { codeResent } = useContext(CodeContext);

		const [value, setValue] = useState("");

		const theme = useCustomTheme();

		const refCodeField = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
		const [props, getCellOnLayoutHandler] = useClearByFocusCell({
			value,
			setValue,
		});

		useEffect(() => {
			return () => {
				shouldHandleKeyboardEvents.value = false;
			};
		}, [shouldHandleKeyboardEvents]);

		useEffect(() => {
			if (codeResent) {
				setValue("");
			}
		});

		const handleOnFocus = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = true;
				if (onFocus) {
					onFocus(args);
				}
			},
			[onFocus, shouldHandleKeyboardEvents]
		);
		const handleOnBlur = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = false;
				if (onBlur) {
					onBlur(args);
				}
			},
			[onBlur, shouldHandleKeyboardEvents]
		);

		return (
			<CodeField
				ref={refCodeField}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				autoFocus={true}
				{...rest}
				{...props}
				value={value}
				onChangeText={setValue}
				cellCount={CELL_COUNT}
				rootStyle={styles.codeFieldRoot}
				keyboardType="number-pad"
				textContentType="oneTimeCode"
				renderCell={({ index, symbol, isFocused }) => (
					<Text
						key={index}
						style={[
							styles.cell,
							isFocused
								? !codeResent
									? { borderWidth: 1, borderColor: theme.colors.secondary }
									: {}
								: {},
							,
						]}
						onLayout={getCellOnLayoutHandler(index)}
					>
						{symbol || (isFocused ? !codeResent ? <Cursor /> : null : null)}
					</Text>
				)}
			/>
		);
	}
);

const BottomSheetTextInput = memo(BottomSheetTextInputComponent);
BottomSheetTextInput.displayName = "BottomSheetTextInput";

const resendTimeLimit = 60;

interface ResendTimerProps {
	handleResentCode: () => void;
	resendTimer: number;
	codeResent: boolean;
}

const ResendTimer = ({ handleResentCode, resendTimer, codeResent }) => {
	const { t } = useTranslation();

	const theme = useCustomTheme();

	return (
		<>
			{resendTimer === 0 ? (
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CustomButton
						label={t("pos.steps.login-code.resend-button")}
						type={"outlined"}
						style={{
							height: 32,
							paddingHorizontal: 32,
							borderRadius: 8,
							marginTop: 32,
						}}
						labelStyle={{
							fontSize: 14,
						}}
						onPress={handleResentCode}
					/>
				</View>
			) : codeResent ? (
				<View style={styles.resend}>
					<ActivityIndicator size={24} color={theme.colors.secondary} />
				</View>
			) : (
				<Text style={styles.resend}>
					{
						t("pos.steps.login-code.resend", {
							seconds: resendTimer,
						}).split(":")[0]
					}
					:
					{
						<Text style={styles.resendSeconds}>
							{
								t("pos.steps.login-code.resend", {
									seconds: resendTimer,
								}).split(":")[1]
							}
						</Text>
					}
				</Text>
			)}
		</>
	);
};

interface CodeContextProps {
	code: string;
	codeResent: boolean;
	handleResentCode: () => void;
}

type TimerIntervalProps = number | undefined;

export const CodeContext = createContext<CodeContextProps>(
	{} as CodeContextProps
);

export default function LoginBottomSheetCode() {
	const { t } = useTranslation();

	const theme = useCustomTheme();

	const { handleCloseBottomSheet, handleLogin, validToken } = useAuth();
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState("");

	const navigation = useNavigation();

	const [value, setValue] = useState("");

	const handleSubmit = async () => {
		console.log("executed");
		await handleLogin(code);
	};

	useEffect(() => {
		if (code.length === CELL_COUNT) {
			handleSubmit();
		}
	}, [code]);

	const [resendTimer, setResendTimer] = useState(resendTimeLimit);

	const [codeResent, setCodeResent] = useState(false);

	const timerInterval = useRef<TimerIntervalProps>(undefined);

	const startResendLimitTimer = (_time?: number) => {
		if (!!timerInterval.current) {
			clearInterval(timerInterval.current);
		}

		//@ts-ignore
		timerInterval.current = setInterval(() => {
			setResendTimer((prevTime) => {
				const time = _time !== undefined ? _time : prevTime;

				if (time <= 1) {
					clearInterval(timerInterval.current);
					return 0;
				}

				return time - 1;
			});
		}, 1000);
	};

	function handleResentCode() {
		setResendTimer(resendTimeLimit);
		setCodeResent(true);
		setLoading(true);
		setCode("");

		setTimeout(() => {
			startResendLimitTimer();
			setCodeResent(false);
			setLoading(false);
		}, 5000);
	}

	useEffect(() => {
		startResendLimitTimer();
	}, []);

	const handleBack = () => {
		navigation.navigate("dataScreen" as never);
	};

	return (
		<CodeContext.Provider value={{ code, handleResentCode, codeResent }}>
			<View style={styles.container}>
				<View style={styles.content}>
					<View>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								marginBottom: 12,
								alignItems: "center",
							}}
						>
							<View
								style={{ flexDirection: "row", alignItems: "center", gap: 8 }}
							>
								<IconButton
									icon="arrow-left"
									iconColor={"#000"}
									size={22}
									onPress={handleBack}
									style={{ margin: 0 }}
								/>
								<Text style={styles.title}>
									{t("pos.steps.login-code.title")}
								</Text>
							</View>
							<IconButton
								icon="close-circle"
								iconColor={"#000"}
								size={22}
								onPress={handleCloseBottomSheet}
								style={{ margin: 0 }}
							/>
						</View>
						<Text style={styles.subTitle}>
							{t("pos.steps.login-code.content")}
						</Text>

						<View>
							<View>
								<BottomSheetTextInput
									disabled={loading}
									value={code}
									onChange={(event) => setCode(event.nativeEvent.text)}
								/>
							</View>
							<Text
								style={{
									fontFamily: "Inter_400Regular",
									textAlign: "center",
									justifyContent: "center",
									alignItems: "center",
									alignContent: "center",
									height: 32,
									lineHeight: 32,
									fontSize: 14,
									color: theme.colors.secondary,
								}}
							>
								{validToken === false && code.length === CELL_COUNT
									? "Invalid Code"
									: ""}
							</Text>
							<ResendTimer
								handleResentCode={handleResentCode}
								resendTimer={resendTimer}
								codeResent={codeResent}
							/>
						</View>
					</View>
				</View>
				{/* <View>
					<CustomButton
						label={!validToken ? t("pos.continue") : ""}
						style={{ marginTop: 16 }}
						disabled={loading || code.length !== CELL_COUNT || !validToken}
						rightAddon={
							<>
								{validToken ? (
									<ActivityIndicator
										size={16}
										color={theme.colors.background}
									/>
								) : (
									<MaterialCommunityIcons
										name="arrow-right"
										size={24}
										color={"#fff"}
									/>
								)}
							</>
						}
						onPress={handleSubmit}
					/>
				</View> */}
			</View>
		</CodeContext.Provider>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		marginHorizontal: 16,
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		marginVertical: 16,
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		fontSize: 16,
	},
	input: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		marginTop: 32,
		fontSize: 16,
	},
	code: {
		justifyContent: "center",
		flexDirection: "row",
		marginTop: 32,
		width: "100%",
		gap: 8,
	},
	resend: {
		fontFamily: "Inter_400Regular",
		justifyContent: "center",
		textAlign: "center",
		marginTop: 32,
		fontSize: 16,
	},
	resendSeconds: {
		fontFamily: "Inter_500Medium",
	},
	root: {
		flex: 1,
		padding: 20,
	},
	codeFieldRoot: {
		marginTop: 20,
		gap: 8,
	},
	cell: {
		fontFamily: "Inter_400Regular",
		backgroundColor: "#D9D9D9",
		borderColor: "transparent",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		borderRadius: 8,
		borderWidth: 1,
		lineHeight: 48,
		fontSize: 32,
		height: 48,
		margin: 4,
		width: 48,
		flex: 1,
	},
});
