import { StyleSheet } from "react-native";

import Constants from "expo-constants";

const statusBarHeight = Constants.statusBarHeight;

const styles = StyleSheet.create({
	container: {
		height: "100%",
		width: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	amountContainer: {
		alignItems: "center",
	},
	amountContent: {
		fontFamily: "Inter_500Medium",
		flexDirection: "row",
		display: "flex",
		fontSize: 48,
		gap: 4,
	},
	amount: {
		fontFamily: "Inter_500Medium",
		lineHeight: 48,
		fontSize: 48,
	},
	symbolAndDecimals: {
		fontFamily: "Inter_500Medium",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "row",
		display: "flex",
		lineHeight: 24,
		fontSize: 24,
	},
	maxAmountWarning: {
		fontFamily: "Inter_500Medium",
		color: "#79747E",
		fontSize: 14,
		marginTop: 4,
	},
	containerHeader: {
		flexDirection: "column",
		width: "100%",
		height: statusBarHeight + 72,
		alignItems: "center",
		justifyContent: "center",
	},
	logoView: {
		height: 72,
		width: 160,
	},
	logoImage: {
		height: "100%",
		width: "100%",
		resizeMode: "contain",
	},
	backButtonView: {
		position: "absolute",
		left: 12,
	},
});

export default styles;
