import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { Text, View, StyleSheet } from "react-native";
import { IconButton } from "react-native-paper";
import { CustomButton } from "../CustomButton";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../Theme";
import React, { useContext } from "react";
import { useAuth } from "../../hooks/useAuth";

export default function RegistrationCompleted() {
	const { t } = useTranslation();

	const theme = useCustomTheme();

	const { handleCloseBottomSheet } = useAuth();

	function handleContinue() {
		setTimeout(() => {
			handleCloseBottomSheet();
		}, 500);
	}

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View
					style={{
						alignItems: "flex-end",
						position: "absolute",
						zIndex: 1,
						right: 16,
					}}
				>
					<IconButton
						icon="close-circle"
						iconColor={"#000"}
						size={22}
						onPress={handleCloseBottomSheet}
					/>
				</View>

				<View
					style={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						gap: 8,
					}}
				>
					<View
						style={{
							width: 144,
							height: 144,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: 112,
							backgroundColor: theme.colors.successLight,
						}}
					>
						<MaterialCommunityIcons
							name={"check"}
							size={96}
							color={"#FFF"}
							style={{
								backgroundColor: theme.colors.success,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								alignSelf: "center",
								textAlign: "center",
								width: 112,
								height: 112,
								lineHeight: 112,
								borderRadius: 112,
							}}
						/>
					</View>

					<View
						style={{
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Text
							style={{
								fontSize: 24,
								textAlign: "center",
								color: theme.colors.success,
							}}
						>
							Sucesso
						</Text>
						<Text
							style={{
								textAlign: "center",
								fontSize: 16,
								lineHeight: 24,
								color: theme.colors.outline,
							}}
						>
							Sua conta foi aprovada!
						</Text>
					</View>
				</View>

				<CustomButton
					label={t("pos.continue")}
					style={{}}
					rightAddon={
						<MaterialCommunityIcons
							name="arrow-right"
							size={24}
							color={"#fff"}
						/>
					}
					onPress={handleContinue}
				/>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	input: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
});
