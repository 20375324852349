import PaymentMethod from "../../../pages/Payment/components/PaymentMethod";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import {
	Text,
	View,
	StyleSheet,
	FlatList,
	Image,
	TextStyle,
	Platform,
} from "react-native";
import { IconButton } from "react-native-paper";
import { t } from "i18next";
import React from "react";

import { CardButton } from "../CardButton";
import { usePayment } from "../../hooks/usePayment";
import { CustomButton } from "../CustomButton";

export default function CancelPaymentBottomSheet({ handleClose, handleConfirm, handleCancel }) {
	const navigation = useCustomNavigation();

	const { paymentMethod, handlePaymentMethod } = usePayment();

	function handleSelectCard(item) {
		navigation.navigate("amount-choice");
		handleClose();

		handlePaymentMethod({
			description: item.description,
			icon: item.type,
			type: item.type,
		});
	}

	const paymentMethods = [
		{
			type: "pix",
			title: "Pix Payment",
			soon: false,
			description: "Pix (BRL)",
		},
		{
			type: "crypto",
			title: "Crypto Payment",
			soon: false,
			description: "Crypto (Bitcoin, ETH, USDT and more 103)",
		},
		{
			type: "taptopay",
			title: "Tap to Pay Payment",
			soon: true,
			description: "Tap to Pay iPhone",
		},
	];

	return (
		<View style={styles.content}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: 12,
					alignItems: "center",
				}}
			>
				<View>
					<Text style={styles.title}>
						{t(`pos.steps.send-payment.cancel-modal.message`)}
					</Text>
					<Text style={styles.subTitle}>
						{t(`pos.steps.send-payment.cancel-modal.subMessage`)}
					</Text>
				</View>

				<IconButton
					icon="close-circle"
					iconColor={"#000"}
					size={22}
					onPress={handleClose}
					style={{ margin: 0 }}
				/>
			</View>
			<View
				style={{
					marginBottom: 16,
					flex: 1,
					display: "flex",
					flexDirection: "row",
					gap: 16,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CustomButton
					style={[styles.button, { backgroundColor: "transparent" }]}
					label={"No"}
					labelStyle={[
						{
							textDecorationLine: "underline",
						},
						Platform.OS === "web"
							? ({
									textUnderlineOffset: 2,
							  } as TextStyle)
							: {},
					]}
					onPress={handleClose}
					type={"ghost"}
				/>
				{
					<CustomButton
						style={[styles.button]}
						label={"Yes"}
						onPress={handleConfirm}
					/>
				}
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		marginHorizontal: 16,
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 18,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		fontSize: 16,
	},
	input: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		marginTop: 32,
		fontSize: 16,
	},
	button: {
		paddingHorizontal: 16,
		borderRadius: 8,
		minWidth: 96,
	},
});
