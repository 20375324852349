import PaymentMethod from "../../../pages/Payment/components/PaymentMethod";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import { Text, View, StyleSheet, Image } from "react-native";
import { IconButton } from "react-native-paper";
import { t } from "i18next";
import React from "react";

import { CardButton } from "../CardButton";
import { usePayment } from "../../hooks/usePayment";
import { PaymentMethodProps, usePos } from "../../providers/pos.provider";
import { Card } from "../Card";
import { GestureDetector } from "react-native-gesture-handler";
import {
	ScrollView,
	FlatList
	} from 'react-native-gesture-handler';

export default function PaymentMethodBottomSheet({ handleClose }) {
	const navigation = useCustomNavigation();

	const { paymentMethods } = usePos();

	const { paymentMethod, handlePaymentMethod } = usePayment();

	const Icon = paymentMethod.icon;

	function handleSelectCard(method: PaymentMethodProps) {
		navigation.navigate("amount-choice");
		handleClose();

		const { type, title, description, available, icon } = method;

		handlePaymentMethod({
			type: type,
			title: title,
			description: description,
			icon: icon,
			available: available,
		});
	}

	return (
		<View style={styles.content}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: 12,
					alignItems: "center",
				}}
			>
				<Text style={styles.title}>
					{t("pos.steps.amount-choice.payment-methods.title")}
				</Text>
				<IconButton
					icon="close-circle"
					iconColor={"#000"}
					size={22}
					onPress={handleClose}
					style={{ margin: 0 }}
				/>
			</View>

			<View style={{ flex: 1 }}>
				<FlatList
					data={paymentMethods}
					horizontal={true}
					scrollEnabled={true}
					style={{ paddingBottom: 16, marginBottom: 8, flex: 1, zIndex: 1000 }}
					keyExtractor={(item) => item.type}
					renderItem={({ item }) => (
						<View
							style={{
								height: 160,
								width: 160,
							}}
						>
							<Card onPress={() => handleSelectCard(item)}>
								<View
									style={{
										gap: 8,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<item.icon
										style={{
											height: 80,
											width: 80,
										}}
									/>
									<Text
										style={{
											fontSize: 14,
											textAlign: "center",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{item.title}
									</Text>
								</View>
							</Card>
						</View>
					)}
				/>
			</View>
			{/* <FlatList
						data={paymentMethods}
						style={styles.list}
						renderItem={({ item }) => (
							<PaymentMethod
							type={item.type}
							title={item.title}
							description={item.description}
							icon={item.icon}
								available={item.available}
							/>
						)}
						keyExtractor={(item) => item.type}
						numColumns={2}
					/> */}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		marginHorizontal: 16,
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		fontSize: 16,
	},
	input: {
		fontFamily: "Inter_400Regular",
		color: "#79747E",
		marginTop: 32,
		fontSize: 16,
	},
});
