import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useResponsive } from "../../../../src/hooks/useResponsive";
import { CardButton } from "../../../../src/components/CardButton";
import BTC from "../../../../src/assets/icons/networks/BTC";
import TRX from "../../../../src/assets/icons/networks/TRX";
import ETH from "../../../../src/assets/icons/networks/ETH";
import { useCustomTheme } from "../../../../src/Theme";
import { View, Text, ImageProps } from "react-native";
import { useTranslation } from "react-i18next";
import React from "react";
import styles from "./styles";
import {
	TCurrencySymbol,
	TNetworkSymbol,
} from "../../../../src/utils/currency";

// const logos = {
// 	[TCurrencySymbol.BTC]: require("../../../../assets/currencies/BTC.png"),
// 	[TCurrencySymbol.ETH]: require("../../../../assets/currencies/ETH.png"),
// 	[TCurrencySymbol.USDT]: require("../../../../assets/currencies/USDT.png"),
// };

const networks = {
	[TNetworkSymbol.Bitcoin]: BTC,
	[TNetworkSymbol.Tron]: TRX,
	[TNetworkSymbol.Ethereum]: ETH,
};

interface CurrencyLogoProps extends Partial<ImageProps> {
	name: string;
	icon: string;
	isSelected: boolean;
	onSelect: () => void;
}

export default function ProfileCard({
	name,
	icon,
	isSelected,
	onSelect,
}: CurrencyLogoProps) {
	const { responsiveMode } = useResponsive();
	const theme = useCustomTheme();
	const { t } = useTranslation();

	const isMobile = responsiveMode === "mobile";

	return (
		<View style={styles.container}>
			<CardButton
				style={({ hovered }: any) => [
					styles.button,
					isSelected ? styles.selected : null,
					hovered ? styles.selected : null,
				]}
				onPress={onSelect}
				isSelect={isSelected}
				content={
					<>
						<View style={[styles.icon]}>
							<MaterialCommunityIcons
								name={icon}
								iconColor={"black"}
								size={52}
							/>
						</View>

						<View>
							<Text style={styles.title}>
								{t(`pos.steps.profile.cards.${name}`)}
							</Text>
						</View>
					</>
				}
			/>
		</View>
	);
}
