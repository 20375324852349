import { MD3LightTheme } from "react-native-paper";
import { ThemeProp } from "react-native-paper/lib/typescript/src/types";
import favicon from "../../../assets/favicon/ezzepay.png";
import { TenantAppDomainEnum, TenantConfig } from "./types";
import { customTheme } from "../../Theme";

import background from "../../../assets/backgrounds/ezzepay.jpg";
import headerLogo from "../../../assets/header-icons/ezzepay.png";
import splashLogo from "../../../assets/splash/ezzepay.png";

const pinbankColor = {
  ...MD3LightTheme.colors,
  ...customTheme.colors,
  primary: "#7b1fa2",
  primaryHover: "rgba(123,31,162,0.3)",
  secondary: "#1356E2",
  secondaryHover: "#bb1046",
  secondaryLight: "#4358d8",
  header: "#1356E2",
}

const _theme: ThemeProp = {
  ...MD3LightTheme,
  custom: "property",
  colors: pinbankColor,
} as ThemeProp;

export const pinbankTenant: TenantConfig = {
  name: TenantAppDomainEnum.PINBANK,
  appTitle: "PIN2pay",
  documentTitle: "..:: PIN2pay - Payment",
  theme: _theme,
  favicon: favicon,
  email: "suporte@pinpay.com.br",
  website: "https://pinpay.com.br/",
  headerLogo: headerLogo,
  splashLogo: splashLogo,
  background: {
    color: "#FFF",
    image: background
  }
}
