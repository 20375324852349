import { AxiosResponse } from "axios";
import API, { corePrefix, defaultPrefix } from "./api";
import { TCurrencySymbol } from "../utils/currency";
import { isYesOrNoEnum } from "../utils/types";
import { ICurrencyTicker } from "./CurrencyService";
import CurrencyUtils from "../utils/CurrencyUtils";

// export enum RequestStatusId {
//   PENDING = 1,
//   COMPLETED = 2,
//   CANCELLED = 3,
//   EMAIL_CONFIRMATION = 4,
//   AWAITING_DEPOSIT = 5,
//   SCHEDULED = 6,
//   VERIFICATION_PENDING = 7,
//   ERROR = 8,
//   RUNNING = 9,
//   EXPIRED = 10,
//   AWAITING_WITHDRAWAL = 11,
//   PAID = 12,
// }

export enum RequestStatusId {
  PENDING = "PENDING",
  COMPLETED = 2,
  CANCELLED = "CANCELLED",
  EMAIL_CONFIRMATION = 4,
  AWAITING_DEPOSIT = "AWAITING_DEPOSIT",
  SCHEDULED = 6,
  VERIFICATION_PENDING = 7,
  ERROR = 8,
  RUNNING = 9,
  EXPIRED = "EXPIRED",
  AWAITING_WITHDRAWAL = 11,
  PAID = "PAID",
}

interface IInvoiceField {
  name: string,
  value?: string,
  isReadonly: isYesOrNoEnum,
  isRequired: isYesOrNoEnum,
  isVisible: isYesOrNoEnum,
}

export interface IInvoiceNetwork {
  enabled: isYesOrNoEnum,
  address: string,
  amount: string,
  minConfirmations?: number,
  contractAddress?: string,
  contractType?: string,
  decimals: number,
  network: {
    nameId: string,
    chainId?: number,
    name: string,
    currencySymbol?: TCurrencySymbol,
  },
}

export interface IInvoicePaymentMethod {
  name: string,
  currency: TCurrencySymbol,
  networks: IInvoiceNetwork[]
}

interface IInvoicePaymentTransaction {
  id: string,
  isPaid: isYesOrNoEnum,
  paidAt: string,
  createdAt: string,
  updatedAt: string,
  autoConvert: {
    enabled: isYesOrNoEnum,
    status: RequestStatusId,
    netAmountOut: string,
    netCurrencyOut: TCurrencySymbol
  },
  address: string,
  receivedAmount: string,
  pendingAmount: string,
  currency: TCurrencySymbol,
  block: {
    height: number,
    hash: string,
    confirmations: number,
    minConfirmations: number
  },
  fees: {
    "service": {
      "amount": string,
      "currency": TCurrencySymbol
    },
    "networkIn": {
      "amount": string,
      "currency": TCurrencySymbol
    }
  }
  network: string,
  transactionId: string,
  transactionIdExplorerUrl: string
}

export interface IInvoicePayment {
  status: RequestStatusId,
  receivedAmount: string,
  pendingAmount: string,
  currency: TCurrencySymbol,
  autoConvert: {
    enabled: isYesOrNoEnum,
    status: RequestStatusId,
    netAmountOut: string,
    netCurrencyOut: TCurrencySymbol
  },
  transactions: IInvoicePaymentTransaction[]
}

export type IInvoiceAllowedPaymentMethods = ['*'] | IInvoiceAllowedPaymentMethodItemByCurrencyAndNetworks[] | TCurrencySymbol[];
// ex.:
//     (1) => All Cryptocurrencies Available
//              ['*']
//     (2) => Allowed Currencies and their respective available networks
//              ['BTC', 'UST']
//     (3) => Allowed Currencies and their respective allowed network
//              [{ currency: 'BTC', networks: ['btc-mainnet'] }, { currency: 'USDT', networks: ['trx-mainnet'] }]
// PS.: [{ currency: 'BTC', networks: ['*'] }]  means the same that ['BTC']

export interface IInvoiceAllowedPaymentMethodItemByCurrencyAndNetworks {
  currency: TCurrencySymbol,  // ex.: BTC, USDT
  networks: ['*'] | string[]  // ex.: ['*] or ['btc-mainnet'] or ['eth-mainnet', 'trx-mainnet']
}

export interface IInvoice {
  id: string,
  code: string,
  createdAt: string,
  updatedAt: string,
  expiresAt: string,
  status: RequestStatusId,
  isPaid: isYesOrNoEnum,
  paidAt?: Date,
  amount: string,
  currency: TCurrencySymbol,
  externalId?: string,
  memo?: string,
  fields: IInvoiceField[],
  allowedPaymentMethods?: IInvoiceAllowedPaymentMethods[],
  paymentMethods?: IInvoicePaymentMethod[]
  payments: IInvoicePayment[],
}

export interface IQuoteInvoice {
  amountIn: number,
  currencyIn: TCurrencySymbol,
  netAmountOut: number,
  currencyOut: TCurrencySymbol,
  amountInMin: number,
  amountInMax: number,
  netAmountOutMin: number,
  netAmountOutMax: number,
}

type QuoteInvoiceData = {
  netAmountOut: number,
  currencyIn: TCurrencySymbol,
  networkIn: string
}

interface GetInvoiceReceiptResponse {
  status: string,
  data: {
    invoice: IInvoice
  }
}

interface GetInvoiceResponse {
  status: string,
  data: {
    invoice: IInvoice
  }
}

export interface GetInvoiceQuoteResponse {
  status: "ok" | "error",
  message?: string,
  data: {
    fees: {},
    request: {},
    response: {},
    quote: {
      amountIn: string,
      netAmountIn: string,
      netRateAmountOut: string,
      netRateCurrencyOut: TCurrencySymbol,
      netInverseRateAmountOut: string,
      netInserveRateCurrencyOut: string,
      netAmountOut: string,
      netCurrencyOut: string,
      amountInMin: string,
      amountInMax: string,
      netAmountOutMin: string,
      netAmountOutMax: string,
      swap: [
        {
          market: {
            side: string,
            baseCurrency: TCurrencySymbol,
            quoteCurrency: TCurrencySymbol
          }
        }
      ],
      networkOut: string,
      addressOut: string,
      ticker: {
        [currency in TCurrencySymbol]?: ICurrencyTicker
      },
      fees: {
        service: {
          percentage: string,
          amount: string,
          currency: TCurrencySymbol
        },
        comissionIn: {
          total: {
            percentage: string,
            amount: string,
            currency: TCurrencySymbol
          },
          gateway: {
            percentage: string,
            amount: string,
            currency: TCurrencySymbol
          },
          referral0: {
            percentage: string,
            amount: string,
            currency: TCurrencySymbol
          }
        },
        networkIn: {
          amount: string,
          amountMin: string,
          currency: TCurrencySymbol
        },
        networkOut: {
          amount: string,
          amountMin: string,
          currency: TCurrencySymbol
        }
      }
    }
  }
}

export default class InvoiceService {
  static async getInvoice({ id }: { id: string }): Promise<AxiosResponse<GetInvoiceResponse>> {
    return API.get(`${defaultPrefix}/invoice/${id}`);
  }

  static async getReceipt({ id }: { id: string }): Promise<AxiosResponse<GetInvoiceReceiptResponse>> {
    return API.get(`${defaultPrefix}/invoice/${id}`);
  }

  static async quoteInvoice({ netAmountOut, currencyIn, networkIn }: QuoteInvoiceData): Promise<IQuoteInvoice> {
    if (netAmountOut === undefined || netAmountOut <= 0) {
      return;
    }

    const { data: { data: { quote } } }: AxiosResponse<GetInvoiceQuoteResponse> = await API.get(`${corePrefix}/requests/swap/quote?&netAmountOut=${netAmountOut}&currencyIn=${currencyIn}&currencyOut=${TCurrencySymbol.BRL}&networkIn=${networkIn}`);

    return {
      amountIn: CurrencyUtils.getNumberByValue(quote.amountIn),
      currencyIn: currencyIn,
      netAmountOut: netAmountOut,
      currencyOut: TCurrencySymbol.BRL,
      amountInMin: CurrencyUtils.getNumberByValue(quote.amountInMin),
      amountInMax: CurrencyUtils.getNumberByValue(quote.amountInMax),
      netAmountOutMin: CurrencyUtils.getNumberByValue(quote.netAmountOutMin),
      netAmountOutMax: CurrencyUtils.getNumberByValue(quote.netAmountOutMax),
    }
  }
}
