import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
	container: {
		height: "100%",
		width: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	cryptoList: {
		marginHorizontal: 24,
		marginRight: 8,
		paddingRight: 16
	},
	amountContainer: {
		alignItems: "center",
		display: "flex",
	},
	amountContent: {
		fontFamily: "Inter_500Medium",
		flexDirection: "row",
		display: "flex",
		lineHeight: 48,
		fontSize: 48,
		gap: 4,
	},
	amount: {
		fontFamily: "Inter_500Medium",
		lineHeight: 40,
		fontSize: 48,
	},
	symbolAndDecimals: {
		fontFamily: "Inter_500Medium",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "row",
		display: "flex",
		lineHeight: 24,
		fontSize: 24,
	},
	maxAmountWarning: {
		fontFamily: "Inter_500Medium",
		color: "#79747E",
		fontSize: 14,
		marginTop: 4,
	},
});

export default styles;
