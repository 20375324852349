import axios from "axios";

class PostalCodeService {
	async getAddressByPostalCode(postalCode: string) {
		const validatePostalCodeRegex = /^[0-9]{8}$/;
		postalCode = postalCode.replace(/\-/g, "");
		if (validatePostalCodeRegex.test(postalCode)) {
			return await axios
				.get(`https://viacep.com.br/ws/${postalCode}/json`)
				.then((res) => res.data);
		}
	}
}

export { PostalCodeService };
