import {NavigationContainer} from "@react-navigation/native";
import Stack from "./Stack";
import linking from "./linking";
import {useMultiTenant} from "../providers/multi-tenant.provider";
import React from "react";

export default function() {
    
    const { tenant } = useMultiTenant();

    return(
        <NavigationContainer
          linking={linking}
          documentTitle={{
              enabled: true,
              formatter: (options, route) => `${tenant.documentTitle}`
          }}
        >
            <Stack/>
        </NavigationContainer>
    )
}
