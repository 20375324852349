import PaymentMethodBottomSheet from "../../../../src/components/PaymentMethodBottomSheet/PaymentMethodBottomSheet";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import BottomSheet, { BottomSheetBackdropProps } from "@gorhom/bottom-sheet";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { CustomButton } from "../../../../src/components/CustomButton";
import { usePos } from "../../../../src/providers/pos.provider";
import PaymentMethods from "../../components/PaymentMethods";
import { View, Text, TouchableOpacity } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import Header from "../../../../src/components/Header";
import { PosTitle } from "../../components/Title";
import Keyboard from "../../components/Keyboard";
import { useTranslation } from "react-i18next";
import styles from "./styles";

import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";

import Animated, {
	Extrapolate,
	interpolate,
	useAnimatedStyle,
} from "react-native-reanimated";
import { usePayment } from "../../../../src/hooks/usePayment";

interface PaymentMethodContextProps {
	handleClosePress: () => void;
}

export const PaymentMethodContext = createContext<PaymentMethodContextProps>(
	{} as PaymentMethodContextProps
);

const CustomBackdrop = ({ animatedIndex, style }: BottomSheetBackdropProps) => {
	const { handleClosePress } = useContext(PaymentMethodContext);

	const containerAnimatedStyle = useAnimatedStyle(() => ({
		opacity: interpolate(
			animatedIndex.value,
			[0, 1],
			[0, 1],
			Extrapolate.CLAMP
		),
	}));

	const containerStyle = useMemo(
		() => [
			style,
			{
				backgroundColor: "rgba(0,0,0,0.5)",
			},
			containerAnimatedStyle,
		],
		[style, containerAnimatedStyle]
	);

	return (
		<Animated.View style={containerStyle}>
			<TouchableOpacity style={{ flex: 1 }} onPress={handleClosePress} />
		</Animated.View>
	);
};

export default function AmountChoice() {
	const navigation = useCustomNavigation();
	const { paymentState, setPaymentState, resetPayment } = usePos();
	const isFocused = useIsFocused();

	const { paymentMethod } = usePayment();

	const { t } = useTranslation();

	const [paymentMethodBottomSheetOpen, setPaymentMethodBottomSheetOpen] =
		useState(false);

	const [amount, setAmount] = useState(
		paymentState?.amountIn?.toString() || "0"
	);

	const handleKeyPress = (value: string) => {
		if (amount === "0") {
			setAmount(value);
			return;
		}

		setAmount((prevValue) => {
			const newValue = prevValue + value;

			const maxAmount = 1000000;

			if (parseFloat(newValue) / 100 <= maxAmount) {
				return newValue;
			}

			return prevValue;
		});
	};

	const handleDeletePress = () => {
		setAmount((prevValue) =>
			prevValue.length === 1 ? "0" : prevValue.slice(0, -1)
		);
	};

	const handleSubmit = () => {
		setPaymentState((prev) => ({
			...prev,
			netAmountOut: parseFloat(amount) / 100,
		}));

		navigation.customNavigate("crypto-select");
	};

	const bottomSheetRef = useRef<BottomSheet>(null);

	const snapPoints = useMemo(() => ["30%", "40%"], []);

	const handleSheetChange = useCallback((index) => {
		console.log("handleSheetChange", index);
	}, []);

	const handleClosePress = useCallback(() => {
		bottomSheetRef.current?.close();
		setTimeout(() => {
			setPaymentMethodBottomSheetOpen(false);
		}, 500);
	}, []);

	const renderBackdrop = useCallback(
		(props) => <CustomBackdrop {...props} type={3} />,
		[]
	);

	return (
		<PaymentMethodContext.Provider value={{ handleClosePress }}>
			<View style={styles.container}>
				<Header
					onBackClick={() => navigation.customNavigate("main")}
					showProfileButton={true}
				/>

				<View style={[styles.content]}>
					<PosTitle
						title={
							paymentMethod.title ? paymentMethod.title : paymentMethod.type
						}
					/>

					<View style={styles.amountContainer}>
						<View style={styles.amountContent}>
							<Text selectable={false} style={styles.symbolAndDecimals}>
								R$
							</Text>
							<Text selectable={false} style={styles.amount}>
								{amount.length > 2
									? parseFloat(amount.slice(0, -2)).toLocaleString("pt-BR")
									: "0"}
							</Text>
							<Text selectable={false} style={styles.symbolAndDecimals}>
								{amount.slice(-2).length < 2
									? `0${amount.slice(-2)}`
									: amount.slice(-2)}
							</Text>
						</View>
						<View>
							<Text selectable={false} style={styles.maxAmountWarning}>
								valor máximo: R$ 1.000.000,00
							</Text>
						</View>
					</View>

					<View>
						<PaymentMethods
							handlePress={() => setPaymentMethodBottomSheetOpen(true)}
						/>
						<Keyboard
							onKeyPress={handleKeyPress}
							onDeletePress={handleDeletePress}
						/>
						<CustomButton
							label={t("pos.continue")}
							style={{ marginTop: 16 }}
							rightAddon={
								<MaterialCommunityIcons
									name="arrow-right"
									size={24}
									color={"#fff"}
								/>
							}
							onPress={handleSubmit}
							disabled={parseFloat(amount) <= 0}
						/>
					</View>
				</View>

				{paymentMethodBottomSheetOpen && (
					<BottomSheet
						ref={bottomSheetRef}
						index={1}
						snapPoints={snapPoints}
						animateOnMount={true}
						onChange={handleSheetChange}
						keyboardBlurBehavior={"restore"}
						backdropComponent={renderBackdrop}
					>
						<PaymentMethodBottomSheet handleClose={handleClosePress} />
					</BottomSheet>
				)}
			</View>
		</PaymentMethodContext.Provider>
	);
}
