import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { universalLanguageDetect } from "@unly/universal-language-detector";

import pt from "./locales/pt.json";
import en from "./locales/en.json";
import es from "./locales/es.json";

const lang = universalLanguageDetect({
	supportedLanguages: ["pt", "en", "es"],
	fallbackLanguage: "pt",
	errorHandler: (error) => {
		console.log("Custom error handler:");
		console.error(error);
	},
});

export type AvailableLanguages = "pt" | "en" | "es";

i18n.use(initReactI18next).init({
	lng: lang,
	fallbackLng: "pt",
	compatibilityJSON: "v3",
	resources: {
		pt,
		en,
		es,
	},
	react: {
		useSuspense: false,
	},
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
