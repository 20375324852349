import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { CustomButton } from "../../../../src/components/CustomButton";
import Header from "../../../../src/components/Header";
import ProfileCard from "../../components/ProfileCard";
import { FlatList, Text, View } from "react-native";
import { PosTitle } from "../../components/Title";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { styles } from "./styles";
import LanguageView from "../../../../src/components/LanguageView";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useAuth } from "../../../../src/hooks/useAuth";
import ConfirmModal from "../../../../src/components/ConfirmModal";

export default function Profile() {
	const navigation = useCustomNavigation();
	const { t } = useTranslation();
	const { user, logout } = useAuth();

	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const currencies = [
		{
			name: "wallet",
			icon: "wallet-outline",
		},
		{
			name: "history",
			icon: "history",
		},
		{
			name: "adjust",
			icon: "cog-outline",
		},
		{
			name: "support",
			icon: "phone",
		},
	];

	const [selectedCard, setSelectedCard] = useState(null);

	const handleLogout = () => {
		navigation.customReplace("main");
		logout();
	};

	const handlePress = (name) => {
		navigation.navigate(name);
	};

	const email = user?.email;

	return (
		<>
			<View style={[styles.container]}>
				<Header onBackClick={() => navigation.goBack()} isOnProfile={true} />
				<PosTitle title={t("pos.steps.profile.title")} />

				<View style={[styles.content]}>
					<View style={styles.email}>
						<MaterialCommunityIcons
							name={"account-circle-outline"}
							size={32}
							color={"black"}
						/>
						<Text style={styles.emailText}>
							{email?.substring(0, 4)}***@{email?.split("@")[1]}
						</Text>
					</View>

					<FlatList
						data={currencies}
						renderItem={({ item }) => (
							<ProfileCard
								name={item.name}
								icon={item.icon}
								onSelect={() => handlePress(item.name)}
								isSelected={selectedCard === item.name}
							/>
						)}
						keyExtractor={(item) => item.name}
						numColumns={2}
					/>
				</View>

				<View style={[styles.footer]}>
					<View style={styles.languageView}>
						<LanguageView />
					</View>
					<CustomButton
						label={t("pos.leave")}
						onPress={() => setShowLogoutModal(true)}
					/>
				</View>
			</View>

			{showLogoutModal && (
				<ConfirmModal
					message={t(`pos.logout.message`)}
					subMessage={t(`pos.logout.subMessage`)}
					confirmButton={{
						label: t(`pos.logout.buttons.confirm`),
						onPress: handleLogout,
					}}
					cancelButton={{
						label: t(`pos.logout.buttons.cancel`),
						onPress: () => setShowLogoutModal(false),
					}}
					visible={showLogoutModal}
				/>
			)}
		</>
	);
}
