import {Platform, StyleSheet} from "react-native";
import ScreenUtils from "../../src/utils/ScreenUtils";
import styled from "styled-components/native";
import {css} from "styled-components";

const isWeb = Platform.OS === "web";
const isSmallScreen = ScreenUtils.isSmallScreen();

const styles = StyleSheet.create({
	statusPageContainer: {
		paddingTop: isSmallScreen ? 32 : 40,
		paddingHorizontal: 16,
	},
	statusPageContent: {
		gap: isSmallScreen ? 24 : 40,
	},
	info: {
		gap: 4,
	},
	currencyListView: {
		flex: 1,
		marginTop: 12,
	},
	currencyList: {
		gap: 12,
		marginVertical: 8,
		maxHeight: 200
	},
	currencyInfo: {
		flexDirection: "row",
		alignItems: "center",
		gap: 12,
	},
	currencyIcon: {
		height: 30,
		width: 30,
		resizeMode: "contain",
	},
	amountView: {
		gap: 6,
	},
	amountValue: {
		textAlign: "center",
	},
	qrCodeImageView: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
		height: isSmallScreen ? 160 : 192,
	},
	qrCodeImageCard: {
		padding: 4,
		borderRadius: 16,
		borderWidth: 1,
		borderColor: "lightgray"
	},
	addressView: {
		gap: 6,
	},
	copyAddressView: {
		flexDirection: "row",
		alignItems: "center",
	},
	copyAddressValue: {},
	progressCircleView: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		gap: 8,
		height: 24,
	},
	statusTitleView: {
		flexDirection: "column",
		alignItems: "center",
	},
	statusIconView: {
		width: 144,
		height: 144,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 112,
	},
	statusIcon: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		textAlign: "center",
		width: 112,
		height: 112,
		lineHeight: 112,
		backgroundColor: "#FFF",
		borderRadius: 112,
	},
	statusTitleText: {
		fontSize: 24,
		textAlign: "center",
	},
	statusTextView: {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	statusText: {
		textAlign: "center",
		fontSize: 16,
		lineHeight: 24,
	},
	footerView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	progressBarView: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		justifyContent: "center",
		alignItems: "center",
		height: 24,
	},
  progressTextView: {
    fontSize: 14,
  },
	inputView: {
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		paddingHorizontal: 16,
		height: 48,
		borderWidth: 1,
		borderRadius: 4,
		gap: 8,
	},
	inputRightView: {
		flexDirection: "row",
		alignItems: "center",
		gap: 8,
	},
	inputCopyButton: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		height: 20,
		width: 20,
	},
});

export const CurrencyItemPressable = styled.Pressable`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 8px 4px 8px 10px;
	border: none;
	border-radius: 10px;
	height: 58px;
	${isWeb ? css`
		transition-duration: 0.2s;
	` : css``}	
`;

export const CurrencyItemLabel = styled.Text`
	font-size: 16px;
`;

export const CurrencyItemSubLabel = styled.Text`
	font-size: 12px;
	line-height: 12px;
	color: #79747e;
`;

export const CurrencyItemAmount = styled.Text`
	position: absolute;
	right: 28px;
	margin-bottom: 2px;
	font-size: 0.65em;
	line-height: 12px;
	color: #79747e;
	white-space: nowrap;
`;

export default styles;
