import { Text, View, StyleSheet, TextInput as TextInputRN } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { IconButton, TextInput, TextInputProps } from "react-native-paper";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";
import { Form, Formik } from "formik";

import PhoneInput from "react-native-phone-input";

// import PhoneInput from "react-native-phone-input";

import React, {
	useCallback,
	useEffect,
	forwardRef,
	memo,
	useContext,
	useState,
	useRef,
} from "react";

// Interface
interface PhoneInputProps {
	value: string;
	onChange: (phone: string) => void;
	onBlur: (phone: string) => void;
	error: boolean;
	touched?: boolean;
}

// Bottom Sheet Input

const BottomSheetTextInputComponent = forwardRef<TextInputRN, TextInputProps>(
	({ onFocus, onBlur, ...rest }, ref) => {
		const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

		useEffect(() => {
			return () => {
				shouldHandleKeyboardEvents.value = false;
			};
		}, [shouldHandleKeyboardEvents]);

		const handleOnFocus = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = true;
				if (onFocus) {
					onFocus(args);
				}
			},
			[onFocus, shouldHandleKeyboardEvents]
		);
		const handleOnBlur = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = false;
				if (onBlur) {
					onBlur(args);
				}
			},
			[onBlur, shouldHandleKeyboardEvents]
		);

		return (
			<TextInput
				ref={ref}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				{...rest}
			/>
		);
	}
);

const BottomSheetTextInput = memo(BottomSheetTextInputComponent);
BottomSheetTextInput.displayName = "BottomSheetTextInput";

// Phone Validation

import { PhoneNumberUtil } from "google-libphonenumber";
import { useAuth } from "../../hooks/useAuth";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
	} catch (error) {
		return false;
	}
};

// Phone Input

// const PhoneInput: React.FC<PhoneInputProps> = ({ value, error, onChange }) => {
// 	const { inputValue, handlePhoneValueChange, country, phone } = usePhoneInput({
// 		defaultCountry: "br",
// 		value,
// 		countries: defaultCountries,
// 		onChange: (data) => {
// 			onChange(data.phone);
// 		},
// 	});

// 	const isValidPhone = isPhoneValid(value);

// 	return (
// 		<>
// 			<BottomSheetTextInput
// 				style={styles.input}
// 				mode="outlined"
// 				value={inputValue}
// 				label={
// 					<Text
// 						style={{
// 							backgroundColor: "#FFF",
// 							paddingHorizontal: 4,
// 							color: "#79747E",
// 						}}
// 					>
// 						Phone
// 					</Text>
// 				}
// 				inputMode="numeric"
// 				onChange={handlePhoneValueChange} // Correção aqui
// 				error={error ? !isValidPhone : false}
// 			/>
// 			{/* <BottomSheetTextInput
// 				style={styles.input}
// 				mode="outlined"
// 				value={inputValue}
// 				label={
// 					<Text
// 						style={{
// 							backgroundColor: "#FFF",
// 							paddingHorizontal: 4,
// 							color: "#79747E",
// 						}}
// 					>
// 						{t("pos.steps.user-info.name")}
// 					</Text>
// 				}
// 				onChange={() => handlePhoneValueChange}
// 				error={error ? !isValidPhone : false}
// 			/> */}
// 		</>
// 	);
// };

export default function UserContactRegisterBottomSheet() {
	const { t } = useTranslation();

	const navigation = useNavigation();

	const { handleCloseBottomSheet, handleUserContactSubmit } = useAuth();

	const handleUserContactFormSubmit = (values) => {
		handleUserContactSubmit(values);

		navigation.navigate("userAddressRegisterScreen" as never);
	};

	const [phoneNumber, setPhoneNumber] = useState("");
	const [isValidNumber, setIsValidNumber] = useState(false);
	const phoneInputRef = useRef(null);

	const handleOnChangePhoneNumber = (value) => {
		setPhoneNumber(value);
		setIsValidNumber(phoneInputRef.current?.isValidNumber());
	};

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={{ paddingHorizontal: 16 }}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 12,
							alignItems: "center",
						}}
					>
						<Text style={styles.title}>
							{t("pos.steps.user-contact.title")}
						</Text>
						<IconButton
							icon="close-circle"
							iconColor={"#000"}
							size={22}
							onPress={handleCloseBottomSheet}
							style={{ margin: 0 }}
						/>
					</View>
					<Text style={styles.subTitle}>
						{t("pos.steps.user-contact.content")}
					</Text>
				</View>

				<View
					style={{
						justifyContent: "space-between",
						flexDirection: "column",
						flex: 1,
					}}
				>
					<View
						style={{
							justifyContent: "space-between",
							flexDirection: "column",
							flex: 1,
						}}
					>
						<View>
							<View style={{ marginTop: 16, gap: 16, paddingHorizontal: 16 }}>
								<BottomSheetTextInput
									style={styles.input}
									mode="outlined"
									render={(props) => (
										<PhoneInput
											ref={phoneInputRef}
											onChangePhoneNumber={handleOnChangePhoneNumber}
											style={{
												flex: 1,
												paddingHorizontal: 16,
											}}
											autoFormat={true}
											onPressFlag={() => {}}
											initialCountry={"br"}
											// initialValue="55"
											textProps={{
												placeholder: "Phone",
											}}
										/>
									)}
								/>
							</View>
						</View>
						<CustomButton
							label={t("pos.continue")}
							style={{ marginTop: 16 }}
							disabled={!isValidNumber}
							rightAddon={
								<MaterialCommunityIcons
									name="arrow-right"
									size={24}
									color={"#fff"}
								/>
							}
							onPress={() => handleUserContactFormSubmit(phoneNumber)}
						/>
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	input: {
		fontFamily: "Inter_400Regular",
		marginHorizontal: 16,
		fontSize: 16,
		marginTop: 32,
		color: "#79747E",
	},
});

{
	/* <Formik
						initialValues={{
							phone: "",
						}}
						validateOnMount={true}
						onSubmit={(values) => handleUserContactFormSubmit(values)}
					>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							values,
							errors,
							touched,
							isSubmitting,
						}) => (
							<View
								style={{
									justifyContent: "space-between",
									flexDirection: "column",
									flex: 1,
								}}
							>
								<View>
									<View
										style={{ marginTop: 16, gap: 16, paddingHorizontal: 16 }}
									>
										<PhoneInput
											value={values.phone}
											onChange={handleChange("phone")}
											onBlur={handleBlur("phone")}
											error={errors.phone && touched.phone ? true : false}
											touched={touched.phone}
										/>
									</View>
								</View>
								<CustomButton
									label={t("pos.continue")}
									style={{ marginTop: 16 }}
									disabled={!isPhoneValid(values.phone)}
									rightAddon={
										<MaterialCommunityIcons
											name="arrow-right"
											size={24}
											color={"#fff"}
										/>
									}
									onPress={() => handleSubmit()}
								/>
							</View>
						)}
					</Formik> */
}
