import {ThemeProp} from "react-native-paper/lib/typescript/src/types";
import {ReactNode} from "react";
import {ImageSourcePropType} from "react-native";

export enum TenantAppDomainEnum {
  // HUBCHAIN = "hubchain.com",          // 0017 
  HUBCHAINBANK = "hubchainbank",  // 0001
  ALPINEC = "alpinec",            // 0002
  AVILABANK = "avilabank",        // 0003
  BETRADERCLUB = "betraderclub",  // 0004
  CINKINVEST = "cinkinvest",      // 0005
  ALEXIAPAY = "alexiapay",        // 0006
  EZZEPAY = "ezzepay",             // 0007
  // LIBERPAG = "liberpag.io",           // 0008
  BANKEI = "bankei",               // 0009
  PINBANK = "pinbank",            // 0010
  // DZEROPAY = "dzeropay.io",           // 0011
}

export interface TenantConfig {
  name: TenantAppDomainEnum,
  appTitle: string,
  documentTitle: string,
  theme: ThemeProp,
  favicon: ReactNode,
  headerLogo: ImageSourcePropType,
  splashLogo: ImageSourcePropType,
  website: string,
  email: string,
  background: {
    image?: ImageSourcePropType,
    color: string,
  }
}
