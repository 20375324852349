import moment from "moment-timezone";

export default class Utils {
	static maskBlockchainAddressOrTransactionId(addressOrTransactionId: string) {
		if (!addressOrTransactionId || addressOrTransactionId?.length <= 9) {
			return addressOrTransactionId;
		}

		const initial = addressOrTransactionId.substring(0, 8);
		const end = addressOrTransactionId.slice(-8);

		return initial + "..." + end;
	}

	static getDateByDateString = (value: string) => {
		return moment(value, "YYYY-MM-DD hh:mm:ss").toDate();
	};

	static getRandomInt(max: number) {
		return Math.floor(Math.random() * max);
	}

	static formatPhone(value: string | number): string {
		let formattedPhone = "";

		if (value) {
			const phone = value.toString().replace(/\D/g, "");

			if (phone.length > 12) {
				formattedPhone = phone.replace(
					/(\d{2})?(\d{2})?(\d{5})?(\d{4})/,
					"+$1 ($2) $3-$4"
				);
			} else if (phone.length > 11) {
				formattedPhone = phone.replace(
					/(\d{2})?(\d{2})?(\d{4})?(\d{4})/,
					"+$1 ($2) $3-$4"
				);
			} else if (phone.length > 10) {
				formattedPhone = phone.replace(/(\d{2})?(\d{5})?(\d{4})/, "($1) $2-$3");
			} else if (phone.length > 9) {
				formattedPhone = phone.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3");
			} else if (phone.length > 5) {
				formattedPhone = phone.replace(
					/^(\d{2})?(\d{4})?(\d{0,4})/,
					"($1) $2-$3"
				);
			} else if (phone.length > 1) {
				formattedPhone = phone.replace(/^(\d{2})?(\d{0,5})/, "($1) $2");
			} else {
				if (phone !== "") {
					formattedPhone = phone.replace(/^(\d*)/, "($1");
				}
			}
		}

		return formattedPhone;
	}
}
