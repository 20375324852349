import {RouteProp, useNavigation, useRoute} from "@react-navigation/native";
import {Linking, Pressable, StyleSheet, View} from "react-native";
import {Chip, Divider, Text} from "react-native-paper";
import {PropsStack, StackParamList} from "../../src/Navigation/Stack/props";
import {useTranslation} from "react-i18next";
import commonStyles from "../../src/styles/commonStyles";
import {useEffect, useMemo} from "react";
import LoadingView from "../../src/components/LoadingView";
import Header from "../../src/components/Header";
import {TCurrencySymbol} from "../../src/utils/currency";
import {useCustomTheme} from "../../src/Theme";
import CurrencyUtils from "../../src/utils/CurrencyUtils";

import {Skeleton} from '@rneui/themed';
import Footer from "../../src/components/Footer";

import InvoiceService, {RequestStatusId} from "../../src/services/InvoiceService";
import {useQuery} from "@tanstack/react-query";
import Utils from "../../src/utils/Utils";
import CopyToClipboardIconButton from "../../src/components/CopyToClipboardIconButton";
import React from "react";

type InfoRowType = {
    label: string,
    value: string,
    valueToCopy?: string,
    badge?: {
        backgroundColor: string,
        color: string
    },
    textColor?: string,
    textFontSize?: number,
    textFontWeight?: "bold" | "normal"
}

export default function Receipt() {
    const navigation = useNavigation<PropsStack>();
    const theme = useCustomTheme();
    const {t} = useTranslation();
    const {params} = useRoute<RouteProp<StackParamList, "Receipt">>();

    const { data: getReceiptResponse, isFetching, isError } = useQuery({
        queryKey: ["invoice/receipt", params.id],
        queryFn: () => InvoiceService.getReceipt({ id: params.id }),
        enabled: !!params.id,
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 1000 * 60 * 5, //5 minutes
        cacheTime: 0
    })

    const {invoice, currency, quote, payment, network} = useMemo(() => {
        const invoice = getReceiptResponse?.data?.data?.invoice;
        const currency = invoice?.currency;
        const payment = invoice?.payments[0];
        let quote = 0;

        const paymentMethod = invoice?.paymentMethods.find(method => !!method.networks.find(network => network.network.id === payment.transactions[0].network))
        const network = paymentMethod?.networks.find(network => network.network.id === payment.transactions[0].network)?.network?.name || "-";

        if(!!invoice) {
            quote = CurrencyUtils.getNumberByValue(invoice.amount) / CurrencyUtils.getNumberByValue(invoice.payments[0].receivedAmount);
        }

        return {
            invoice,
            currency,
            quote,
            payment,
            network
        }
    }, [getReceiptResponse]);

    const isLoading = isFetching || invoice?.status !== RequestStatusId.PAID;

    useEffect(() => {
        if((isError) || (invoice && invoice?.status !== RequestStatusId.PAID)) {
            navigation.navigate("Invoice" as any, { id: invoice?.id || params?.id || "" } as any);
        }
    }, [invoice, isError])

    const InfoRow = ({ label, value, valueToCopy, badge, textColor, textFontSize, textFontWeight }: InfoRowType) => {
        return (
            <View style={[styles.infoRow]}>
                <Text>{ label }</Text>
                {
                    (invoice && !isLoading) ? (
                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 4 }}>
                            {
                                badge ? (
                                    <Chip
                                        style={{ backgroundColor: badge.backgroundColor }}
                                        textStyle={{ color: badge.color, fontWeight: "bold", marginHorizontal: 8, marginVertical: 2 }}
                                    >
                                        { value }
                                    </Chip>
                                ) : (
                                    <Text style={{ color: textColor, fontSize: textFontSize, fontWeight: textFontWeight }} numberOfLines={1}>{ value }</Text>
                                )
                            }

                            {
                                valueToCopy && (
                                <CopyToClipboardIconButton value={valueToCopy.toString()} size={16}/>
                              )
                            }
                        </View>
                    ) : (
                        <Skeleton animation="pulse" width={128} height={badge ? 24 : 17}/>
                    )
                }
            </View>
        )
    }

    return (
      <>
          <Header/>
          <View style={commonStyles.container}>
              <View style={commonStyles.content}>
                  { !isLoading ? (
                    <Text style={{ color: theme.colors.primary, fontWeight: "bold", textAlign: "center", fontSize: 16 }}>{ t(`receipt.title`) }</Text>
                  ) : <Skeleton animation="pulse" width={128} height={16.8}/> }

                  <InfoRow
                    label={t(`receipt.status`)}
                    value={t(`receipt.status-types.${invoice?.status}`)}
                    badge={{ backgroundColor: theme.colors.successLight, color: theme.colors.success }}
                  />

                  <InfoRow
                    label={t(`receipt.order`)}
                    value={invoice?.code}
                    badge={{ backgroundColor: theme.colors.grayLight, color: theme.colors.gray }}
                  />

                  <InfoRow
                    label={t(`receipt.date`)}
                    value={invoice?.paidAt?.toString() || ""}
                  />

                  <InfoRow
                    label={t(`receipt.amount`)}
                    value={CurrencyUtils.formatCurrency(CurrencyUtils.getNumberByValue(invoice?.amount), currency || TCurrencySymbol.BRL)}
                    textFontSize={20}
                    textFontWeight={"bold"}
                    textColor={theme.colors.primary}
                  />

                  <Divider/>

                  <View style={[styles.infoRow, { alignItems: "center" }]}>
                      <Text>{ t(`receipt.amount-paid`) }</Text>
                      <View style={styles.infoValue}>
                          {
                              (invoice && !isLoading) ? (
                                <>
                                    <Text>{ CurrencyUtils.formatCurrency(CurrencyUtils.getNumberByValue(invoice.payments[0].receivedAmount), invoice.payments[0].currency, true, 6) }</Text>
                                </>
                              ) : (
                                <>
                                    <Skeleton animation="pulse" width={112} height={17} style={{ marginBottom: 2 }} />
                                </>
                              )
                          }
                      </View>
                  </View>

                  <InfoRow
                    label={t(`receipt.fee-network-in`)}
                    value={CurrencyUtils.formatCurrency(Number(payment?.transactions[0].fees.networkIn.amount), payment?.transactions[0].fees.networkIn.currency, true, 6)}
                  />

                  <InfoRow
                    label={t(`receipt.fee-service`)}
                    value={CurrencyUtils.formatCurrency(Number(payment?.transactions[0].fees.service.amount), payment?.transactions[0].fees.service.currency, true, 6)}
                  />

                  <InfoRow
                    label={t(`receipt.quote${invoice ? "" : "-loading"}`, { symbol: payment?.currency || "" })}
                    value={CurrencyUtils.formatCurrency(quote, invoice?.currency || TCurrencySymbol.BRL, true, 4)}
                  />

                  <InfoRow
                    label={t(`receipt.network`)}
                    value={network}
                  />

                  <InfoRow
                    label={t(`receipt.address`)}
                    value={Utils.maskBlockchainAddressOrTransactionId(payment?.transactions[0]?.address)}
                    valueToCopy={payment?.transactions[0]?.address}
                  />

                  <InfoRow
                    label={t(`receipt.transaction-id`)}
                    value={Utils.maskBlockchainAddressOrTransactionId(payment?.transactions[0]?.transactionId)}
                    valueToCopy={payment?.transactions[0]?.transactionId}
                  />

                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 12 }}>
                      <Pressable
                        disabled={!invoice}
                        onPress={() => Linking.openURL(payment?.transactions[0]?.transactionIdExplorerUrl)}
                      >
                          {
                              ({ hovered }) => (
                                <Text style={{ color: hovered ? theme.colors.secondary : theme.colors.primary, fontSize: 14 }}>{t(`receipt.blockchain-button`)}</Text>
                              )
                          }
                      </Pressable>
                  </View>

                  <Footer/>
              </View>
          </View>

          {
              isLoading && <LoadingView/>
          }
      </>
    );
}

const styles = StyleSheet.create({
    infoRow: {
        width: "100%",
        gap: 16,
        height: 20,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    infoValue: {
        flexDirection: "column",
        alignItems: "flex-end"
    },
    badge: {
    }
});
