import { RouteProp, useIsFocused, useNavigation, useRoute, } from "@react-navigation/native";
import { Platform, View, ViewStyle } from "react-native";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

import AwaitingPaymentStep from "./steps/AwaitingPaymentStep";
import styles from "./styles";
import AwaitingDepositStep from "./steps/AwaitingDepositStep";
import CurrencyChoiceStep from "./steps/CurrencyChoiceStep";
import PaymentPaidStep from "./steps/PaymentPaidStep";
import commonStyles from "../../src/styles/commonStyles";
import LoadingView from "../../src/components/LoadingView";
import Footer from "../../src/components/Footer";
import { PusherService } from "../../src/services/pusher";
import NotFoundView from "../../src/components/NotFoundView";
import { StackParamList } from "../../src/Navigation/Stack/props";
import { useQuery } from "@tanstack/react-query";
import InvoiceService, { IInvoiceNetwork, RequestStatusId, } from "../../src/services/InvoiceService";
import { useMultiTenant } from "../../src/providers/multi-tenant.provider";
import PaymentCancelledStep from "./steps/PaymentCancelledStep";
import PaymentExpiredStep from "./steps/PaymentExpiredStep";

import Header from "../../src/components/Header";
import { AwaitingPaymentTimer } from "./components";
import { TCurrencySymbol } from "../../src/utils/currency";
import { useResponsive } from "../../src/hooks/useResponsive";
import Utils from "../../src/utils/Utils";
import { differenceInSeconds } from "date-fns";
import { InvoiceContext } from "./context";
import { InvoiceStepsEnum } from "./types";

export interface IPageConfig {
	step: InvoiceStepsEnum;
	isLoading: boolean;
	startedTimerAt: Date;
}

export interface IInvoiceCurrencyOption {
	name: string;
	currency: TCurrencySymbol;
	symbol: TCurrencySymbol;
	network: IInvoiceNetwork;
	contractType: string,
}

export default function Invoice(props: any) {
	const navigation = useNavigation<any>();

	const isFocused = useIsFocused();
	const { tenant } = useMultiTenant();
	const { params } = useRoute<RouteProp<StackParamList, "Invoice">>();
	const { responsiveMode } = useResponsive();

	const isMobile = responsiveMode === "mobile";

	const {
		data: getInvoiceResponse,
		isFetching,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ["invoice", params?.id],
		queryFn: () => InvoiceService.getInvoice({ id: params?.id }),
		enabled: !!params?.id,
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: 0,
		cacheTime: 0,
	});

	const invoice = getInvoiceResponse?.data?.data?.invoice;

	const { paymentMethods } = useMemo(() => {
		const paymentMethods: IInvoiceCurrencyOption[] = [];

		invoice?.paymentMethods?.forEach((paymentMethod) => {
			paymentMethod.networks?.forEach((network) => {
				paymentMethods.push({
					name: paymentMethod.name,
					symbol: paymentMethod.currency,
					currency: paymentMethod.currency,
					network: network,
					contractType: network.contractType!,
				});
			});
		});

		return {
			paymentMethods: paymentMethods || [],
		};
	}, [invoice]);

	const [selectedCurrency, setSelectedCurrency] =
		useState<IInvoiceCurrencyOption>();

	const [pageConfig, setPageConfig] = useState<IPageConfig>({
		step: null,
		isLoading: false,
		startedTimerAt: new Date(),
	});

	const setStepByInvoice = () => {
		if (!!invoice) {
			if (invoice.status === RequestStatusId.CANCELLED) {
				return setPageConfig((prev) => ({
					...prev,
					step: InvoiceStepsEnum.PAYMENT_CANCELLED,
					isLoading: false,
				}));
			}

			if (invoice.status === RequestStatusId.PENDING) {
				if (!!invoice.expiresAt) {
					const expiresAt = Utils.getDateByDateString(invoice.expiresAt);

					const diff = differenceInSeconds(expiresAt, new Date());
					if (diff <= 1) {
						invoice.status = RequestStatusId.EXPIRED;
						return setPageConfig((prev) => ({
							...prev,
							step: InvoiceStepsEnum.PAYMENT_EXPIRED,
							isLoading: false,
						}));
					}
				}

				if (
					![
						InvoiceStepsEnum.CURRENCY_CHOICE,
						InvoiceStepsEnum.AWAITING_PAYMENT,
					].includes(pageConfig.step)
				) {
					return setPageConfig((prev) => ({
						...prev,
						step: InvoiceStepsEnum.CURRENCY_CHOICE,
						isLoading: false,
					}));
				}

				if (
					pageConfig.step === InvoiceStepsEnum.AWAITING_PAYMENT &&
					paymentMethods.findIndex(
						(currency) =>
							currency.currency === selectedCurrency.currency &&
							currency.network.network.nameId ===
							selectedCurrency.network.network.nameId
					) === -1
				) {
					return setPageConfig((prev) => ({
						...prev,
						step: InvoiceStepsEnum.CURRENCY_CHOICE,
						isLoading: false,
					}));
				}

				if (
					pageConfig.step === InvoiceStepsEnum.CURRENCY_CHOICE &&
					paymentMethods.length === 1
				) {
					const paymentCurrency = paymentMethods[0];

					setSelectedCurrency(paymentCurrency);

					return setPageConfig((prev) => ({
						...prev,
						step: InvoiceStepsEnum.AWAITING_PAYMENT,
						isLoading: false,
					}));
				}
			}

			if (invoice.status === RequestStatusId.EXPIRED) {
				return setPageConfig((prev) => ({
					...prev,
					step: InvoiceStepsEnum.PAYMENT_EXPIRED,
					isLoading: false,
				}));
			}

			const paymentCurrency = paymentMethods.find(
				(currency) =>
					currency.currency === invoice.payments[0]?.currency &&
					currency.network.network.nameId ===
					invoice.payments[0]?.transactions[0]?.network
			);

			if (invoice.status === RequestStatusId.PAID) {
				if (!!paymentCurrency) {
					setSelectedCurrency(paymentCurrency);
				} else {
					return;
				}

				return setPageConfig((prev) => ({
					...prev,
					step: InvoiceStepsEnum.PAYMENT_PAID,
					isLoading: false,
				}));
			}

			if (invoice.status === RequestStatusId.AWAITING_DEPOSIT) {
				if (!!paymentCurrency) {
					setSelectedCurrency(paymentCurrency);
				} else {
					return;
				}

				return setPageConfig((prev) => ({
					...prev,
					step: InvoiceStepsEnum.AWAITING_DEPOSIT,
					isLoading: false,
				}));
			}
		}
	};

	useEffect(() => {
		if (!params?.id) {
			if (Platform.OS === "web") {
				window.open(tenant.website, "_self");
			} else {
				navigation.navigate("Home");
			}
		}
	}, [params]);

	useEffect(() => {

		if (!!invoice) {

			const _channel = PusherService.subscribe(tenant.name + "-invoice-" + invoice.id);

			_channel.bind("invoice-updated", (event) => {
				if (!isFetching) {
					refetch();
				}
			});

			return () => {
				_channel.unbind_all();
				_channel.unsubscribe();
			};
		}
	}, [invoice?.id]);

	useEffect(() => {
		if (!!invoice) {
			setStepByInvoice(); //initial step set
		}
	}, [invoice]);

	useEffect(() => {
		if (isFocused && pageConfig.step !== InvoiceStepsEnum.CURRENCY_CHOICE) {
			setPageConfig((state) => ({
				...state,
				step: InvoiceStepsEnum.CURRENCY_CHOICE,
				isLoading: false,
			}));
		}
	}, [isFocused]);

	const setLoading = (value) => {
		setPageConfig((state) => ({
			...state,
			isLoading: value,
		}));
	};

	const toggleStep = async (action: "next" | "previous" = "next") => {
		if (pageConfig.isLoading) {
			return;
		}

		setLoading(true);

		if (action === "next") {
			await new Promise((r) => setTimeout(r, 200));
		} else {
			await new Promise((r) => setTimeout(r, 200));
		}

		setPageConfig((state) => {
			let step = state.step;

			if (action === "next" && step !== InvoiceStepsEnum.AWAITING_DEPOSIT) {
				step += 1;
				if (step === InvoiceStepsEnum.AWAITING_PAYMENT) {
				}
			} else if (step !== InvoiceStepsEnum.CURRENCY_CHOICE) {
				step -= 1;
			} else {
				step = InvoiceStepsEnum.CURRENCY_CHOICE;
			}

			if (step !== InvoiceStepsEnum.AWAITING_PAYMENT) {
			}

			return {
				...state,
				isLoading: false,
				step: step,
			};
		});
	};

	const RenderStep = ({ timer }: { timer: ReactNode }) => {
		const ComponentByStep = {
			[InvoiceStepsEnum.CURRENCY_CHOICE]: CurrencyChoiceStep,
			[InvoiceStepsEnum.AWAITING_PAYMENT]: AwaitingPaymentStep,
			[InvoiceStepsEnum.AWAITING_DEPOSIT]: AwaitingDepositStep,
			[InvoiceStepsEnum.PAYMENT_PAID]: PaymentPaidStep,
			[InvoiceStepsEnum.PAYMENT_CANCELLED]: PaymentCancelledStep,
			[InvoiceStepsEnum.PAYMENT_EXPIRED]: PaymentExpiredStep,
		};

		return ComponentByStep[pageConfig.step]({ timer });
	};

	const customContainerStyleByStep: { [key: string]: ViewStyle | ViewStyle[] } =
	{
		[InvoiceStepsEnum.CURRENCY_CHOICE]: {},
		[InvoiceStepsEnum.AWAITING_PAYMENT]: {},
		[InvoiceStepsEnum.PAYMENT_PAID]: styles.statusPageContainer,
		[InvoiceStepsEnum.AWAITING_DEPOSIT]: styles.statusPageContainer,
		[InvoiceStepsEnum.PAYMENT_CANCELLED]: styles.statusPageContainer,
		[InvoiceStepsEnum.PAYMENT_EXPIRED]: styles.statusPageContainer,
	};

	const customContentStyleByStep: { [key: string]: ViewStyle | ViewStyle[] } = {
		[InvoiceStepsEnum.CURRENCY_CHOICE]: {},
		[InvoiceStepsEnum.AWAITING_PAYMENT]: {},
		[InvoiceStepsEnum.PAYMENT_PAID]: styles.statusPageContent,
		[InvoiceStepsEnum.AWAITING_DEPOSIT]: styles.statusPageContent,
		[InvoiceStepsEnum.PAYMENT_CANCELLED]: styles.statusPageContent,
		[InvoiceStepsEnum.PAYMENT_EXPIRED]: styles.statusPageContent,
	};

	return (
		<InvoiceContext.Provider
			value={{
				toggleStep,
				setPageConfig,
				pageConfig,
				setSelectedCurrency,
				selectedCurrency,
				invoice,
				paymentMethods,
			}}
		>
			<View style={{ flex: 1, minHeight: "auto" }}>
				<Header
					onBackClick={
						pageConfig.step === InvoiceStepsEnum.AWAITING_PAYMENT &&
							paymentMethods?.length > 1
							? () => toggleStep("previous")
							: undefined
					}
				/>

				{invoice && (
					<View
						style={[
							isMobile ? commonStyles.containerMobile : commonStyles.container,
							customContainerStyleByStep[pageConfig.step],
						]}
					>
						<View
							style={[
								commonStyles.content,
								customContentStyleByStep[pageConfig.step],
							]}
						>
							<RenderStep timer={<AwaitingPaymentTimer />} />

							<Footer
								isFlex={[InvoiceStepsEnum.AWAITING_PAYMENT].includes(
									pageConfig.step
								)}
							/>
						</View>
					</View>
				)}

				{!invoice && !isLoading && <NotFoundView id={" " + params.id} />}

				{(pageConfig.isLoading || isLoading) && <LoadingView />}
			</View>
		</InvoiceContext.Provider>
	);
}
