import * as React from "react";
import {ReactNode, useEffect, useRef, useState} from "react";
import {Animated, Easing, Platform, View} from "react-native";
import {useMultiTenant} from "../providers/multi-tenant.provider";
import {useCustomTheme} from "../Theme";
import {AnimatedProgressBar} from "./AnimatedProgressBar";
const CustomSplashScreen = ({children}: { children: ReactNode }) => {
  if (Platform.OS !== "web") {
    return children;
  }

  const {tenant} = useMultiTenant();
  const theme = useCustomTheme();

  const [loading, setLoading] = useState(true);

  const fadeAnimImage = useRef(new Animated.Value(0)).current;
  const fadeAnimView = useRef(new Animated.Value(1)).current;

  const progressBarValue  = useRef(new Animated.Value(0)).current;


  const fadeInImage = () => {
    Animated.timing(fadeAnimImage, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start(({finished}) => {
      if (finished) {
        setTimeout(() => {
          fadeOutImage();
        }, 250)
      }
    });
  };

  const fadeOutImage = () => {
    Animated.timing(fadeAnimImage, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(({finished}) => {
      fadeOutView();
    });
  };

  const fadeOutView = () => {
    Animated.timing(fadeAnimView, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(({finished}) => {
      if (finished) {
        setLoading(false);
      }
    });
  };

  const progressBarAnimation = () => {
    Animated.timing(progressBarValue, {
      toValue: 1,
      easing: Easing.ease,
      duration: 700,
      useNativeDriver: true,
    }).start();
  }

  useEffect(() => {
    fadeInImage();

    progressBarAnimation();
  }, []);

  return (
    <>
      {children}

      {
        loading && (
          <Animated.View style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            backgroundColor: "#FFF",
            opacity: fadeAnimView
          }}>
            <Animated.View style={{ opacity: fadeAnimImage, marginTop: -128, display: "flex", flexDirection: "column", alignItems: "center", gap: 16 }}>
              <Animated.Image source={tenant.splashLogo}
                              style={{width: 256, height: 48, resizeMode: "contain"}}/>

              <View style={{ width: "100%", maxWidth: 200 }}>
                <AnimatedProgressBar animatedValue={progressBarValue} color={theme.colors.secondary} style={{ borderRadius: 8 }}/>
              </View>
            </Animated.View>

          </Animated.View>
        )
      }
    </>
  )
}

export {CustomSplashScreen}
