import React, { createContext, ReactNode, useContext, useState } from "react";
import { PosPaymentMethod } from "../../pages/Payment/steps/CryptoSelect";
import useCustomNavigation from "../hooks/useCustomNavigation";
import PixSvg from "../assets/svg/Pix";
import CryptoSvg from "../assets/svg/Crypto";
import ApplePaySvg from "../assets/svg/ApplePay";
import CreditCardSvg from "../assets/svg/CreditCard";
import DeditCardSvg from "../assets/svg/DebitCard";
import { t } from "i18next";

interface PosPaymentState {
  paymentMethod?: PosPaymentMethod;
  amountIn?: number;
  netAmountOut?: number;
  lastQuoteDate?: Date;
}

export interface PaymentMethodProps {
  type: string;
  title?: string;
  description?: string;
  icon: React.ElementType;
  available?: boolean;
}

interface PosContextProps {
  paymentState: PosPaymentState | null,
  setPaymentState: React.Dispatch<React.SetStateAction<PosPaymentState | null>>,
  resetPayment: () => void,
  paymentMethods: PaymentMethodProps[]
}

const PosContext = createContext<PosContextProps>({} as PosContextProps);

const PosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [paymentState, setPaymentState] = useState<PosPaymentState | null>(null);
  const { customNavigate } = useCustomNavigation();

  const resetPayment = () => {
    setPaymentState(null);
  }

  const paymentMethods = [
    {
      type: "pix",
      title: t("pos.steps.main.payment-method.pix"),
      description: "Pix (BRL)",
      icon: PixSvg,
      available: true,
    },
    {
      type: "crypto",
      title: t("pos.steps.main.payment-method.crypto"),
      description: "(Bitcoin, ETH, USDT e mais 103 criptos...)",
      icon: CryptoSvg,
      available: true,
    },
    {
      type: "credit-card",
      title: t("pos.steps.main.payment-method.credit-card"),
      description: "à vista",
      icon: CreditCardSvg,
      available: true,
    },
    {
      type: "debit-card",
      title: t("pos.steps.main.payment-method.debit-card"),
      description: "à vista",
      icon: DeditCardSvg,
      available: true,
    },
  ];

  return (
    <PosContext.Provider value={{ paymentState, setPaymentState, resetPayment, paymentMethods }}>
      {children}
    </PosContext.Provider>
  )
}

export const usePos = () => {
  const context = useContext(PosContext);

  if (!context) {
    throw new Error('usePos must be used within an PosProvider.');
  }
  return context;
}

export default PosProvider;
