import { PUSHER_API_KEY, PUSHER_API_CLUSTER } from "@env";
import Pusher, { Channel } from "pusher-js";
import { TenantConfig } from "src/config/tenants/types";

// Pusher.logToConsole = true;
class PusherServiceClass {

  private static _instance = null;

  constructor() {
  }

  getInstance(): Pusher {
    if (PusherServiceClass._instance === null) {
      PusherServiceClass._instance = new Pusher("3a90b2a1e8704e0a85be", {
        cluster: "us2",
        forceTLS: true,
        // userAuthentication: {
        //   endpoint: "/api/v3/core/notifications/auth/login",
        //   // transport: "ajax",    
        //   params: { serviceName: 'pusher' },
        //   // paramsProvider: null,
        //   // headers: null,
        //   headersProvider: () => {
        //     return {
        //       'X-Jwt-Token': window.localStorage.getItem(UserSessionStorage.jwtToken.getKey)
        //     };
        //   },
        //   customHandler: null,
        // }
      });
    }
    return PusherServiceClass._instance;
  }

  // getChannelNameByTenant(channel_name: string, tenant: TenantConfig): string {
  //   return `${tenant.name.split(".")[0].split("-")[0]}-${channel_name}-${Util.getEnvironment()}`;
  // }

  subscribe(channel_name: string): Channel {
    return this.getInstance().subscribe(channel_name);
  }

  // signin() {
  //   if (window.localStorage.getItem(`${window.location.host}/TOKEN_KEY`)) {
  //     this.getInstance().signin();
  //   }
  // }

}

export const PusherService = new PusherServiceClass();

