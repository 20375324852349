import { View, Text, ImageProps } from "react-native";

import React, { useEffect, useState } from "react";

import styles from "./styles";
import { CardButton } from "../../../../src/components/CardButton";
import CurrencyLogo from "../../../../src/components/CurrencyLogo";
import InvoiceService, {
	IQuoteInvoice,
} from "../../../../src/services/InvoiceService";
import { PosPaymentMethod } from "../../steps/CryptoSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ActivityIndicator, Button } from "react-native-paper";
import { useCustomTheme } from "../../../../src/Theme";

interface CurrencyLogoProps extends Partial<ImageProps> {
	paymentMethod: PosPaymentMethod;
	netAmountOut: number;
	isSelected: boolean;
	onSelect: (quote: IQuoteInvoice) => void;
}

export default function Crypto({
	paymentMethod,
	netAmountOut,
	onSelect,
	isSelected,
}: CurrencyLogoProps) {
	const { network, currencySymbol, name } = paymentMethod;
	const theme = useCustomTheme();

	const { data: quote, isFetching: isQuoting } = useQuery(
		[
			"paymentMethod/Quote",
			paymentMethod.currency.currency,
			paymentMethod.network.nameId,
			netAmountOut,
		],
		{
			queryFn: () =>
				InvoiceService.quoteInvoice({
					netAmountOut: netAmountOut,
					currencyIn: paymentMethod.currencySymbol,
					networkIn: network.nameId,
				}),
			refetchOnWindowFocus: false,
			cacheTime: 1000 * 60,
			staleTime: 1000 * 60,
			retry: 0,
			enabled: true,
		}
	);

	const { mutateAsync: quoteInvoice, isLoading: isQuotingInvoice } =
		useMutation({
			mutationFn: InvoiceService.quoteInvoice,
		});

	const [cardQuote, setCardQuote] = useState<any>();

	const [quoteNow, setQuoteNow] = useState<boolean>(false);

	useEffect(() => {
		const fetchQuote = async () => {
			const quote = await quoteInvoice({
				netAmountOut: netAmountOut,
				currencyIn: paymentMethod.currencySymbol,
				networkIn: network.nameId,
			});
			setCardQuote(quote);
			console.log(cardQuote);
		};

		fetchQuote();
	}, []);

	return (
		<View
			style={[
				styles.container,
				{}, // { opacity: !quote && !isQuoting ? 0.3 : 1 }
			]}
		>
			<CardButton
				style={({ hovered }: any) => [
					styles.button,
					isSelected ? styles.selected : null,
					hovered ? styles.selected : null,
				]}
				onPress={() => onSelect(quote!)}
				isSelect={isSelected}
				content={
					<>
						<View
							style={[
								styles.logos,
								network.currencySymbol === currencySymbol
									? { marginBottom: 4 }
									: null,
							]}
						>
							<CurrencyLogo
								currency={currencySymbol}
								network={
									network.currencySymbol !== currencySymbol
										? network
										: undefined
								}
								style={styles.currencyLogo}
								networkStyle={styles.networkLogo}
								contractType={"_"}
							/>
						</View>

						<View>
							<Text selectable={false} style={styles.title}>
								{name} ({currencySymbol})
							</Text>
						</View>

						<View>
							{network.currencySymbol !== currencySymbol ? (
								<Text selectable={false} style={styles.subtitle}>
									via{" "}
									<Text selectable={false} style={styles.networkText}>
										{network.description +
											(network.contractType ? `-${network.contractType}` : "")}
									</Text>
								</Text>
							) : null}
						</View>

						<View style={{ flexDirection: "row", gap: 4 }}>
							<Text style={{ fontSize: 12, color: "#79747E", height: 16 }}>
								{isQuoting
									? ""
									: quote
									? `${quote.amountIn} ${quote.currencyIn}`
									: ""}
								{/* {cardQuote
									? `${cardQuote.amountIn} ${cardQuote.currencyIn}`
									: ""} */}
							</Text>
							{isQuoting && (
								<ActivityIndicator size={12} color={theme.colors.secondary} />
							)}
							{/* {!cardQuote && (
								<ActivityIndicator size={12} color={theme.colors.secondary} />
							)} */}
						</View>
					</>
				}
			/>
		</View>
	);
}
