import {TCurrencies, TCurrencySymbol} from "./currency";

export default class CurrencyUtils {
    static getNumberByValue(value: string) {
        if(!value) {
            return 0;
        }

        if(!isNaN(Number(value))) {
            return Number(value);
        }

        const decimals = value.split(",")[1]?.length || 0;

        let formattedValue = value.replace(/\D+/g, "");
        const replaceRegex = new RegExp(`([0-9]{${decimals}})$`, "g");
        formattedValue = formattedValue.replace(replaceRegex, ".$1");
        const numberValue = Number(formattedValue);
        return !isNaN(numberValue) ? numberValue : 0;
    }

    /**
     *
     * @param amount
     * @param currency
     * @param showSymbol
     * @param minimumDecimals
     */
    static formatCurrency(amount: number, currency: TCurrencySymbol, showSymbol: boolean = true, minimumDecimals?: number): string {
        const options = showSymbol ? { minimumFractionDigits: minimumDecimals, style: "currency", currency: "BRL" } : { minimumFractionDigits: minimumDecimals }

        switch (currency) {
            case "BRL":
                return amount.toLocaleString("pt-BR", options);
            default:
                const decimals = (minimumDecimals === undefined) ? (!!TCurrencies ? TCurrencies.find(value => value.currency === currency)?.decimals : 0) : minimumDecimals;

                if(decimals > 8) {
                    const decimalsNumber = Math.pow(10, 8);
                    return (Math.ceil(amount * decimalsNumber) / decimalsNumber) + (showSymbol ? " " + currency : "");
                }

                return amount.toFixed(decimals) + (showSymbol ? " " + currency : "");
        }
    }
}
