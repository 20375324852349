import {Pressable, StyleSheet, View, Image, Text} from "react-native";
import { useTranslation } from "react-i18next";
import { AvailableLanguages } from "../i18n/i18n";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useCustomTheme } from "../Theme";

import en from "../../assets/flags/flag-us.png";
import pt from "../../assets/flags/flag-pt.png";
import es from "../../assets/flags/flag-es.png";
import React, {useCallback} from "react";

const LanguageButton = ({
													label,
													lng,
													changeLanguage
												}: {
	label: string;
	lng: AvailableLanguages;
	changeLanguage: (lng: AvailableLanguages) => void
}) => {
	const { i18n } = useTranslation();
	const theme = useCustomTheme();

	return (
		<Pressable
			onPress={() => changeLanguage(lng)}
		>
			{
				({ hovered }: any) => (
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							marginLeft: 12,
							marginBottom: 9,
							marginRight: 12,
							marginTop: 9,
							gap: 8
						}}
					>
						<Image source={lng === "en" ? en : lng === "pt" ? pt : es} style={{ width: 19, height: 19 }} alt={lng + " icon"}/>

						<Text
							selectable={false}
							style={{
								fontSize: 14,
								lineHeight: 20,
								color: hovered
									? theme.colors.primary
									: i18n.language !== lng
										? theme.colors.outline
										: theme.colors.primary
							}}>{label}</Text>
					</View>
				)
			}
		</Pressable>
	);
};

export default function LanguageView({ fontSize }: { fontSize?: number }) {
	const { i18n } = useTranslation();

	const changeLanguage = useCallback(async (lng: AvailableLanguages) => {
		await i18n.changeLanguage(lng);
		await AsyncStorage.setItem("language", lng);
	}, []);

	return (
		<View style={[styles.languageView]}>
			<LanguageButton label={"English"} lng={"en"} changeLanguage={changeLanguage}/>
			<LanguageButton label={"Português"} lng={"pt"} changeLanguage={changeLanguage}/>
			<LanguageButton label={"Espanõl"} lng={"es"} changeLanguage={changeLanguage}/>
		</View>
	);
}

const styles = StyleSheet.create({
	languageView: {
		flexDirection: "row",
		gap: 10,
	},
});
