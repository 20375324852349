import { Platform, Text, TextProps, View } from "react-native";
import Header from "../../../../src/components/Header";
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { styles } from "./styles";
import { PosTitle } from "../../components/Title";
import { useTranslation } from "react-i18next";
import { AnimatedProgressBar } from "../../../../src/components/AnimatedProgressBar";
import { useCustomTheme } from "../../../../src/Theme";
import { CustomButton } from "../../../../src/components/CustomButton";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";
import { intervalToDuration } from "date-fns";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import QRCode from "react-native-qrcode-svg";
import CurrencyLogo from "../../../../src/components/CurrencyLogo";

import { usePos } from "../../../../src/providers/pos.provider";
import { QuoteTimer } from "../CryptoSelect";
import { useQueryClient } from "@tanstack/react-query";
import { IQuoteInvoice } from "../../../../src/services/InvoiceService";
import ConfirmModal from "../../../../src/components/ConfirmModal";
import CopyToClipboardButton from "../../../../src/components/CopyToClipboardButton";
import { TCurrencySymbol } from "../../../../src/utils/currency";
import BottomSheet from "@gorhom/bottom-sheet";
import { CustomBackdrop } from "../../../../src/components/AuthBottomSheet/AuthBottomSheet";
import CancelPaymentBottomSheet from "../../../../src/components/CancelPaymentBottomSheet";

const isWeb = Platform.OS === "web";

const QrCode = ({ data, logo }: { data: string; logo?: ReactNode }) => {
	const theme = useCustomTheme();

	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				marginBottom: 16,
			}}
		>
			<QRCode
				value={data}
				size={Platform.OS === "web" ? 176 : 192}
				// enableLinearGradient={true}
				// gradientDirection={["170", "30", "0", "0"]}
				// linearGradient={[theme.colors.primary, theme.colors.secondary]}
				backgroundColor={"transparent"}
			/>
			{logo && (
				<View
					style={{
						position: "absolute",
						backgroundColor: "#FFF",
						borderRadius: 40,
						padding: 0,
					}}
				>
					{logo}
				</View>
			)}
		</View>
	);
};

const mockMaxTime = 60 * 10; // 10 minutes

const Timer = () => {
	const { t } = useTranslation();
	const theme = useCustomTheme();
	const navigation = useCustomNavigation();

	const timerInterval = useRef<NodeJS.Timer>(null);

	const [paymentTimeLimit, setPaymentTimeLimit] = useState(mockMaxTime);

	const [timeToCancelPayment, setTimeToCancelPayment] =
		useState(paymentTimeLimit);

	const timerIntervalToDuration = intervalToDuration({
		start: 0,
		end: timeToCancelPayment * 1000,
	});

	const hours = timerIntervalToDuration?.hours?.toString().padStart(2, "0");
	const minutes = timerIntervalToDuration?.minutes?.toString().padStart(2, "0");
	const seconds = timerIntervalToDuration?.seconds?.toString().padStart(2, "0");

	const startPaymentLimitTimer = (_time?: number) => {
		if (!!timerInterval.current) {
			clearInterval(timerInterval.current);
		}

		timerInterval.current = setInterval(() => {
			setTimeToCancelPayment((prevTime) => {
				const time = _time !== undefined ? _time : prevTime;

				if (time <= mockMaxTime - 30) {
					clearInterval(timerInterval.current!);
				}

				if (time <= 1) {
					clearInterval(timerInterval.current!);
					return 0;
				}

				return time - 1;
			});
		}, 1000);
	};

	useEffect(() => {
		startPaymentLimitTimer();
	}, []);

	useEffect(() => {
		if (timeToCancelPayment <= mockMaxTime - 30) {
			navigation.customReplace("payment-detected");
		}
	}, [timeToCancelPayment]);

	return (
		<View style={{ marginBottom: 16 }}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: 4,
				}}
			>
				<Text style={styles.timerText}>
					{t(`pos.steps.send-payment.timer.label`)}
				</Text>
				<Text style={styles.timerText}>{`${hours}:${minutes}:${seconds}`}</Text>
			</View>
			<AnimatedProgressBar
				animatedValue={timeToCancelPayment / paymentTimeLimit}
				color={theme.colors.secondary}
				style={{ borderRadius: 8, height: 8, backgroundColor: "#D9D9D9" }}
			/>
		</View>
	);
};

function TextWithFeedback({ ...props }: TextProps) {
	const [blink, setBlink] = useState(false);
	const theme = useCustomTheme();

	useEffect(() => {
		setBlink(true);
		setTimeout(() => {
			setBlink(false);
		}, 400);
	}, [props.children]);

	return (
		<Text
			{...props}
			style={[props.style, blink ? { color: theme.colors.secondary } : {}]}
		>
			{props.children}
		</Text>
	);
}

export default function PosSendPayment() {
	const { t } = useTranslation();
	const { paymentState, setPaymentState, resetPayment } = usePos();
	const { customReplace, goBack, canGoBack } = useCustomNavigation();
	const [cancelModalOpen, setCancelModalOpen] = useState<{
		type: "back" | "cancel";
	} | null>(null);

	const queryClient = useQueryClient();

	if (!paymentState || !paymentState?.paymentMethod) {
		return null;
	}

	const [cancelPaymentBottomSheet, setCancelPaymentBottomSheet] =
		useState(false);

	const bottomSheetRef = useRef<BottomSheet>(null);

	const snapPoints = useMemo(() => ["30%", "40%"], []);

	const handleSheetChange = useCallback((index) => {
		console.log("handleSheetChange", index);
	}, []);

	const handleClosePress = useCallback(() => {
		bottomSheetRef.current?.close();
		setTimeout(() => {
			setCancelPaymentBottomSheet(false);
		}, 500);
	}, []);

	const renderBackdrop = useCallback(
		(props) => <CustomBackdrop {...props} type={3} />,
		[]
	);

	const amount = paymentState?.amountIn;
	const { currency, network } = paymentState.paymentMethod;
	const wallet: string =
		network.currencySymbol === TCurrencySymbol.BTC
			? "3BioTxVSYTLAvvhGsV4vZPU7de2xcKwrr5"
			: network.currencySymbol === TCurrencySymbol.TRX
				? "TNyoSVavZe9hFGWE7RfLfFv6NBbuWdKFsB"
				: "0x9e78355Ff071b82910dB4add9D4e115aE0FBb1ce";

	const _qrCode: {
		address: string,
		metamask: string | null,
		trustwallet: string | null
	} = {
		address: wallet,
		metamask: null,
		trustwallet: null,
	}

	switch (network.nameId) {
		case "btc-mainnet":
			break;
		case "eth-mainnet":
		case "bsc-mainnet":
		case "polygon-mainnet":
			if (network.currencySymbol === currency.currency) {
				_qrCode.metamask = `https://metamask.app.link/send/${(network.nameId === "eth-mainnet" ? 1 : (network.nameId === "polygon-mainnet" ? 137 : (network.nameId === "bsc-mainnet" ? 56 : 0)))}/transfer?address=${wallet}&uint256=1e18`;
			} else {
				_qrCode.metamask = `https://metamask.app.link/send/${wallet}@${network.nameId === "eth-mainnet"}/transfer?address=${wallet}&uint256=1e18`;
			}
			break;
		case "trx-mainnet":
			break;
	}

	return (
		<>
			<View style={[styles.container]}>
				<Header
					onBackClick={() => {
						if (isWeb) {
							setCancelPaymentBottomSheet(true);
						} else {
							setCancelModalOpen({ type: "back" });
						}
					}}
					returnToMain={false}
				/>
				<PosTitle title={t(`pos.steps.send-payment.title`)} />

				<View style={[styles.content]}>
					<Timer />

					<QuoteTimer
						onTimeOut={() => {
							queryClient
								.refetchQueries({
									queryKey: [
										"paymentMethod/Quote",
										currency.currency,
										network.nameId,
										paymentState.netAmountOut,
									],
								})
								.then(() => {
									const data: IQuoteInvoice = queryClient.getQueryData([
										"paymentMethod/Quote",
										currency.currency,
										network.nameId,
										paymentState.netAmountOut,
									])!;

									setPaymentState((prev) => ({
										...prev,
										amountIn: data.amountIn,
									}));
								});
						}}
					/>

					<View style={{ alignItems: "center", marginBottom: 16 }}>
						<TextWithFeedback style={[styles.amountText]}>
							{amount
								? CurrencyUtils.formatCurrency(
									amount,
									currency.currency,
									true,
									currency.decimals
								)
								: null}
						</TextWithFeedback>
						<View
							style={{ flexDirection: "row", gap: 4, alignItems: "center" }}
						>
							<CurrencyLogo
								currency={network.currencySymbol}
								style={{ width: 20, height: 20 }}
							/>
							<Text style={[styles.networkText]}>
								{network.description +
									(network.contractType ? `-${network.contractType}` : ``)}
							</Text>
						</View>
					</View>

					<QrCode
						data={wallet}
						logo={
							<CurrencyLogo
								currency={currency.currency}
								width={48}
								height={48}
								style={{ width: 48, height: 48 }}
							/>
						}
					/>

					<View style={{ alignItems: "center", marginBottom: 16 }}>
						<Text style={[styles.walletText]} numberOfLines={1}>
							{wallet}
						</Text>
					</View>
				</View>

				<CopyToClipboardButton
					label={t(`pos.steps.send-payment.share`)}
					copiedLabel={t(`pos.steps.send-payment.share-copied`)}
					value={wallet}
				/>

				<View style={[styles.footer]}>
					<CustomButton
						label={t(`general.cancel`)}
						onPress={() => {
							// setCancelModalOpen({ type: "cancel" });
							setCancelModalOpen({ type: "back" });
							setCancelPaymentBottomSheet(true);
						}}
					/>
				</View>
			</View>

			{/* <ConfirmModal
				visible={!!cancelModalOpen}
				message={t(`pos.steps.send-payment.cancel-modal.message`)}
				subMessage={t(`pos.steps.send-payment.cancel-modal.subMessage`)}
				confirmButton={{
					label: t(`pos.steps.send-payment.cancel-modal.buttons.confirm`),
					onPress: () => {
						if (cancelModalOpen.type === "cancel") {
							resetPayment();
							customReplace("main");
						} else {
							canGoBack() && goBack();
						}
					},
				}}
				cancelButton={{
					label: t(`pos.steps.send-payment.cancel-modal.buttons.cancel`),
					onPress: () => setCancelModalOpen(null),
				}}
				onDismiss={() => setCancelModalOpen(null)}
			/> */}

			{isWeb ? (
				cancelPaymentBottomSheet && (
					<BottomSheet
						ref={bottomSheetRef}
						index={1}
						snapPoints={snapPoints}
						animateOnMount={true}
						onChange={handleSheetChange}
						keyboardBlurBehavior={"restore"}
						backdropComponent={renderBackdrop}
					>
						<CancelPaymentBottomSheet
							handleClose={() => setCancelPaymentBottomSheet(false)}
							handleConfirm={() => {
								canGoBack() && goBack();
								// if (cancelModalOpen.type === "cancel") {
								// 	resetPayment();
								// 	customReplace("main");
								// } else {
								// }
							}}
							handleCancel={undefined}
						/>
					</BottomSheet>
				)
			) : (
				<ConfirmModal
					visible={!!cancelModalOpen}
					message={t(`pos.steps.send-payment.cancel-modal.message`)}
					subMessage={t(`pos.steps.send-payment.cancel-modal.subMessage`)}
					confirmButton={{
						label: t(`pos.steps.send-payment.cancel-modal.buttons.confirm`),
						onPress: () => {
							if (cancelModalOpen?.type === "cancel") {
								resetPayment();
								customReplace("main");
							} else {
								canGoBack() && goBack();
							}
						},
					}}
					cancelButton={{
						label: t(`pos.steps.send-payment.cancel-modal.buttons.cancel`),
						onPress: () => setCancelModalOpen(null),
					}}
					onDismiss={() => setCancelModalOpen(null)}
				/>
			)}
		</>
	);
}
