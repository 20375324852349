import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Provider as PaperProvider} from "react-native-paper";
import {tenants} from "../config/tenants";
import {hubchainTenant} from "../config/tenants/hubchain";
import {TenantAppDomainEnum, TenantConfig} from "../config/tenants/types";
import {Helmet} from "react-helmet";
import {Platform} from "react-native";
import {MultiTenancyService} from "../services/MultiTenantService";
// @ts-ignore
import {APP_TENANT} from "@env";
import {AppTheme} from "../Theme";

interface MultiTenantContextData {
  tenant: TenantConfig
}

const MultiTenantContext = createContext<MultiTenantContextData>({} as MultiTenantContextData);

const MultiTenantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenant, setTenant] = useState({} as TenantConfig);
  const theme = tenant.theme as AppTheme;
  const isLoadingTenant = !tenant.name;

  useEffect(() => {
    handleConfigTenant()
  }, []);

  const handleConfigTenant = async () => {
    let tenant: TenantAppDomainEnum = TenantAppDomainEnum.HUBCHAINBANK;

    if(Platform.OS === "web") {
      tenant = await MultiTenancyService.getTenant()
    } else {
      tenant = APP_TENANT || TenantAppDomainEnum.HUBCHAINBANK;
    }

    setTenant(tenants[tenant] || hubchainTenant);
  }

  return <MultiTenantContext.Provider value={{ tenant }}>
    {
      (!isLoadingTenant && Platform.OS === "web") && (
        <Helmet>
          <meta name="theme-color" content={theme.colors.header} />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <link rel="icon" type="image/png" href={tenant.favicon as any} sizes="16x16" />
          <link rel="icon" type="image/png" href={tenant.favicon as any} sizes="32x32" />
          <link rel="shortcut icon" type="image/png" href={tenant.favicon as any} />                    
        </Helmet>
      )
    }

    <PaperProvider theme={tenant.theme || hubchainTenant.theme}>
      {isLoadingTenant ? null : children}
    </PaperProvider>
  </MultiTenantContext.Provider>
}

export const useMultiTenant = () => {
  const context = useContext(MultiTenantContext);

  if (!context) {
    throw new Error('useMultiTenant must be used within an MultiTenantProvider.');
  }
  return context;
}

export default MultiTenantProvider;
