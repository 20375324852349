import axios from "axios";
import { API_URL, TUNNEL_API_URL } from "@env";
import { Platform } from "react-native";

const defaultPrefix = "v1";
const corePrefix = "v3/core";

// console.log({ "Platform.OS": Platform.OS, API_URL, TUNNEL_API_URL });
const API = axios.create({
  baseURL: Platform.OS === "web" ? API_URL : ((TUNNEL_API_URL ?? "").trim() !== "" ? TUNNEL_API_URL : "https://payment.ezzepay.io/api/")
});

API.interceptors.request.use(async (request) => {
  return request;
});

export default API;

export { defaultPrefix, corePrefix };
