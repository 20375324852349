import { FlatList, View } from "react-native";
import styles, {
	CurrencyItemAmount,
	CurrencyItemLabel,
	CurrencyItemPressable,
	CurrencyItemSubLabel,
} from "../styles";
import { ActivityIndicator, Text } from "react-native-paper";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";

import { useCustomTheme } from "../../../src/Theme";
import { useTranslation } from "react-i18next";
import { IInvoiceCurrencyOption } from "../index";

import CurrencyLogo from "../../../src/components/CurrencyLogo";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import { ReactNode, useContext } from "react";
import React from "react";
import { useResponsive } from "../../../src/hooks/useResponsive";
import { InvoiceContext } from "../context";

const CurrencyItem = (option: IInvoiceCurrencyOption) => {
	const theme = useCustomTheme();
	const { toggleStep, setSelectedCurrency } = useContext(InvoiceContext);

	return (
		<CurrencyItemPressable
			style={(state: any) => ({
				backgroundColor:
					state.hovered || state.pressed ? "#f5f5f6" : "transparent",
			})}
			onPress={() => {
				setSelectedCurrency(option);
				toggleStep("next");
			}}
		>
			{({ hovered, pressed }: any) => (
				<>
					<View style={styles.currencyInfo}>
						<CurrencyLogo
							currency={option.currency}
							network={option.network.network}
							contractType={option.network.contractType}
							style={styles.currencyIcon}
						/>
						<View style={{ flexDirection: "column" }}>
							<CurrencyItemLabel>{`${option.name}${option.network.contractType !== undefined
								? (" (" + option.network.contractType + ")")
								: ""}`}</CurrencyItemLabel>
							<CurrencyItemSubLabel>
								{`${option.currency} • via `}
								{
									<CurrencyItemSubLabel
										style={{ fontWeight: "500", color: "#000" }}
									>{`${option.network.network.name}`}</CurrencyItemSubLabel>
								}
							</CurrencyItemSubLabel>
						</View>
					</View>

					<View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
						<CurrencyItemAmount>{`${CurrencyUtils.formatCurrency(
							CurrencyUtils.getNumberByValue(option.network.amount),
							option.currency, true, option.network.decimals
						)}`}</CurrencyItemAmount>
						<MaterialCommunityIcons
							name={"chevron-right"}
							size={24}
							color={
								hovered || pressed ? theme.colors.secondary : theme.colors.gray
							}
						/>
					</View>
				</>
			)}
		</CurrencyItemPressable>
	);
};

const CurrencyList = () => {
	const { paymentMethods } = useContext(InvoiceContext);

	const isFetchingCurrencies = false;
	const availableCurrencies = paymentMethods;
	const theme = useCustomTheme();
	const { t } = useTranslation();
	const { responsiveMode } = useResponsive();

	const isMobile = responsiveMode === "mobile";

	return (
		<View style={styles.currencyListView}>
			<Text style={{ color: theme.colors.outline, paddingLeft: isMobile ? 12 : 0 }}>
				{t("invoice.currency-list-label")}
			</Text>

			{isFetchingCurrencies && (
				<ActivityIndicator
					size={32}
					color={theme.colors.primary}
					style={{ marginTop: 16 }}
				/>
			)}

			<FlatList
				contentContainerStyle={[styles.currencyList]}
				data={availableCurrencies}
				renderItem={({ item }) => <CurrencyItem {...item} />}
				keyExtractor={(item) => item.currency}
				scrollEnabled={true}
			/>
		</View>
	);
};

export default function CurrencyChoiceStep({ timer }: { timer: ReactNode }) {
	const theme = useCustomTheme();
	const { t } = useTranslation();
	const { invoice } = useContext(InvoiceContext);
	const { responsiveMode } = useResponsive();

	const isMobile = responsiveMode === "mobile";

	return (
		<>
			<View style={styles.info}>
				<Text
					style={[
						{
							fontWeight: "bold",
							color: theme.colors.primary,
							textAlign: "center",
							fontSize: 16,
							paddingVertical: 8,
						},
						isMobile && {
							paddingTop: 0,
							borderBottomWidth: 1,
							borderBottomColor: theme.colors.primaryHover
						}
					]}
				>
					{t("invoice.title")}
				</Text>

				<View style={{ flexDirection: "column", gap: 4 }}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							paddingVertical: 10,
							paddingHorizontal: 16,
							borderRadius: 8,
						}}
					>
						<Text style={{ color: theme.colors.primary }}>
							{t("invoice.currency-choice.id")}
						</Text>
						<Text style={{ color: theme.colors.primary }}>{invoice?.code}</Text>
					</View>

					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							paddingVertical: 10,
							paddingHorizontal: 16,
							borderRadius: isMobile ? 0 : 8,
							backgroundColor: "#EEF0F3",
						}}
					>
						<Text
							style={{
								color: theme.colors.primary,
								fontWeight: "bold",
								fontSize: 16,
							}}
						>
							{t("invoice.currency-choice.total")}
						</Text>
						<Text
							style={{
								color: theme.colors.primary,
								fontWeight: "bold",
								fontSize: 20,
							}}
						>
							{CurrencyUtils.formatCurrency(
								CurrencyUtils.getNumberByValue(invoice?.amount!),
								invoice?.currency!
							)}
						</Text>
					</View>
				</View>
			</View>

			{timer}

			<CurrencyList />
		</>
	);
}
