// import { View, Text, Image } from "react-native";

// import React from "react";
// import { useTranslation } from "react-i18next";
// import { useCustomTheme } from "../../../../src/Theme";
// import { useResponsive } from "../../../../src/hooks/useResponsive";

// import styles from "./styles";

// import pixIcon from "../../../../src/assets/icon/pixIcon.png";
// import bitcoinIcon from "../../../../src/assets/icon/cryptoicon.png";
// import tapToPayIcon from "../../../../src/assets/icon/taptopayicon.png";
// import { CardButton } from "../../../../src/components/CardButton";
// import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
// import { usePayment } from "../../../../src/hooks/usePayment";

// interface Props {
// 	type: string;
// 	title: string;
// 	soon: boolean;
// 	description: string;
// }

// export default function PaymentMethod({
// 	type,
// 	title,
// 	soon,
// 	description,
// }: Props) {
// 	const theme = useCustomTheme();
// 	const { responsiveMode } = useResponsive();
// 	const { t } = useTranslation();

// 	const isMobile = responsiveMode === "mobile";

// 	const label = t(`pos.steps.main.payment-method.${type}`).split(" ");

// 	const navigation = useCustomNavigation();
// 	const { paymentMethod, handlePaymentMethod } = usePayment();

// 	return (
// 		<View style={styles.container}>
// 			<View
// 				style={{
// 					paddingHorizontal: 8,
// 					width: "100%",
// 					margin: 8,
// 					flex: 1,
// 				}}
// 			>
// 				<CardButton
// 					style={({ hovered }: any) => [
// 						styles.button,
// 						hovered ? styles.selected : null,
// 					]}
// 					onPress={handlePress}
// 					content={
// 						<>
// 							<View style={styles.icon}>
// 								<Image
// 									source={
// 										type === "pix"
// 											? pixIcon
// 											: type === "crypto"
// 											? bitcoinIcon
// 											: tapToPayIcon
// 									}
// 									style={{ width: 96, height: 96 }}
// 								/>
// 							</View>

// 							<View>
// 								<Text
// 									key={t(`pos.steps.main.payment-method.${type}`)}
// 									selectable={false}
// 									style={[
// 										styles.title,
// 										soon === true
// 											? {
// 													marginTop: -12,
// 											  }
// 											: {},
// 									]}
// 								>
// 									{t(`pos.steps.main.payment-method.${type}`)}
// 								</Text>
// 								{/* {
// 								label.map((label) => (
// 									<Text key={label} selectable={false} style={styles.title}>
// 										{label}
// 									</Text>
// 								));
// 							} */}
// 							</View>

// 							<View
// 								style={[
// 									soon === true
// 										? {
// 												backgroundColor: theme.colors.header,
// 												justifyContent: "center",
// 												paddingHorizontal: 8,
// 												position: "absolute",
// 												borderRadius: 24,
// 												height: 18,
// 												bottom: 2,
// 										  }
// 										: {
// 												height: 20,
// 												position: "absolute",
// 												bottom: 8,
// 										  },
// 								]}
// 							>
// 								<Text selectable={false} style={{ color: "white" }}>
// 									{soon === true ? t(`pos.steps.main.soon`) : null}
// 								</Text>
// 							</View>
// 						</>
// 					}
// 				/>
// 			</View>
// 		</View>
// 	);
// }

import { View, Text, Image } from "react-native";

import React from "react";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../../src/Theme";
import { useResponsive } from "../../../../src/hooks/useResponsive";

import styles from "./styles";

import { CardButton } from "../../../../src/components/CardButton";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import { usePayment } from "../../../../src/hooks/usePayment";
import { Card } from "../../../../src/components/Card";
import { PaymentMethodProps } from "../../../../src/providers/pos.provider";
import CryptoSvg from "../../../../src/assets/svg/Crypto";

export default function PaymentMethod({
	type,
	title,
	description,
	icon: Icon,
	available,
}: PaymentMethodProps) {
	const theme = useCustomTheme();
	const { responsiveMode } = useResponsive();
	const { t } = useTranslation();

	const isMobile = responsiveMode === "mobile";

	const label = t(`pos.steps.main.payment-method.${type}`).split(" ");

	const navigation = useCustomNavigation();
	const { paymentMethod, handlePaymentMethod } = usePayment();

	function handleSelectMethod() {
		navigation.navigate("amount-choice");
		// navigation.navigate("testFormBottomSheet" as never);

		handlePaymentMethod({
			type: type,
			title: title,
			description: description,
			icon: Icon,
			available: available,
		});
	}

	return (
		<View style={styles.container}>
			<View
				style={{
					width: "100%",
					flex: 1,
				}}
			>
				<Card onPress={() => handleSelectMethod()}>
					<View
						style={{
							gap: 4,
							flex: 1,
							borderRadius: 4,
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
						}}
					>
						<View style={[styles.icon, {}]}>
							<Icon
								style={{
									width: 72,
									height: 72,
								}}
							/>
						</View>
						<View
							style={{
								gap: 4,
								alignItems: "center",
							}}
						>
							<Text
								key={t(`pos.steps.main.payment-method.${type}`)}
								selectable={false}
								style={[styles.title]}
							>
								{title}
							</Text>
							{!available && (
								<View
									style={{
										backgroundColor: theme.colors.header,
										borderRadius: 24,
										alignItems: "center",
										paddingHorizontal: 16,
										justifyContent: "center",
										paddingVertical: 2,
									}}
								>
									<Text
										selectable={false}
										style={{
											color: "white",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{!available === true ? t(`pos.steps.main.soon`) : null}
									</Text>
								</View>
							)}
						</View>
					</View>
				</Card>
			</View>
		</View>
	);
}
