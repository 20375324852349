import API, {defaultPrefix, corePrefix} from "./api";
import {MultiTenancyService} from "./MultiTenantService";

export default class AuthService {
  async login(
    otpType: string,
    username: string,
    token?: string) {
    const headers = {
      "x-tenant-id": await MultiTenancyService.getTenant() + ".com"
    }

    const data = {
      otpType,
      username,
    }

    if (token) {
      Object.assign(data, { token })
    }

    const response = await API.post(`${corePrefix}/auth/login`, data, { headers });
    const responseData = response.data.data;

    if (response.data.status === "ok") {
      return {
        otpType: responseData.otpType,
        otpToken: responseData.otpToken,
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async codeVerification(otpType: string,
    otpCode: string,
    otpToken?: string,
    ) {
    const headers = {
      "x-tenant-id": await MultiTenancyService.getTenant() + ".com"
    }

    const data = {
      otpType,
      otpCode,
    }

    if (otpToken) {
      Object.assign(data, { otpToken })
    }

    const response = await API.post(`${corePrefix}/auth/login`, data, { headers });
    const responseData = response.data.data;

    if (response.data.status === "ok") {
      return {
        authorization: responseData.authorization,
        email: responseData.email,
        name: responseData.name,
        expiresIn: responseData.expiresIn
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async logout() {
    return await API.post(`${defaultPrefix}/auth/logout`);
  }
}
