import { TenantAppDomainEnum } from "../config/tenants/types";
import { TenantAliasConfig } from "../config/tenants";

export class MultiTenancyService {
    private static tenant: TenantAppDomainEnum;

    public static async getTenant(): Promise<TenantAppDomainEnum> {
        if (!MultiTenancyService.tenant) {

            const hostname = window.location.host;
            let tenant = null;

            for (const dotStep of hostname.split(".")) {
                for (const appDomain of Object.keys(TenantAliasConfig)) {
                    const alias = TenantAliasConfig[appDomain];
                    if (alias?.find((a: any) => (dotStep.indexOf(a) !== -1)) !== undefined) {
                        tenant = alias;                        
                        break;
                    }
                    if (tenant !== null) break;
                }
                
            }

            MultiTenancyService.tenant = tenant;            
        }

        return MultiTenancyService.tenant;
    }
}
