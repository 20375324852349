import { Pressable, View } from "react-native";
import styles from "../styles";
import { Text } from "react-native-paper";
import { useCustomTheme } from "../../../src/Theme";
import { useTranslation } from "react-i18next";

import { useNavigation } from "@react-navigation/native";
import { PropsStack } from "../../../src/Navigation/Stack/props";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useContext } from "react";
import React from "react";
import {InvoiceContext} from "../context";

export default function PaymentPaidStep() {
	const theme = useCustomTheme();
	const { t } = useTranslation();
	const { selectedCurrency, invoice } = useContext(InvoiceContext);

	const navigation = useNavigation<PropsStack>();

	return (
		<>
			<View style={{ alignItems: "center", justifyContent: "center" }}>
				<View
					style={[
						styles.statusIconView,
						{ backgroundColor: theme.colors.successLight },
					]}
				>
					<MaterialCommunityIcons
						name={"check"}
						size={96}
						color={"#FFF"}
						style={[
							styles.statusIcon,
							{ backgroundColor: theme.colors.success },
						]}
					/>
				</View>
			</View>

			<Text style={[styles.statusTitleText, { color: theme.colors.success }]}>
				{t(`invoice.payment-completed.title`)}
			</Text>

			<View style={styles.statusTextView}>
				<Text style={[styles.statusText, { color: theme.colors.outline }]}>
					{t(`invoice.payment-completed.info-1`, {
						currency: selectedCurrency.name,
					})}
				</Text>
			</View>

			<View style={styles.footerView}>
				<View style={{ width: "100%" }}>
					<Pressable
						onPress={() =>
							navigation.navigate("Receipt" as any, { id: invoice?.id } as any)
						}
						style={(state: any) => ({
							backgroundColor: state.hovered
								? theme.colors.secondary
								: theme.colors.primary,
							width: "100%",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 10,
							paddingTop: 10,
							transitionDuration: "400ms",
						})}
					>
						{
							({ hovered }: any) => (
								<Text style={{
									color: "rgb(255, 255, 255)",
									fontFamily: "Inter_500Medium",
									letterSpacing: 0.1,
									fontSize: 16,
									lineHeight: 1.5
								}}>
									{t(`invoice.payment-completed.receipt-button`)}
								</Text>
							)
						}
					</Pressable>
				</View>
			</View>
		</>
	);
}
