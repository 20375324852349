import { Text, View } from "react-native";
import Header from "../../../../src/components/Header";
import React from "react";
import { styles } from "./styles";
import { PosTitle } from "../../components/Title";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../../../src/Theme";
import { CustomButton } from "../../../../src/components/CustomButton";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import CurrencyLogo from "../../../../src/components/CurrencyLogo";
import { usePos } from "../../../../src/providers/pos.provider";
import CopyToClipboardButton from "../../../../src/components/CopyToClipboardButton";

const ViewInBlockchainButton = ({ link }: { link: string }) => {
	const theme = useCustomTheme();
	const { t } = useTranslation();

	return (
		<CustomButton
			onPress={() => {}}
			label={t(`pos.steps.paid-payment.view-transaction`)}
			type={"ghost"}
			style={{
				backgroundColor: "transparent",
			}}
			labelStyle={{
				color: theme.colors.secondary,
			}}
			rightAddon={
				<MaterialCommunityIcons
					name={"open-in-new"}
					size={16}
					color={theme.colors.secondary}
				/>
			}
		/>
	);
};

const StatusIcon = () => {
	const theme = useCustomTheme();

	return (
		<View style={[styles.statusIconView]}>
			<View
				style={[
					styles.statusIconView,
					{ backgroundColor: "#def1e9", width: 150, height: 150 },
				]}
			>
				<View
					style={[
						styles.statusIconView,
						{ backgroundColor: "#bee3d6", width: 128, height: 128 },
					]}
				>
					<View
						style={[
							styles.statusIconView,
							{ backgroundColor: theme.colors.success, width: 96, height: 96 },
						]}
					>
						<MaterialCommunityIcons name={"check"} size={80} color={"#FFF"} />
					</View>
				</View>
			</View>
		</View>
	);
};

export default function PosPaymentPaid() {
	const navigation = useCustomNavigation();
	const { t } = useTranslation();
	const { paymentState } = usePos();

	const theme = useCustomTheme();

	if (!paymentState || !paymentState?.paymentMethod) {
		return null;
	}

	const amount = paymentState?.amountIn;
	const { currency, network } = paymentState?.paymentMethod;
	const transactionId = "0x04c88e6a...0aa703f3";
	const transactionIdParsed = "0x04c88e6a...0aa703f3";

	return (
		<View style={[styles.container]}>
			<Header />
			<PosTitle title={t(`pos.steps.paid-payment.title`)} />

			<View style={[styles.content]}>
				<View style={{ alignItems: "center" }}>
					<Text style={[styles.amountText]}>
						{amount
							? CurrencyUtils.formatCurrency(
									amount,
									currency.currency,
									true,
									currency.decimals
							  )
							: null}
					</Text>
					<View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
						<CurrencyLogo
							currency={network.currencySymbol}
							width={20}
							height={20}
							style={{ width: 20, height: 20 }}
						/>
						<Text style={[styles.networkText]}>
							{network.description +
								(network.contractType ? `-${network.contractType}` : ``)}
						</Text>
					</View>
				</View>

				<View style={{ marginVertical: 32, marginBottom: 24 }}>
					<StatusIcon />
				</View>
			</View>

			<View style={{ width: "100%", marginBottom: 8 }}>
				<CopyToClipboardButton
					label={transactionId}
					value={transactionIdParsed}
					labelColor={"#000"}
				/>
			</View>

			<ViewInBlockchainButton link={transactionId} />

			<View style={[styles.footer]}>
				<CustomButton
					label={t(`general.close`)}
					onPress={() => {
						navigation.customReplace("main");
					}}
				/>
			</View>
		</View>
	);
}
