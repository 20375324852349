import {View} from "react-native";
import styles from "../styles";
import {Text} from "react-native-paper";
import {useCustomTheme} from "../../../src/Theme";
import {useTranslation} from "react-i18next";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

export default function PaymentCancelledStep() {
  const theme = useCustomTheme();
  const {t} = useTranslation();

  return (
    <>
      <View style={{alignItems: "center", justifyContent: "center"}}>
        <View
          style={[
            styles.statusIconView,
            {backgroundColor: theme.colors.dangerHover}
          ]}
        >
          <MaterialCommunityIcons
            name={"close"}
            size={96}

            color={"#FFF"}
            style={[
              styles.statusIcon,
              {backgroundColor: theme.colors.danger}
            ]}
          />
        </View>
      </View>

      <Text style={[styles.statusTitleText, {color: theme.colors.danger}]}>
        {t(`invoice.payment-cancelled.title`)}
      </Text>


      <View style={styles.statusTextView}>
        <Text style={[styles.statusText, {color: theme.colors.outline}]}>
          {t(`invoice.payment-cancelled.info-1`)}
        </Text>
      </View>

      <View style={{flex: 1}}/>
    </>
  )
}
