import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		flex: 1,
		margin: 8,
		borderWidth: 1,
		borderColor: "transparent",
	},
	hover: {
		shadowOpacity: 0.5,
		shadowRadius: 8,
		borderWidth: 1,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
});
