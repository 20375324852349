import { StyleSheet } from "react-native";

import Constants from "expo-constants";

const statusBarHeight = Constants.statusBarHeight;

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		height: "100%",
		width: "100%",
	},
	content: {
		justifyContent: "space-between",
		flexDirection: "column",
		marginTop: 24,
		flex: 1,
		marginHorizontal: 16,
	},
	list: {
		flex: 1,
	},
	amountContainer: {
		alignItems: "center",
	},
	amountContent: {
		fontFamily: "Inter_500Medium",
		flexDirection: "row",
		display: "flex",
		fontSize: 48,
		gap: 4,
	},
	amount: {
		fontFamily: "Inter_500Medium",
		lineHeight: 48,
		fontSize: 48,
	},
	symbolAndDecimals: {
		fontFamily: "Inter_500Medium",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "row",
		display: "flex",
		lineHeight: 24,
		fontSize: 24,
	},
	maxAmountWarning: {
		fontFamily: "Inter_500Medium",
		color: "#79747E",
		fontSize: 14,
		marginTop: 4,
	},
	nextButton: {
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		marginTop: 14,
		padding: 18,
	},
	nextButtonContent: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 16,
		gap: 8,
	},
	nextButtonText: {
		fontFamily: "Inter_500Medium",
		color: "white",
		fontSize: 16,
	},

	// HEADER
	headerView: {
		paddingHorizontal: 16,
		height: 72,
		alignItems: "flex-end",
		justifyContent: "center",
	},
	headerAccountButton: {
		margin: 0,
		padding: 0,
	},
	headerLogoImage: {
		height: 40,
		width: "100%",
		resizeMode: "contain",
		marginVertical: 8,
	},
	headerLogoView: {
		alignItems: "center",
	},
	languageView: {
		alignItems: "center",
		justifyContent: "center",
	},
	releaseView: {
		alignItems: "center",
		justifyContent: "center",
		padding: 18,
	},
	release: {
		fontFamily: "Inter_400Regular",
		fontSize: 14,
	},
});

export default styles;
