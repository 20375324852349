import {useTranslation} from "react-i18next";
import {StyleSheet, View, Text} from "react-native";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Footer from "./Footer";
import commonStyles from "../styles/commonStyles";
import {customTheme, useCustomTheme} from "../Theme";

interface NotFoundViewProps {
  id?: string
}

export default function NotFoundView({ id }: NotFoundViewProps) {
  const theme = useCustomTheme();
  const {t} = useTranslation();

  return (
    <View style={commonStyles.container}>
      <View style={styles.content}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 96,
            width: 96,
            borderRadius: 96,
            backgroundColor: customTheme.colors.warningHover,
          }}
        >
          <MaterialCommunityIcons
            name={"exclamation"}
            size={80}

            color={"#FFF"}
            style={[
              {
                backgroundColor: theme.colors.warning,
                borderRadius: 96
              }
            ]}
          />
        </View>
        <Text
          style={{ fontSize: 16, fontWeight: "500" }}
        >
          { t(`not-found.title`) }
        </Text>
        <Text
          style={{ color: theme.colors.gray }}
        >
          { t(`not-found.message`, { replace: { id: id ? (" " + id) : ""  } }) }
        </Text>
      </View>
      <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    marginBottom: 32
  }
});
