import { TouchableRipple, TouchableRippleProps } from "react-native-paper";
import {
	GestureResponderEvent,
	Platform,
	StyleProp,
	StyleSheet,
	Text,
	TextStyle,
	ViewStyle,
} from "react-native";
import { useCustomTheme } from "../Theme";
import { commonFonts } from "../styles/commonStyles";
import {ReactNode, useMemo, useState} from "react";
import React from "react";

type CustomButtonTypes = "contained" | "outlined" | "ghost";

interface CustomButtonTypeStyle {
	labelStyle?: StyleProp<TextStyle>;
	labelStyleHover?: StyleProp<TextStyle>;
	buttonStyle?: StyleProp<ViewStyle>;
	buttonStyleHover?: StyleProp<ViewStyle>;
}

export interface CustomButtonPros extends Omit<TouchableRippleProps, "children"> {
	type?: CustomButtonTypes;
	isInverted?: boolean;
	label: string;
	labelStyle?: StyleProp<TextStyle>;
	rightAddon?: ((isHover: boolean) => ReactNode) | ReactNode;
	leftAddon?: ((isHover: boolean) => ReactNode) | ReactNode;
	disabled?: boolean;
}

const isWeb = Platform.OS === "web";

export function CustomButton({
	style,
	label,
	labelStyle,
	disabled = false,
	type = "contained",
	isInverted = false,
	...props
}: CustomButtonPros) {
	const theme = useCustomTheme();

	const [isHover, setIsHover] = useState(false);

	const [onPressEffect, setOnPressEffect] = useState(false);

	const handleOnPressIn = (event: GestureResponderEvent) => {
		setOnPressEffect(true);
	};

	const handleOnPressOut = (event: GestureResponderEvent) => {
		if (onPressEffect) {
			setTimeout(() => {
				setOnPressEffect(false);
			}, 50);
		}
	};

	const styleByType = useMemo(() => {
		const stylesByType: Record<CustomButtonTypes, CustomButtonTypeStyle> = {
			contained: {
				buttonStyle: {
					backgroundColor: theme.colors.secondary,
				},
				buttonStyleHover: {
					...(isWeb ? ({ filter: "brightness(90%)" } as ViewStyle) : {}),
				},
				labelStyle: {
					color: "#FFF",
				},
				labelStyleHover: {},
			},
			ghost: {
				buttonStyle: {
					backgroundColor: "#F8F8F8",
				},
				buttonStyleHover: {},
				labelStyle: {
					color: "#1C1B1F",
				},
				labelStyleHover: {
					color: theme.colors.secondary,
				},
			},
			outlined: {
				buttonStyle: {
					backgroundColor: "#FFF",
					borderWidth: 1,
					paddingHorizontal: 16,
					borderRadius: 16,
					borderColor: theme.colors.secondary,
				},
				buttonStyleHover: {
					backgroundColor: theme.colors.secondary,
					borderWidth: 1,
					paddingHorizontal: 16,
					borderRadius: 16,
					borderColor: "#FFF"
				},
				labelStyle: {
					color: theme.colors.secondary
				},
				labelStyleHover: {
					color: "#FFF"
				},
			},
		}

		return stylesByType[type];
	}, [theme, type]);

	return (
		<TouchableRipple
			onHoverIn={() => setIsHover(true)}
			onHoverOut={() => setIsHover(false)}
			onPressIn={handleOnPressIn}
			onPressOut={handleOnPressOut}
			disabled={disabled}
			{...props}
			style={[
				styles.button,
				styleByType.buttonStyle,
				isInverted ? styleByType.buttonStyleHover : {},
				isHover ? (isInverted ? styleByType.buttonStyle : styleByType.buttonStyleHover) : {},
				disabled ? { opacity: 0.4 } : {},
				disabled && isWeb ? ({ cursor: "default" } as ViewStyle) : {},
				{
					...(isWeb ? { userSelect: "none" } : {}),
				} as ViewStyle,
				Platform.OS === "ios" && onPressEffect
					? {
							opacity: 0.5,
					  }
					: {},
				style,
			]}
		>
			<>
				{props.leftAddon}

				{label ? (
					<Text
						selectable={false}
						style={[
							styles.label,
							styleByType.labelStyle,
							isInverted ? styleByType.labelStyleHover : {},
							isHover ? ( isInverted ? styleByType.labelStyle : styleByType.labelStyleHover ) : {},
							Platform.OS === "ios" && onPressEffect
								? styleByType.labelStyleHover
								: {},
							labelStyle,
						]}
					>
						{label}
					</Text>
				) : null}

				{typeof props.rightAddon === "function" ? props.rightAddon(isHover) : props.rightAddon}
			</>
		</TouchableRipple>
	);
}

const styles = StyleSheet.create({
	button: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		height: 56,
		gap: 8,
		...(isWeb ? { transitionDuration: "0.3s" } : {}),
	},
	label: {
		fontFamily: commonFonts.medium,
		fontSize: 16,
		...(isWeb ? { transitionDuration: "0.3s" } : {}),
	},
});
