import { TouchableRipple, useTheme } from "react-native-paper";
import { Platform } from "react-native";
import { useState } from "react";
import React from "react";
import { styles } from "./styles";
import { useCustomTheme } from "../../Theme";

interface CardProps {
	onPress: () => void;
	children: React.ReactElement;
}

export function Card({ children, onPress }: CardProps) {
	const isWeb = Platform.OS === "web";

	const [hover, setHover] = useState(false);

	const colors = useCustomTheme().colors;

	return (
		<TouchableRipple
			onPress={onPress}
			onHoverIn={() => setHover(true)}
			onHoverOut={() => setHover(false)}
			style={[
				styles.container,
				{
					backgroundColor: colors.elevation.level1,
				},
				hover && [
					styles.hover,
					{
						backgroundColor: colors.elevation.level2,
						borderColor: colors.secondary,
						shadowColor: colors.secondary,
					},
				],
			]}
		>
			{children}
		</TouchableRipple>
	);
}
