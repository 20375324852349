import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default (props: any) => {
	return (
		<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			<Path
				fill="#00bdaf"
				d="M242.4 292.5c5.4-5.4 14.7-5.4 20.1 0l77 77c14.2 14.2 33.1 22 53.1 22h15.1l-97.1 97.1c-30.3 29.5-79.5 29.5-109.8 0l-97.5-97.4h9.3c20 0 38.9-7.8 53.1-22l76.7-76.7zm20.1-73.6c-6.4 5.5-14.6 5.6-20.1 0l-76.7-76.7c-14.2-15.1-33.1-22-53.1-22h-9.3l97.4-97.4c30.4-30.4 79.6-30.4 109.9 0l97.2 97.1h-15.2c-20 0-38.9 7.8-53.1 22l-77 77zm-149.9-76.2c13.8 0 26.5 5.6 37.1 15.4l76.7 76.7c7.2 6.3 16.6 10.8 26.1 10.8 9.4 0 18.8-4.5 26-10.8l77-77c9.8-9.7 23.3-15.3 37.1-15.3h37.7l58.3 58.3c30.3 30.3 30.3 79.5 0 109.8l-58.3 58.3h-37.7c-13.8 0-27.3-5.6-37.1-15.4l-77-77c-13.9-13.9-38.2-13.9-52.1.1l-76.7 76.6c-10.6 9.8-23.3 15.4-37.1 15.4H80.8l-58-58c-30.4-30.3-30.4-79.5 0-109.8l58-58.1h31.8z"
			/>
		</Svg>
	);
}