import React, {useContext, useEffect, useRef, useState} from "react";
import {useCustomTheme} from "../../../src/Theme";
import {useTranslation} from "react-i18next";
import {View} from "react-native";
import styles from "../styles";
import {Text} from "react-native-paper";

import * as Progress from "react-native-progress";
import {addMinutes, differenceInSeconds, intervalToDuration,} from "date-fns";
import {RequestStatusId} from "../../../src/services/InvoiceService";
import Utils from "../../../src/utils/Utils";
import {InvoiceContext} from "../context";
import {InvoiceStepsEnum} from "../types";

export const AwaitingPaymentTimer = ({
}: {
	paymentTimeLimit?: number;
}) => {
	const { setPageConfig, invoice } = useContext(InvoiceContext);

	const theme = useCustomTheme();
	const { t } = useTranslation();

	const timerInterval = useRef<NodeJS.Timer>(null);

	const [paymentTimeLimit, setPaymentTimeLimit] = useState(60 * 30);

	const [timeToCancelPayment, setTimeToCancelPayment] = useState(paymentTimeLimit);

	const timerIntervalToDuration = intervalToDuration({
		start: 0,
		end: timeToCancelPayment * 1000,
	});

	const startPaymentLimitTimer = (_time?: number) => {
		if (!!timerInterval.current) {
			clearInterval(timerInterval.current);
		}

		timerInterval.current = setInterval(() => {
			setTimeToCancelPayment((prevTime) => {
				const time = _time !== undefined ? _time : prevTime;

				if (time <= 1) {
					clearInterval(timerInterval.current);
					invoice.status = RequestStatusId.EXPIRED;
					setPageConfig(prev => ({
						...prev,
						step: InvoiceStepsEnum.PAYMENT_EXPIRED
					}))
				}

				return time - 1;
			});
		}, 1000);
	};

	useEffect(() => {
		if(invoice) {
			const createdAt = Utils.getDateByDateString(invoice.createdAt);
			const expiresAt = invoice.expiresAt ? Utils.getDateByDateString(invoice.expiresAt) : addMinutes(createdAt, 15);

			const diff = differenceInSeconds(expiresAt, new Date());

			if(diff <= 1 && ![RequestStatusId.COMPLETED, RequestStatusId.PAID].includes(invoice.status)) {
				invoice.status = RequestStatusId.EXPIRED;
			} else {
				setPaymentTimeLimit(differenceInSeconds(expiresAt, createdAt))
				setTimeToCancelPayment(diff);
				startPaymentLimitTimer();
			}
		}
	}, [invoice]);

	const minutes = timerIntervalToDuration.minutes;
	const seconds = timerIntervalToDuration.seconds;

	return (
		<View style={[
			styles.progressCircleView
		]}>
			<Progress.Circle
				progress={timeToCancelPayment / paymentTimeLimit}
				borderWidth={0}
				fill={"transparent"}
				size={20}
				color={theme.colors.primary}
				unfilledColor={theme.colors.outlineVariant}
			/>
			<Text
				style={{
					textAlign: "center",
				}}
			>
				{t(`invoice.awaiting-payment.timer-label`)}
				{minutes === 0
					? null
					: ` ${minutes?.toString().padStart(1)} ${
							minutes === 1
								? t(`invoice.awaiting-payment.minute`)
								: t(`invoice.awaiting-payment.minutes`)
					  } ${t(`invoice.awaiting-payment.and`)}`}
				{` ${seconds?.toString().padStart(1)} ${
					seconds === 1 || seconds === 0
						? t(`invoice.awaiting-payment.second`)
						: t(`invoice.awaiting-payment.seconds`)
				}`}
			</Text>
		</View>
	);
};
