import { TCurrencySymbol } from "../utils/currency";

import { AxiosResponse } from "axios";
import { isYesOrNoEnum } from "src/utils/types";
import API, { corePrefix } from "./api";

export enum CurrencyTypeEnum {
  FIAT = "fiat",
  CRYPTO = "crypto"
}

export interface INetwork {
  "nameId": string,
  "description": string,
  "currencySymbol": TCurrencySymbol,
  "placeholder": string,
  "contractType"?: string,
  minConfirmations: number
}

export interface CurrencyData {
  currency: TCurrencySymbol,
  name: string,
  type: CurrencyTypeEnum,
  decimals: number,
  "deposit": {
    "enabled": isYesOrNoEnum,
    "minConfirmations": null,
    "amountMin": string,
    "networks": INetwork[]
  },
  "withdraw": {
    "enabled": isYesOrNoEnum,
    "amountMin": string,
    "amountFee": string,
    "networks": INetwork[]
  },
  "category": {
    "code": string,
    "name": string
  }
}

interface GetCurrenciesResponse {
  data: CurrencyData[],
  status: string
}

export interface MarketData {
  "symbol": string,
  "baseAmountMin": string,
  "baseAmountIncrement": string,
  "baseAmountDecimals": number,
  "quoteAmountMin": string,
  "quoteAmountIncrement": string,
  "quoteAmountDecimals": number,
  "baseCurrency": {
    "currency": TCurrencySymbol,
    "name": string,
    "type": CurrencyTypeEnum,
    "precision": number,
    "deposit": {
      "enabled": isYesOrNoEnum,
      "minConfirmations": string,
      "amountMin": string,
      "networks": INetwork[]
    },
    "withdraw": {
      "enabled": isYesOrNoEnum,
      "amountMin": string,
      "amountFee": string,
      "networks": INetwork[]
    },
    "category": {
      "code": string,
      "name": string
    }
  },
  "quoteCurrency": {
    "currency": TCurrencySymbol,
    "name": string,
    "type": CurrencyTypeEnum,
    "precision": number,
    "deposit": {
      "enabled": isYesOrNoEnum,
      "minConfirmations": null,
      "amountMin": string,
      "networks": INetwork[]
    },
    "withdraw": {
      "enabled": isYesOrNoEnum,
      "amountMin": string,
      "amountFee": string,
      "networks": INetwork[]
    },
    "category": {
      "code": string,
      "name": string
    }
  }
}

export interface ICurrencyTicker {
  updatedAt: number,
  provider: string,
  name: string,
  EUR: number,
  USD: number,
  BRL: number,
}

interface GetMarketsResponse {
  data: MarketData[],
  status: string
}

export default class CurrencyService {
  async getCurrencies(): Promise<AxiosResponse<GetCurrenciesResponse>> {
    console.log(`[currencies()]:`, `${API.defaults.baseURL}${corePrefix}/currencies`);
    return API.get(`${corePrefix}/currencies`);
  }

  async getMarkets(): Promise<AxiosResponse<GetMarketsResponse>> {
    console.log(`[getMarkets()]:`, `${API.defaults.baseURL}${corePrefix}/markets`);
    return API.get(`${corePrefix}/markets`);
  }
}
