import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useResponsive } from "../../../../src/hooks/useResponsive";
import { CardButton } from "../../../../src/components/CardButton";
import BTC from "../../../../src/assets/icons/networks/BTC";
import TRX from "../../../../src/assets/icons/networks/TRX";
import ETH from "../../../../src/assets/icons/networks/ETH";
import { useCustomTheme } from "../../../../src/Theme";
import { View, Text, ImageProps } from "react-native";
import { useTranslation } from "react-i18next";
import React from "react";
import styles from "./styles";
import {
	TCurrencySymbol,
	TNetworkSymbol,
} from "../../../../src/utils/currency";
import { Chip } from "react-native-paper";

// const logos = {
// 	[TCurrencySymbol.BTC]: require("../../../../assets/currencies/BTC.png"),
// 	[TCurrencySymbol.ETH]: require("../../../../assets/currencies/ETH.png"),
// 	[TCurrencySymbol.USDT]: require("../../../../assets/currencies/USDT.png"),
// };

const networks = {
	[TNetworkSymbol.Bitcoin]: BTC,
	[TNetworkSymbol.Tron]: TRX,
	[TNetworkSymbol.Ethereum]: ETH,
};

interface CurrencyLogoProps extends Partial<ImageProps> {
	name: string;
	icon: string;
	isSelected: boolean;
	onSelect: () => void;
}

export default function HistoryCard({
	name,
	icon,
	isSelected,
	onSelect,
}: CurrencyLogoProps) {
	const { responsiveMode } = useResponsive();
	const theme = useCustomTheme();
	const { t } = useTranslation();

	const isMobile = responsiveMode === "mobile";

	return (
		<View style={styles.container}>
			<CardButton
				style={({ hovered }: any) => [
					styles.button,
					isSelected ? styles.selected : null,
					hovered ? styles.selected : null,
				]}
				onPress={onSelect}
				isSelect={isSelected}
				content={
					<>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%",
								paddingVertical: 8,
								paddingHorizontal: 16,
								alignItems: "center",
							}}
						>
							<View>
								<Text
									style={{
										paddingBottom: 8,
										color: "#1C1B1F",
										fontFamily: "Inter_400Regular",
										fontSize: 14,
									}}
								>
									Compra USDT
								</Text>
								<View
									style={{
										flexDirection: "row",
										gap: 8,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Text
										style={{
											color: "#79747E",
											fontFamily: "Inter_400Regular",
											fontSize: 12,
										}}
									>
										24/08/2023
									</Text>
									<Chip
										textStyle={{
											fontSize: 12,
											marginVertical: 4,
											marginHorizontal: 8,
										}}
										compact
									>
										Cancelado
									</Chip>
								</View>
							</View>
							<View>
								<Text
									style={{
										textAlign: "right",
										color: "#1C1B1F",
										fontFamily: "Inter_400Regular",
										fontSize: 14,
									}}
								>
									R$ 100,00
								</Text>
								<Text
									style={{
										textAlign: "right",
										color: "#79747E",
										fontFamily: "Inter_400Regular",
										fontSize: 12,
									}}
								>
									19,4658 USDT
								</Text>
							</View>
						</View>
					</>
				}
			/>
		</View>
	);
}
