import {
	Text,
	View,
	StyleSheet,
	TextInput as TextInputRN,
	ScrollView,
} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { PostalCodeService } from "../../utils/utilsService";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { validateEmail } from "validations-br";
import { CustomButton } from "../CustomButton";

import {
	IconButton,
	TextInput as TextInputPaper,
	TextInputProps,
} from "react-native-paper";

import React, {
	useCallback,
	useEffect,
	useState,
	forwardRef,
	memo,
	useContext,
} from "react";

import * as Yup from "yup";
import { UserAddressProps, useAuth } from "../../hooks/useAuth";

const BottomSheetTextInputComponent = forwardRef<TextInputRN, TextInputProps>(
	({ onFocus, onBlur, ...rest }, ref) => {
		const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

		useEffect(() => {
			return () => {
				shouldHandleKeyboardEvents.value = false;
			};
		}, [shouldHandleKeyboardEvents]);

		const handleOnFocus = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = true;
				if (onFocus) {
					onFocus(args);
				}
			},
			[onFocus, shouldHandleKeyboardEvents]
		);
		const handleOnBlur = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = false;
				if (onBlur) {
					onBlur(args);
				}
			},
			[onBlur, shouldHandleKeyboardEvents]
		);

		return (
			<TextInputPaper
				ref={ref}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				{...rest}
			/>
		);
	}
);

const BottomSheetTextInput = memo(BottomSheetTextInputComponent);
BottomSheetTextInput.displayName = "BottomSheetTextInput";

export default function UserAddressRegister() {
	const { t } = useTranslation();

	const navigation = useNavigation();

	const [email, setEmail] = useState("");

	const isValid = !!email && validateEmail(email);

	const {
		// setEmail: saveEmail,
		handleCloseBottomSheet,
		handleUserAddressSubmit,
	} = useAuth();

	//

	const [postalCode, setPostalCode] = useState("");

	const [addressName, setAddressName] = useState("");

	const [addressNumber, setAddressNumber] = useState("");

	const [addressSupplement, setAddressSupplement] = useState("");

	const [addressNeighborhood, setAddressNeighborhood] = useState("");

	const [addressCity, setAddressCity] = useState("");

	const [addressState, setAddressState] = useState("");

	const [addressCountry, setAddressCountry] = useState("");

	const postalCodeService = new PostalCodeService();

	const handleUserAddressFormSubmit = (values: UserAddressProps) => {
		handleUserAddressSubmit(values);

		setTimeout(() => {
			navigation.navigate("registrationCompletedScreen" as never);
		}, 500);
	};

	const validationSchema = Yup.object().shape({
		postalCode: Yup.string().required(),
		addressName: Yup.string().required(),
		addressNumber: Yup.string().required(),
		addressSupplement: Yup.string(),
		addressNeighborhood: Yup.string().required(),
		addressCity: Yup.string().required(),
		addressState: Yup.string().required(),
		addressCountry: Yup.string().required(),
	});

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={{ paddingHorizontal: 16, marginBottom: 8 }}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 12,
							alignItems: "center",
						}}
					>
						<Text style={styles.title}>Address</Text>
						<IconButton
							icon="close-circle"
							iconColor={"#000"}
							size={22}
							onPress={handleCloseBottomSheet}
							style={{ margin: 0 }}
						/>
					</View>
					<Text style={styles.subTitle}>Inform your address:</Text>
				</View>
				<View
					style={{
						justifyContent: "space-between",
						flexDirection: "column",
						flex: 1,
					}}
				>
					<View
						style={{
							justifyContent: "space-between",
							flexDirection: "column",
							flex: 1,
						}}
					>
						<View
							style={{
								justifyContent: "space-between",
								flexDirection: "column",
								flex: 1,
							}}
						>
							<ScrollView>
								<View
									style={{
										marginTop: 16,
										marginBottom: 16,
										gap: 16,
										paddingHorizontal: 16,
									}}
								>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={postalCode.replace(/(\d{5})(\d)/, "$1-$2")}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.postalCode")}
											</Text>
										}
										onChangeText={(code) => {
											if (code.toString().length >= 9) {
												postalCodeService
													.getAddressByPostalCode(code.toString())
													.then((codeData: any) => {
														if (codeData.cep) {
															setAddressName(codeData.logradouro);
															setAddressNeighborhood(codeData.bairro);
															setAddressCity(codeData.localidade);
															setAddressState(codeData.uf);
															setAddressCountry("Brasil");
														}
													});
											}
											setPostalCode(code);
										}}
										maxLength={9}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressName}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressName")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressName(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressNumber}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressNumber")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressNumber(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressSupplement}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressSupplement")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressSupplement(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressNeighborhood}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressNeighborhood")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressNeighborhood(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressCity}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressCity")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressCity(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressState}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressState")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressState(addressName);
										}}
										onBlur={() => {}}
									/>
									<BottomSheetTextInput
										style={styles.input}
										mode="outlined"
										value={addressCountry}
										label={
											<Text
												style={{
													backgroundColor: "#FFF",
													paddingHorizontal: 4,
												}}
											>
												{t("pos.steps.user-address.addressCountry")}
											</Text>
										}
										onChangeText={(addressName) => {
											setAddressCountry(addressName);
										}}
										onBlur={() => {}}
									/>
								</View>
							</ScrollView>
						</View>
						<CustomButton
							label={t("pos.continue")}
							style={{}}
							rightAddon={
								<MaterialCommunityIcons
									name="arrow-right"
									size={24}
									color={"#fff"}
								/>
							}
							onPress={() =>
								handleUserAddressFormSubmit({
									postalCode,
									addressName,
									addressNumber,
									addressSupplement,
									addressNeighborhood,
									addressCity,
									addressState,
									addressCountry,
								})
							}
						/>
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		// marginHorizontal: 16,
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	input: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
});
