import { StyleSheet } from "react-native";
import {commonFonts} from "../../../../src/styles/commonStyles";

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    paddingHorizontal: 16
  },
  timerText: {
    fontFamily: commonFonts.regular,
    fontSize: 14
  },
  amountText: {
    fontFamily: commonFonts.medium,
    fontSize: 24,
  },
  networkText: {
    fontFamily: commonFonts.medium,
    fontSize: 14
  },
  walletText: {
    fontFamily: commonFonts.medium,
    fontSize: 16,
    textAlign: "center"
  },
  shareButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 56
  },
  shareButtonText: {
    fontFamily: commonFonts.medium,
    fontSize: 16,
  },
  footer: {
    flex: 1,
    justifyContent: "flex-end"
  }
})
