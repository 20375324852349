import {MD3LightTheme, useTheme} from "react-native-paper";

export const customTheme = {
    ...MD3LightTheme,
    custom: "property",
    colors: {
        ...MD3LightTheme.colors,
        primary: "#204B94",
        primaryHover: "rgba(32,75,148,0.3)",
        secondary: "#515966",
        secondaryHover: "rgba(81,89,102,0.3)",
        secondaryLight: "#dae1e9",
        success: "#228c5e",
        successLight: "#d5ece4",
        warning: "#ed6c02",
        warningHover: "rgba(237,108,2,0.3)",
        danger: "#d32f2f",
        dangerHover: "rgba(211,47,47,0.2)",
        header: "#204B94",
        gray: "#515966",
        grayHover: "rgba(81,89,102,0.3)",
        grayLight: "#dae1e9"
    },
};

export type AppTheme = typeof customTheme;

export const useCustomTheme = () => useTheme<AppTheme>();
