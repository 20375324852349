import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CustomSplashScreen } from "./src/components/CustomSplashScreen";
import MultiTenantProvider from "./src/providers/multi-tenant.provider";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ResponsiveProvider } from "./src/hooks/useResponsive";
import ResetStyle from "./src/components/ResetStyle";
import { AuthProvider } from "./src/hooks/useAuth";
import * as SplashScreen from "expo-splash-screen";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { ThemeProvider } from "@rneui/themed";
import Navigation from "./src/Navigation";
import { View, StyleSheet } from "react-native";
import * as React from "react";
import "./src/i18n/i18n";

import {
	useFonts,
	Inter_400Regular,
	Inter_500Medium,
	Inter_700Bold,
} from "@expo-google-fonts/inter";
import { PaymentProvider } from "./src/hooks/usePayment";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { StatusBar } from "expo-status-bar";


SplashScreen.preventAutoHideAsync();
setTimeout(SplashScreen.hideAsync, 100);

const queryClient = new QueryClient();
export default function App() {
	const { i18n } = useTranslation();

	const [fontsLoaded] = useFonts({
		Inter_400Regular,
		Inter_500Medium,
		Inter_700Bold,
	});

	useEffect(() => {
		setLanguageFromLocalStorage();
	}, []);

	const setLanguageFromLocalStorage = async () => {
		const language: string = await AsyncStorage.getItem("language") ?? 'pt';
		await i18n.changeLanguage(language);
	};

	const onLayoutRootView = useCallback(async () => {
		if (fontsLoaded) {
			await SplashScreen.hideAsync();
		}
	}, [fontsLoaded]);

	return (
		<>
			<StatusBar style="light" />
			<GestureHandlerRootView style={styles.container}>
				<QueryClientProvider client={queryClient}>
					<MultiTenantProvider>
						<ThemeProvider>
							<ResponsiveProvider>
								<SafeAreaProvider>
									<CustomSplashScreen>
										<ResetStyle />
										<PaymentProvider>
											<AuthProvider>
												<BottomSheetModalProvider>
													<View style={{ flex: 1 }} onLayout={onLayoutRootView}>
														{fontsLoaded && <Navigation />}
													</View>
												</BottomSheetModalProvider>
											</AuthProvider>
										</PaymentProvider>
									</CustomSplashScreen>
								</SafeAreaProvider>
							</ResponsiveProvider>
						</ThemeProvider>
					</MultiTenantProvider>
				</QueryClientProvider>
			</GestureHandlerRootView>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
});
