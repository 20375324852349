import InitialView from "../../src/components/InitialView";
// @ts-ignore
import {APP_ENV} from "@env";
import NotFoundView from "../../src/components/NotFoundView";
import {useEffect} from "react";
import {useMultiTenant} from "../../src/providers/multi-tenant.provider";
import React from "react";
import {Platform} from "react-native";

export default function Home() {
    const { tenant } = useMultiTenant();

    useEffect(() => {
        if(APP_ENV !== "local" && Platform.OS === "web") {
            window?.open(tenant.website, "_self");
        }
    }, [])

    if(APP_ENV === "local") {
        return (<InitialView/>);
    }

    return (
      <NotFoundView/>
    );
}
