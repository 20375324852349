import Ionicons from "@expo/vector-icons/Ionicons";
import {Pressable} from "react-native";
import * as Clipboard from "expo-clipboard";
import {useCustomTheme} from "../Theme";
import {useState} from "react";

interface CopyToClipboardButtonProps {
  value: string,
  size?: number
}

export default function CopyToClipboardIconButton({ value, size = 20 }: CopyToClipboardButtonProps) {
  const theme = useCustomTheme();

  const [onPressEffect, setOnPressEffect] = useState(false);

  const handleOnClick = () => {
    copyToClipboard();

    if(!onPressEffect) {
      setOnPressEffect(true);
      setTimeout(() => {
        setOnPressEffect(false);
      }, 1500)
    }
  }

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(value);
  };

  return (
    <Pressable
      onPress={handleOnClick}
    >
      {
        ({ hovered }: any) => (
          <Ionicons
            name={onPressEffect ? "copy" : "copy-outline"}
            color={onPressEffect ? theme.colors.secondary : ( hovered ? theme.colors.secondary : theme.colors.primary )}
            size={size}
          />
        )
      }
    </Pressable>
  )
}
