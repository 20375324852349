import BottomSheet, { BottomSheetBackdropProps } from "@gorhom/bottom-sheet";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import React, { useCallback, useMemo, useRef } from "react";
import AuthService from "../../services/AuthService";
import { Navigator } from "./BottomSheetNavigator";
import { useAuth } from "../../hooks/useAuth";

import Animated, {
	Extrapolate,
	interpolate,
	useAnimatedStyle,
} from "react-native-reanimated";

export const CustomBackdrop = ({
	animatedIndex,
	style,
}: BottomSheetBackdropProps) => {
	const containerAnimatedStyle = useAnimatedStyle(() => ({
		opacity: interpolate(
			animatedIndex.value,
			[0, 1],
			[0, 1],
			Extrapolate.CLAMP
		),
	}));

	const containerStyle = useMemo(
		() => [
			style,
			{
				backgroundColor: "rgba(0,0,0,0.5)",
			},
			containerAnimatedStyle,
		],
		[style, containerAnimatedStyle]
	);

	const { handleCloseBottomSheet } = useAuth();

	return (
		<Animated.View style={containerStyle}>
			<TouchableOpacity style={{ flex: 1 }} onPress={handleCloseBottomSheet} />
		</Animated.View>
	);
};

const authService = new AuthService();

export default function AuthBottomSheet() {
	const snapPoints = useMemo(() => ["25%", "90%"], []);

	const bottomSheetRef = useRef<BottomSheet>(null);

	const handleSheetChange = useCallback((index) => {
		console.log("handleSheetChange", index);
	}, []);

	const renderBackdrop = useCallback(
		(props) => <CustomBackdrop {...props} type={3} />,
		[]
	);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<BottomSheet
				ref={bottomSheetRef}
				index={1}
				snapPoints={snapPoints}
				animateOnMount={true}
				onChange={handleSheetChange}
				keyboardBlurBehavior={"restore"}
				backdropComponent={renderBackdrop}
				android_keyboardInputMode={"adjustResize"}
			>
				<Navigator />
			</BottomSheet>
		</View>
	);
}

const styles = StyleSheet.create({});
