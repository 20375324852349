import * as Linking from "expo-linking";

const config = {
	screens: {
		Home: "",
		Invoice: "invoice/:id",
		Receipt: "receipt/:id",
		pos: {
			path: "pos",
			screens: {
				"main": "*"
			}
		},
	},
};

const linking = {
	prefixes: [Linking.createURL("/")],
	config,
};

export default linking;
