import React, { createContext, ReactNode, useContext, useState } from "react";
import { PaymentMethodProps } from "../providers/pos.provider";
import CryptoSvg from "../assets/svg/Crypto";

interface PaymentContextData {
	paymentMethod: PaymentMethodProps;
	handlePaymentMethod: (paymentMethod: PaymentMethodProps) => void;
}

//

const PaymentContext = createContext<PaymentContextData>(
	{} as PaymentContextData
);

const PaymentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethodProps>({
		type: "",
		title: "",
		description: "",
		icon: CryptoSvg,
	});

	const handlePaymentMethod = (paymentMethod: PaymentMethodProps) => {
		setPaymentMethod(paymentMethod);
	};

	return (
		<PaymentContext.Provider
			value={{
				paymentMethod,
				handlePaymentMethod,
			}}
		>
			{children}
		</PaymentContext.Provider>
	);
};

const usePayment = () => {
	const context = useContext(PaymentContext);
	if (!context) {
		throw new Error("usePayment must be used within an PaymentProvider.");
	}

	return context;
};

export { PaymentProvider, usePayment };
