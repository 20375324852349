import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import LanguageView from "../../src/components/LanguageView";
import { useNavigation } from "@react-navigation/native";
import { Button } from "react-native-paper";
import React from "react";

export default function InitialView() {
	const navigation = useNavigation<any>();
	const { t } = useTranslation();

	return (
		<View style={styles.container}>
			<View style={styles.buttons}>
				<Button
					mode={"contained"}
					onPress={() =>
						navigation.navigate("Invoice", { id: "EZZ-ABCDE0000" })
					}
				>
					{t("home.create-invoice")}
				</Button>

				<Button
					mode={"contained"}
					onPress={() => navigation.navigate("pos", { screen: "main" })}
				>
					{t("home.go-to-pos")}
				</Button>
			</View>
			<LanguageView />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	buttons: {
		flexDirection: "row",
		gap: 8,
		marginBottom: 32,
	},
});
