import {Platform, StyleSheet} from "react-native";
import ScreenUtils from "../utils/ScreenUtils";

const isSmallScreen = ScreenUtils.isSmallScreen();

const maxWidth = 400;
const maxWidthMobile = 480;
const minHeight = 640;


export const commonFonts = {
    regular: "Inter_400Regular",
    medium: "Inter_500Medium",
    bold: "Inter_700Bold"
}

const commonStyles = StyleSheet.create({
    stackContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: isSmallScreen ? 8 : 16
    },
    stackCard: {
        overflow: Platform.OS === "web" ? "auto" as any : undefined,
        width: "100%",
        maxWidth: maxWidth,
        minHeight: minHeight,
        borderRadius: 16,
        backgroundColor: "#FFF"
    } as any,
    container: {
        flex: 1,
        width: "100%",
        maxWidth: maxWidth,
        paddingVertical: isSmallScreen ? 12 : 24,
        paddingHorizontal: 24,
        gap: isSmallScreen ? 16 : 24,
        flexDirection: "column",
        justifyContent: "space-between"
    },
    containerMobile: {
        flex: 1,
        width: "100%",
        maxWidth: maxWidthMobile,
        paddingVertical: 12,
        paddingHorizontal: 0,
        gap: 16,
        flexDirection: "column",
        justifyContent: "space-between"
    },
    content: {
        flex: 1,
        gap: isSmallScreen ? 12 : 16
    },
    footerContainer: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingHorizontal: 16
    },
    cardShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});

export default commonStyles;
