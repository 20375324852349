import {useCustomTheme} from "../Theme";
import React, {useState} from "react";
import * as Clipboard from "expo-clipboard";
import {CustomButton, CustomButtonPros} from "./CustomButton";
import {useTranslation} from "react-i18next";

interface CopyToClipboardButton extends Omit<CustomButtonPros, "label"> {
  label: string,
  copiedLabel?: string,
  value: string,
  labelColor?: string,
  copiedLabelColor?: string
}

const CopyToClipboardButton = ({ label, copiedLabel, value, labelColor, copiedLabelColor, ...buttonProps }: CopyToClipboardButton) => {
  const theme = useCustomTheme();
  const {t} = useTranslation();

  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await Clipboard.setStringAsync(value);

    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  return (
    <CustomButton
      onPress={handleCopy}
      label={copied ? (copiedLabel || t(`general.clipboard-copied`)) : label}
      type={"ghost"}
      style={{
        backgroundColor: "transparent",
      }}
      labelStyle={{
        color: copied ? (copiedLabelColor || theme.colors.primary) : (labelColor || theme.colors.secondary),
      }}
      {...buttonProps}
    />
  );
};

export default CopyToClipboardButton;
