import {createContext} from "react";
import {IInvoice} from "../../src/services/InvoiceService";
import {IInvoiceCurrencyOption, IPageConfig} from "./index";

interface InvoiceContextProps {
  toggleStep: (action: "next" | "previous") => Promise<void>;
  setPageConfig: (
    value: ((prevState: IPageConfig) => IPageConfig) | IPageConfig
  ) => void;
  pageConfig: IPageConfig;
  setSelectedCurrency: (currency: IInvoiceCurrencyOption) => void;
  selectedCurrency: IInvoiceCurrencyOption;
  invoice?: IInvoice;
  paymentMethods: IInvoiceCurrencyOption[];
}
export const InvoiceContext = createContext<InvoiceContextProps>(
  {} as InvoiceContextProps
);
