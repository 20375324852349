export enum TNetworkSymbol {
	Bitcoin = "Bitcoin",
	Ethereum = "Ethereum",
	Tron = "TRON",
}

/* Currency Symbols
 *  @Dev: let in A-Z order
*/
export enum TCurrencySymbol {
	BNB = "BNB",
	BUSD = "BUSD",
	BRL = "BRL",
	BTC = "BTC",
	BTCB = "BTCB",
	EART = "EART",
	ETH = "ETH",
	HTEST = "HTEST",
	LTC = "LTC",
	OLE = "OLE",
	TSC = "TSC",
	USD = "USD",
	USDT = "USDT",
	USDC = "USDC",
	WBNB = "WBNB",
	TRX = "TRX",
	XRP = "XRP",
	DOGE = "DOGE",
}

export interface ICurrency {
	currency: TCurrencySymbol,
	name: string,
	decimals: number
	prefix?: string
	decimalSeparator: "." | ","
}

/* Currencies
 *  @Dev: let in A-Z order by symbol
*/
export const TCurrencies: Array<ICurrency> = [
	{
		currency: TCurrencySymbol.BNB,
		name: "BNB Coin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.WBNB,
		name: "Wrapped BNB",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.BRL,
		name: "Reais",
		decimals: 2,
		prefix: "R$",
		decimalSeparator: ","
	},
	{
		currency: TCurrencySymbol.BTC,
		name: "Bitcoin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.BTCB,
		name: "BTCB Token",
		decimals: 18,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.EART,
		name: "eArt Token",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.ETH,
		name: "Ethereum",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.LTC,
		name: "Litecoin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.OLE,
		name: "OleCoin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.TSC,
		name: "TrusterCoin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.USDC,
		name: "USD Coin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.USDT,
		name: "Tether USD",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.USDC,
		name: "USD Coin",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.USD,
		name: "Dollar",
		decimals: 2,
		prefix: "$",
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.HTEST,
		name: "HubTestToken",
		decimals: 9,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.BUSD,
		name: "BUSD Token",
		decimals: 18,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.TRX,
		name: "TRON",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.DOGE,
		name: "DOGE",
		decimals: 6,
		decimalSeparator: "."
	},
	{
		currency: TCurrencySymbol.XRP,
		name: "XRP",
		decimals: 6,
		decimalSeparator: "."
	},
];

export const TCurrency: { [currency in TCurrencySymbol]?: ICurrency } = TCurrencies.reduce((items, item: ICurrency) => {
	items[item.currency] = item;
	return items;
}, {});
