import {
	TextInput as TextInputRN,
	StyleSheet,
	View,
	Text,
	ScrollView,
} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { UserInfoProps, useAuth } from "../../hooks/useAuth";
import { validateCPF, validateCNPJ } from "validations-br";
import { TextInputMask } from "react-native-masked-text";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";
import { CardButton } from "../CardButton";
import { parse, isValid } from "date-fns";
import { Formik } from "formik";
import * as Yup from "yup";

import {
	IconButton,
	TextInput as TextInputPaper,
	TextInputProps,
} from "react-native-paper";

import React, {
	useCallback,
	forwardRef,
	useEffect,
	useState,
	memo,
} from "react";

type AccountType = "person" | "company";

const BottomSheetTextInputComponent = forwardRef<TextInputRN, TextInputProps>(
	({ onFocus, onBlur, ...rest }, ref) => {
		const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

		useEffect(() => {
			return () => {
				shouldHandleKeyboardEvents.value = false;
			};
		}, [shouldHandleKeyboardEvents]);

		const handleOnFocus = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = true;
				if (onFocus) {
					onFocus(args);
				}
			},
			[onFocus, shouldHandleKeyboardEvents]
		);
		const handleOnBlur = useCallback(
			(args) => {
				shouldHandleKeyboardEvents.value = false;
				if (onBlur) {
					onBlur(args);
				}
			},
			[onBlur, shouldHandleKeyboardEvents]
		);

		return (
			<TextInputPaper
				ref={ref}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				{...rest}
			/>
		);
	}
);

const BottomSheetTextInput = memo(BottomSheetTextInputComponent);
BottomSheetTextInput.displayName = "BottomSheetTextInput";

export default function RegisterBottomSheet() {
	const { t } = useTranslation();

	const navigation = useNavigation();

	const { handleCloseBottomSheet, handleUserInfoSubmit } = useAuth();

	const personValidationSchema = Yup.object().shape({
		personLegalName: Yup.string().required().max(255),
		personId: Yup.string()
			.required()
			.test("is-valid-id", "invalid-id", (id) => {
				const idAsString = id.toString();

				return validateCPF(idAsString);
			}),
		birthdate: Yup.string()
			.required()
			.transform((value, originalValue) => {
				if (originalValue) {
					const parsedDate = parse(originalValue, "dd/MM/yyyy", new Date());
					return isValid(parsedDate)
						? parsedDate.toISOString().split("T")[0]
						: value;
				}
				return value;
			})
			.test("isValidDate", "Data inválida", function (value) {
				if (
					isValid(
						value
							? parse(value, "yyyy-MM-dd", new Date()).getFullYear()
							: undefined
					)
				) {
					const year = value
						? parse(value, "yyyy-MM-dd", new Date()).getFullYear()
						: undefined;

					return year === undefined ? false : year > 1900;
				}
			}),
	});

	const companyValidationSchema = Yup.object().shape({
		companyLegalName: Yup.string().required().max(255),
		companyId: Yup.string()
			.required()
			.test("is-valid-id", "invalid-id", (id) => {
				const idAsString = id.toString();

				return validateCNPJ(idAsString);
			}),
	});

	function handleUseInfoFormSubmit(values: UserInfoProps) {
		handleUserInfoSubmit(values);

		setTimeout(() => {
			navigation.navigate("userContactRegisterScreen" as never);
		}, 500);
	}

	const [accountType, setAccountType] = useState("person");

	function handleChangeAccountType(accountType: AccountType, resetForm) {
		setAccountType(accountType);

		resetForm();
	}

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={{ paddingHorizontal: 16 }}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 12,
							alignItems: "center",
						}}
					>
						<Text style={styles.title}>{t("pos.steps.user-info.title")}</Text>
						<IconButton
							icon="close-circle"
							iconColor={"#000"}
							size={22}
							onPress={handleCloseBottomSheet}
							style={{ margin: 0 }}
						/>
					</View>

					<View>
						<Text style={styles.subTitle}>
							{t("pos.steps.user-info.content")}
						</Text>
					</View>
				</View>

				<View
					style={{
						justifyContent: "space-between",
						flexDirection: "column",
						flex: 1,
					}}
				>
					{accountType === "person" ? (
						<Formik
							initialValues={{
								personLegalName: "",
								personId: "",
								birthdate: "",
							}}
							validateOnMount={true}
							validationSchema={personValidationSchema}
							onSubmit={(values) => handleUseInfoFormSubmit(values)}
						>
							{({
								handleChange,
								handleBlur,
								handleSubmit,
								values,
								errors,
								touched,
								resetForm,
							}) => (
								<View
									style={{
										justifyContent: "space-between",
										flexDirection: "column",
										flex: 1,
									}}
								>
									<View
										style={{
											marginTop: 16,
											gap: 16,
											paddingHorizontal: 16,
											overflow: "scroll",
										}}
									>
										<View
											style={{
												flexDirection: "row",
												gap: 16,
											}}
										>
											<CardButton
												style={({ hovered }: any) => [
													styles.button,
													hovered ? styles.selected : null,
												]}
												onPress={() =>
													handleChangeAccountType("person", resetForm)
												}
												isSelect={true}
												content={
													<View
														style={{
															paddingVertical: 16,
														}}
													>
														<Text
															style={{
																color: "#1C1B1F",
																fontFamily: "Inter_400Regular",
																fontSize: 14,
															}}
														>
															{t("pos.steps.user-info.account-type-person")}
														</Text>
													</View>
												}
											/>
											<CardButton
												style={({ hovered }: any) => [
													styles.button,
													hovered ? styles.selected : null,
												]}
												onPress={() =>
													handleChangeAccountType("company", resetForm)
												}
												isSelect={false}
												content={
													<View
														style={{
															paddingVertical: 16,
														}}
													>
														<Text
															style={{
																color: "#1C1B1F",
																fontFamily: "Inter_400Regular",
																fontSize: 14,
															}}
														>
															{t("pos.steps.user-info.account-type-company")}
														</Text>
													</View>
												}
											/>
										</View>

										<ScrollView>
											<View
												style={{
													gap: 16,
													flexDirection: "column",
												}}
											>
												<BottomSheetTextInput
													style={styles.input}
													mode="outlined"
													value={values.personId}
													label={
														<Text
															style={{
																backgroundColor: "#FFF",
																paddingHorizontal: 4,
																color: "#79747E",
															}}
														>
															{t("pos.steps.user-info.personId")}
														</Text>
													}
													render={(props) => (
														<TextInputMask
															{...props}
															type={"cpf"}
															ref={() => {}}
														/>
													)}
													onChangeText={handleChange("personId")}
													onBlur={handleBlur("personId")}
													inputMode="numeric"
													error={
														errors.personId && touched.personId ? true : false
													}
												/>

												<BottomSheetTextInput
													style={styles.input}
													mode="outlined"
													value={values.personLegalName}
													label={
														<Text
															style={{
																backgroundColor: "#FFF",
																paddingHorizontal: 4,
																color: "#79747E",
															}}
														>
															{t("pos.steps.user-info.name")}
														</Text>
													}
													onChangeText={handleChange("personLegalName")}
													onBlur={handleBlur("personLegalName")}
													error={
														errors.personLegalName && touched.personLegalName
															? true
															: false
													}
												/>

												<BottomSheetTextInput
													style={styles.input}
													mode="outlined"
													value={values.birthdate}
													label={
														<Text
															style={{
																backgroundColor: "#FFF",
																paddingHorizontal: 4,
																color: "#79747E",
															}}
														>
															{t("pos.steps.user-info.birthdate")}
														</Text>
													}
													onChangeText={handleChange("birthdate")}
													onBlur={handleBlur("birthdate")}
													inputMode="numeric"
													render={(props) => (
														<TextInputMask
															{...props}
															options={{
																format: "DD/MM/YYYY",
															}}
															type={"datetime"}
															value={values.birthdate}
															ref={() => {}}
														/>
													)}
													error={
														errors.birthdate && touched.birthdate ? true : false
													}
												/>
											</View>
										</ScrollView>
									</View>
									<CustomButton
										label={t("pos.continue")}
										style={{ marginTop: 16 }}
										disabled={
											errors.birthdate ||
											errors.personId ||
											errors.personLegalName
												? true
												: false
										}
										rightAddon={
											<MaterialCommunityIcons
												name="arrow-right"
												size={24}
												color={"#fff"}
											/>
										}
										onPress={() => handleSubmit()}
									/>
								</View>
							)}
						</Formik>
					) : (
						<Formik
							initialValues={{
								companyLegalName: "",
								companyId: "",
							}}
							validateOnMount={true}
							validationSchema={companyValidationSchema}
							onSubmit={(values) => handleUseInfoFormSubmit(values)}
						>
							{({
								handleChange,
								handleBlur,
								handleSubmit,
								values,
								resetForm,
							}) => (
								<View
									style={{
										justifyContent: "space-between",
										flexDirection: "column",
										flex: 1,
										overflow: "hidden",
									}}
								>
									<View>
										<View
											style={{ marginTop: 16, gap: 16, paddingHorizontal: 16 }}
										>
											<View
												style={{
													flexDirection: "row",
													gap: 16,
												}}
											>
												<CardButton
													style={({ hovered }: any) => [
														styles.button,
														hovered ? styles.selected : null,
													]}
													onPress={() =>
														handleChangeAccountType("person", resetForm)
													}
													isSelect={false}
													content={
														<View
															style={{
																paddingVertical: 16,
															}}
														>
															<Text
																style={{
																	color: "#1C1B1F",
																	fontFamily: "Inter_400Regular",
																	fontSize: 14,
																}}
															>
																{t("pos.steps.user-info.account-type-person")}
															</Text>
														</View>
													}
												/>
												<CardButton
													style={({ hovered }: any) => [
														styles.button,
														hovered ? styles.selected : null,
													]}
													onPress={() =>
														handleChangeAccountType("company", resetForm)
													}
													isSelect={true}
													content={
														<View
															style={{
																paddingVertical: 16,
															}}
														>
															<Text
																style={{
																	color: "#1C1B1F",
																	fontFamily: "Inter_400Regular",
																	fontSize: 14,
																}}
															>
																{t("pos.steps.user-info.account-type-company")}
															</Text>
														</View>
													}
												/>
											</View>

											<BottomSheetTextInput
												style={styles.input}
												mode="outlined"
												value={values.companyId}
												label={
													<Text
														style={{
															backgroundColor: "#FFF",
															paddingHorizontal: 4,
															color: "#79747E",
														}}
													>
														{t("pos.steps.user-info.companyId")}
													</Text>
												}
												render={(props) => (
													<TextInputMask
														{...props}
														type={"cnpj"}
														ref={() => {}}
													/>
												)}
												onChangeText={handleChange("companyId")}
												onBlur={handleBlur("companyId")}
												inputMode="numeric"
											/>

											<BottomSheetTextInput
												style={styles.input}
												mode="outlined"
												value={values.companyLegalName}
												label={
													<Text
														style={{
															backgroundColor: "#FFF",
															paddingHorizontal: 4,
															color: "#79747E",
														}}
													>
														{t("pos.steps.user-info.companyName")}
													</Text>
												}
												onChangeText={handleChange("companyLegalName")}
												onBlur={handleBlur("companyLegalName")}
											/>
										</View>
									</View>
									<CustomButton
										label={t("pos.continue")}
										style={{ marginTop: 16 }}
										rightAddon={
											<MaterialCommunityIcons
												name="arrow-right"
												size={24}
												color={"#fff"}
											/>
										}
										onPress={() => handleSubmit()}
									/>
								</View>
							)}
						</Formik>
					)}
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		height: "100%",
	},
	content: {
		// marginHorizontal: 16,
		justifyContent: "space-between",
		flexDirection: "column",
		flex: 1,
	},
	title: {
		fontFamily: "Inter_500Medium",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		fontSize: 24,
	},
	subTitle: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	input: {
		fontFamily: "Inter_400Regular",
		fontSize: 16,
		color: "#79747E",
	},
	selected: {
		transition: "300ms",
		shadowOpacity: 0.5,
		shadowRadius: 2,
		borderWidth: 1,
		elevation: 4,

		shadowOffset: {
			width: 0,
			height: 0,
		},
	},
	button: {
		backgroundColor: "red",
		borderColor: "transparent",
		justifyContent: "center",
		alignItems: "center",
		transition: "300ms",
		borderRadius: 8,
		borderWidth: 1,
		width: "100%",
		flex: 1,
		position: "relative",
	},
});
