import { useAuth } from "../hooks/useAuth";
import { useCustomTheme } from "../Theme";
import useCustomNavigation from "../hooks/useCustomNavigation";
import { StyleSheet, View } from "react-native";
import {
	ActivityIndicator,
	IconButton,

} from "react-native-paper";
import React, { useState } from "react";
import { commonFonts } from "../styles/commonStyles";
import { useTranslation } from "react-i18next";
import {CustomButton} from "./CustomButton";

interface HeaderProfileButtonProps {
	variant?: "header" | "header-contrast";
	onPressLogin: () => void;
}

export const HeaderProfileButton = ({
	variant = "header-contrast",
	onPressLogin,
}: HeaderProfileButtonProps) => {
	const { isLogged, login } = useAuth();
	const { t } = useTranslation();
	const theme = useCustomTheme();
	const navigation = useCustomNavigation();

	const [isLoading, setIsLoading] = useState(false);

	const handlePressLogin = async () => {
		onPressLogin();
	};

	if (isLoading) {
		return (
			<View style={[styles.loadingView]}>
				<ActivityIndicator
					size={16}
					color={variant === "header" ? theme.colors.header : "#FFF"}
				/>
			</View>
		);
	}

	return (
		<>
			{isLogged ? (
				<IconButton
					icon="account-circle-outline"
					iconColor={variant === "header" ? theme.colors.header : "#FFF"}
					size={32}
					style={styles.iconButton}
					onPress={() => navigation.customNavigate("profile")}
				/>
			) : (
				<CustomButton
					label={t(`general.login`)}
					type={"outlined"}
					style={{
						height: 32
					}}
					labelStyle={{
						fontSize: 14
					}}
					onPress={handlePressLogin}
					isInverted={variant === "header-contrast"}
				/>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		right: 12,
	},
	iconButton: {
		margin: 0,
		padding: 0,
	},
	button: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		paddingHorizontal: 16,
		height: 30,
		borderWidth: 1,
		borderRadius: 16,
	},
	buttonText: {
		fontFamily: commonFonts.medium,
		userSelect: "none",
	},
	loadingView: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: 48,
		height: 48,
	},
});
