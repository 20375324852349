import UserContactRegisterBottomSheet from "./UserContactRegister";
import { NavigationContainer } from "@react-navigation/native";
import UserInfoRegisterBottomSheet from "./UserInfoRegister";
import RegistrationCompleted from "./RegistrationCompleted";
import LoginBottomSheetData from "./LoginBottomSheetData";
import LoginBottomSheetCode from "./LoginBottomSheetCode";
import UserAddressRegister from "./UserAddressRegister";
import { useMemo } from "react";
import React from "react";

import {
	StackNavigationOptions,
	TransitionPresets,
	createStackNavigator,
} from "@react-navigation/stack";

const Stack = createStackNavigator();

export const Navigator = () => {
	const screenOptions = useMemo<StackNavigationOptions>(
		() => ({
			...TransitionPresets.SlideFromRightIOS,

			headerShown: false,
			safeAreaInsets: { top: 0 },
			cardStyle: {
				backgroundColor: "white",
				overflow: "visible",
			},
		}),
		[]
	);

	return (
		<NavigationContainer independent={true}>
			<Stack.Navigator screenOptions={screenOptions}>
				<Stack.Screen name="dataScreen" component={LoginBottomSheetData} />
				<Stack.Screen name="codeScreen" component={LoginBottomSheetCode} />
				<Stack.Screen
					name="userAddressRegisterScreen"
					component={UserAddressRegister}
				/>
				<Stack.Screen
					name="userContactRegisterScreen"
					component={UserContactRegisterBottomSheet}
				/>
				<Stack.Screen
					name="userInfoRegisterScreen"
					component={UserInfoRegisterBottomSheet}
				/>
				<Stack.Screen
					name="registrationCompletedScreen"
					component={RegistrationCompleted}
				/>
			</Stack.Navigator>
		</NavigationContainer>
	);
};
