import { useMultiTenant } from "../../../../src/providers/multi-tenant.provider";
import { View, Text, Image, FlatList, StyleSheet, Button } from "react-native";
import { useCustomTheme } from "../../../../src/Theme";
import { useTranslation } from "react-i18next";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import Constants from "expo-constants";
import styles from "./styles";
import { useIsFocused } from "@react-navigation/native";
import PaymentMethod from "../../components/PaymentMethod";
import useCustomNavigation from "../../../../src/hooks/useCustomNavigation";
import {
	PaymentMethodProps,
	usePos,
} from "../../../../src/providers/pos.provider";
import LanguageView from "../../../../src/components/LanguageView";
import Header from "../../../../src/components/Header";
import PaymentMethodBottomSheet from "../../../../src/components/PaymentMethodBottomSheet/PaymentMethodBottomSheet";
import {
	BottomSheetBackdrop,
	BottomSheetModal,
	BottomSheetModalProvider,
	BottomSheetTextInput,
} from "@gorhom/bottom-sheet";
import { usePayment } from "../../../../src/hooks/usePayment";
import { Card } from "../../../../src/components/Card";

const statusBarHeight = Constants.statusBarHeight;

export default function Main() {
	const theme = useCustomTheme();
	const navigation = useCustomNavigation();
	const isFocused = useIsFocused();
	const { resetPayment, paymentMethods } = usePos();
	const { handlePaymentMethod } = usePayment();

	const { t } = useTranslation();

	const { tenant } = useMultiTenant();

	const [selectedCard, setSelectedCard] = useState(null);

	function handleSelectCard(cardId, paymentMethod) {
		setSelectedCard(cardId);
		handlePaymentMethod(paymentMethod);
		navigation.navigate("crypto-select");
	}

	useEffect(() => {
		if (isFocused) {
			resetPayment();
		}
	}, [isFocused]);

	// Test

	// backdrop
	const renderBackdrop = useCallback(
		(props) => (
			<BottomSheetBackdrop
				{...props}
				disappearsOnIndex={-1}
				appearsOnIndex={1}
			/>
		),
		[]
	);

	// ref
	const bottomSheetModalRef = useRef<BottomSheetModal>(null);

	// variables
	const snapPoints = useMemo(() => ["25%", "50%"], []);

	// callbacks
	const handlePresentModalPress = useCallback(() => {
		bottomSheetModalRef.current?.present();
	}, []);
	const handleSheetChanges = useCallback((index: number) => {
		console.log("handleSheetChanges", index);
	}, []);

	return (
		<BottomSheetModalProvider>
			<View style={styles.container}>
				<Header showProfileButton={true} variant={"clean"} />

				<View style={styles.headerLogoView}>
					<Image style={styles.headerLogoImage} source={tenant.splashLogo} />
				</View>

				<View style={styles.content}>
					<FlatList
						data={paymentMethods}
						style={styles.list}
						renderItem={({ item }) => (
							<PaymentMethod
								type={item.type}
								title={item.title}
								description={item.description}
								icon={item.icon}
								available={item.available}
							/>
						)}
						keyExtractor={(item) => item.type}
						numColumns={2}
					/>

				</View>

				<View style={styles.languageView}>
					<LanguageView />
				</View>

				<View style={styles.releaseView}>
					<Text style={[styles.release, { color: theme.colors.gray }]}>
						release: v1.0.1
					</Text>
					<Text
						style={[styles.release, { fontSize: 12, color: theme.colors.gray }]}
					>
						Powered by Hubchain Technologies
					</Text>
				</View>
			</View>
		</BottomSheetModalProvider>
	);
}
